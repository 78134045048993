import React, { useState, useEffect, useRef } from "react";
import { MapContainer, FeatureGroup, GeoJSON, useMap } from "react-leaflet";
import { govMarkers } from "../../static/map/govMarkers";
import { markers } from "../../static/map/markers";
import MapMarker from "./components/MapMarker";
import ZoomControl from "./components/ZoomControl";
import StyledLayer from "./components/StyledLayer";

const BoundsController = ({ bounds }) => {
  const map = useMap();

  useEffect(() => {
    if (bounds && bounds.isValid()) {
      map.fitBounds(bounds);
    }
  }, [bounds]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

const TownHallMap = ({ className, announcements, incidents, geoJson }) => {
  const layer = useRef(null);
  const [bounds, setBounds] = useState(null);
  const [key, setKey] = useState(-1);

  const zoomToBounds = () => {
    if (layer.current) {
      setBounds(layer.current.getBounds());
    }
  };

  // Handle GoJSON refresh
  // Leaflet won't load new data unless the component is refreshed manually using key
  useEffect(() => {
    setKey(Math.random());
  }, [geoJson]);

  return (
    <MapContainer
      center={[52.015, 18.123]}
      zoom={12}
      minZoom={9}
      maxZoom={18}
      className={className}
      zoomControl={false}
    >
      <StyledLayer />
      <BoundsController bounds={bounds} />
      <FeatureGroup>
        {announcements
          .map(marker => (
            <MapMarker
              key={marker.id}
              marker={marker}
              pos={[marker.latitude, marker.longitude]}
              icon={govMarkers[marker.category]}
              type="announcement"
              small
              openInNewTab
            />
          ))}
        {incidents
          ?.filter(i => i.latitude && i.longitude)
          .map(marker => (
            <MapMarker
              key={marker.id}
              marker={marker}
              pos={[marker.latitude, marker.longitude]}
              icon={markers[marker.status]}
              type="incident"
              small
              openInNewTab
            />
          ))}
      </FeatureGroup>
      {geoJson && (
        <GeoJSON
          ref={layer}
          data={geoJson}
          eventHandlers={{ add: zoomToBounds }}
          pathOptions={{ fillOpacity: "0.1" }}
          key={key}
        />
      )}
      <ZoomControl disableFitToMap />
    </MapContainer>
  );
};

export default TownHallMap;
