import React from "react";
import SuccessPage from "../components/SuccessPage";
import { useHistory, useLocation, Redirect } from "react-router-dom";

const NewPasswordSuccess = () => {
  const history = useHistory();
  const key = useLocation().state?.date;

  if (!key) {
    return <Redirect to="/login" />
  }

  return (
    <SuccessPage
      header="Hasło zostało zmienione"
      texts={["Hasło zostało zmienione pomyślnie"]}
      buttons={["Powrót do logowania"]}
      buttonActions={[() => history.push("/login")]}
    />
  );
};

export default NewPasswordSuccess;