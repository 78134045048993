import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useEffect, useRef } from "react";

const Hidden = ({ hiddenUp, hiddenDown, onChange, children }) => {
  const theme = useTheme();
  const isHiddenUp = useMediaQuery(theme.breakpoints.up(hiddenUp));
  const isHiddenDown = useMediaQuery(theme.breakpoints.down(hiddenDown));
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }

    if (!onChange) return;

    // Pass down wether the component is hidden
    onChange(isHiddenUp || isHiddenDown);
  }, [isHiddenDown, isHiddenUp]);

  if (isHiddenUp || isHiddenDown) {
    return null;
  }

  return children;
};

export default Hidden;
