import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import pin from "../../../static/km-logo-no-text.png";

const useStyles = makeStyles(theme => ({
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: "50px",
  },
  headerText: {
    fontSize: "40px",
    letterSpacing: "4px",
    textTransform: "uppercase",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      letterSpacing: "2px",
    },
  },
  largeIcon: {
    fontSize: "44px",
    position: "relative",
    top: "-3px",
    marginRight: "10px",
    color: theme.palette.primary.main,
  },
  pin: {
    height: "40px",
    marginRight: "20px",
  },
}));

const Header = ({ header, showSuccess }) => {
  const classes = useStyles();
  return (
    <Box className={classes.header}>
      {showSuccess ? (
        <CheckCircleIcon className={classes.largeIcon} />
      ) : (
        <img src={pin} className={classes.pin} alt="" />
      )}
      <Typography component="h1" variant="h1" className={classes.headerText}>
        {header}
      </Typography>
    </Box>
  );
};

export default Header;
