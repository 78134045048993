import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link } from "react-router-dom";
import CoatOfArms from "../../../CoatOfArms/CoatOfArms";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: "1",
    maxWidth: "70px",
  },
  image: {
    width: "50px",
    height: "53px",
    objectFit: "contain",
    objectPosition: "center",
    marginBottom: "5px",
  },
  name: {
    fontSize: "13px",
    fontWeight: "500",
    letterSpacing: "1.3px",
    textTransform: "uppercase",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "11px",
      letterSpacing: "1.1px",
      lineHeight: "17px",
    },
  },
  crestButton: {
    minHeight: "90px",
    minWidth: "50px",
    height: "fit-content",
    padding: "5px",
  },
}));

const County = ({ src, name, url }) => {
  const classes = useStyles();

  return (
    <Button className={classes.crestButton} component={Link} to={url}>
      <Box className={classes.root}>
        <CoatOfArms name={src} className={classes.image} />
        <Typography className={classes.name}>{name}</Typography>
      </Box>
    </Button>
  );
};

export default County;
