import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { useHistory } from "react-router";
import LabelWithPin from "../../../components/LabelWithPin/LabelWithPin";
import TextField from "../../../components/TextField/TextField";
import requestPasswordReset from "../../../services/user/requestPasswordReset";
import Header from "../components/Header";
import Root from "../components/Root";
import { useLoginStyles } from "../useLoginStyles";

const RemindPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const classes = useLoginStyles({ showMessage: error !== "" });
  const history = useHistory();

  const handleRequest = e => {
    e.preventDefault();
    setIsLoading(true);
    requestPasswordReset(email)
      .then(() => {
        history.push("/login/resetuj-haslo/sukces", { email });
      })
      .catch(() => {
        setIsLoading(false);
        setError("Wystąpił błąd. Proszę spróbować ponownie.");
      });
  };

  return (
    <Root>
      <form className={classes.root}>
        <Header header="Odzyskiwanie hasła" />
        <Typography className={classes.helperText}>
          Wprowadź swój adres e-mail w pole poniżej
        </Typography>
        <Box width="100%" maxWidth="510px">
          <LabelWithPin marginBottom="8px" marginRight="8px" label="E-mail" />
          <TextField
            className={classes.input}
            placeholder="E-mail"
            variant="filled"
            type="email"
            onChange={e => setEmail(e.target.value)}
          />
        </Box>
        <Typography className={classes.errorMessage}>{error + ""}</Typography>
        <Button
          color="primary"
          variant="contained"
          className={classes.loginButton}
          type="submit"
          disabled={!email || isLoading}
          onClick={e => handleRequest(e)}
        >
          Odzyskaj hasło
        </Button>
      </form>
    </Root>
  );
};

export default RemindPassword;
