import React from "react";
import { Redirect, useParams } from "react-router-dom";

const TokenRedirect = () => {
  const { token } = useParams();

  if (token) {
    return <Redirect to={{ pathname: "/nowe-haslo", state: { token } }} />;
  } else {
    return <Redirect to="/login" />;
  }
};

export default TokenRedirect;