import React from "react";
import { Switch, Route } from "react-router-dom";
import NewPassword from "./NewPassword";
import NewPasswordSuccess from "./NewPasswordSuccess";

const PasswordResetWrapper = () => {
  return (
    <Switch>
      <Route path="/nowe-haslo/sukces">
        <NewPasswordSuccess />
      </Route>
      <Route path="/nowe-haslo">
        <NewPassword />
      </Route>
    </Switch>
  );
};

export default PasswordResetWrapper;
