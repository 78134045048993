import marker1 from "./incidents/marker-1.png";
import marker2 from "./incidents/marker-2.png";
import marker3 from "./incidents/marker-3.png";
import marker4 from "./incidents/marker-4.png";
import a1 from "./active/active-1.png";
import a2 from "./active/active-2.png";
import a3 from "./active/active-3.png";
import a4 from "./active/active-4.png";
import aGov from "./active/active-gov.png";

export const markers = {
  1: marker1,
  2: marker2,
  3: marker3,
  4: marker4,
};

export const active = {
  1: a1,
  2: a2,
  3: a3,
  4: a4,
  gov: aGov,
};
