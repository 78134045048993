import marker1 from "./marker-1.png";
import marker2 from "./marker-2.png";
import marker3 from "./marker-3.png";
import marker4 from "./marker-4.png";
import avatar from "./avatar.png";
import likes from "./likes.png";
import comments from "./comment.png";

export const markers = {
  1: marker1,
  2: marker2,
  3: marker3,
  4: marker4,
  avatar: avatar,
  likes: likes,
  comments: comments,
};
