const incidentsUrl = "/incidents";
export const incidents = {
  search: incidentsUrl + "/search",
  single: incidentsUrl,
  categories: incidentsUrl + "/categories",
  create: incidentsUrl + "/create",
  close: id => incidentsUrl + `/${id}/close`,
};

const announcementsUrl = "/announcements";
export const announcements = {
  search: announcementsUrl + "/search",
  single: announcementsUrl,
};

const accountUrl = "/account";
export const account = {
  login: accountUrl + "/login",
  details: accountUrl,
  fullDetails: accountUrl + "/full",
  nickExists: accountUrl + "/isNickExists",
  create: accountUrl + "/create",
  initPasswordReset: accountUrl + "/initPasswordReset",
  passwordReset: accountUrl + "/resetPassword",
  activate: accountUrl + "/activate",
  edit: accountUrl + "/edit",
  initDelete: accountUrl + "/initDelete",
  delete: accountUrl + "/delete",
};

const imagesUrl = process.env.REACT_APP_BASE_URL + "/image";
export const images = {
  avatar: imagesUrl + "/avatar",
  incident: imagesUrl + "/incident",
  announcement: imagesUrl + "/announcement",
};

const attachmentsUrl = process.env.REACT_APP_BASE_URL + "/file";
export const attachments = {
  incidents: (incidentId, fileId) => `${attachmentsUrl}/incident/${incidentId}/${fileId}.pdf`,
};

const cityUrl = process.env.REACT_APP_BASE_URL + "/cities";
export const cities = {
  all: cityUrl + "/data",
  single: name => `${cityUrl}/data/${name}`,
  geoJson: name => `${cityUrl}/geoJson/${name}`,
  logo: name => `${cityUrl}/logo/${name}`,
};
