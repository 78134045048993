import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import MuiAvatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import { images } from "../../../services/urls";
import EditAvatar from "./EditAvatar";
import AvatarPlaceholder from "../../../static/navbar/Avatar";
import logo from "../../../static/km-logo-no-text.png";
import CoatOfArms from "../../../components/CoatOfArms/CoatOfArms";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    width: "fit-content",
    marginBottom: "16px",
  },
  avatar: {
    width: "280px",
    height: "280px",
    minHeight: "280px",
    minWidth: "280px",
    border: "2px solid #DDDDDD",
    borderRadius: "50%",
    objectFit: "cover",
    objectPosition: "center",
    backgroundColor: "#E9ECF0",
    [theme.breakpoints.down(300)]: {
      width: "140px",
      height: "140px",
      minHeight: "140px",
      minWidth: "140px",
    },
  },
  avatarImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  avatarPlaceholderRoot: {
    margin: "21px", // 3/40 (navbar avatar padding / width) * 280px
    width: "100%",
    height: "calc(100% - 42px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    border: "5px solid",
    borderColor: theme.palette.primary.main,
    [theme.breakpoints.down(300)]: {
      margin: "10.5px", // 3/40 (navbar avatar padding / width) * 140px
      height: "calc(100% - 21px)",
      border: "2.5px solid",
    },
  },
  avatarPlaceholder: {
    width: "140px",
    height: "140px",
    [theme.breakpoints.down(300)]: {
      width: "70px",
      height: "70px",
    },
  },
  coatOfArmsRoot: {
    position: "absolute",
    bottom: "-13px",
    right: "-13px",
    padding: "13px",
    width: "82px",
    height: "82px",
    minHeight: "82px",
    borderRadius: "50%",
    border: "1px solid #DDDDDD",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down(300)]: {
      width: "41px",
      height: "41px",
      minHeight: "41px",
      padding: "6px",
      bottom: "-6px",
      right: "-6px",
    },
  },
  coatOfArms: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    objectPosition: "center",
  },
  button: {
    marginBottom: "20px",
  },
}));

const Avatar = ({ avatarId, handleRefresh }) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [imageError, setImageError] = useState(false);
  const selectedOfficeId = useSelector(state => state.userReducer.selectedOffice);
  const townData = useSelector(state => state.userReducer.townData);
  const selectedOffice = useMemo(
    () => townData?.find(t => t.id === selectedOfficeId),
    [selectedOfficeId, townData]
  );

  const handleClose = refresh => {
    setDialogOpen(false);
    if (refresh === true) {
      handleRefresh();
    }
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <MuiAvatar className={classes.avatar} alt="Awatar użytkownika">
          {imageError || !avatarId ? (
            <div className={classes.avatarPlaceholderRoot}>
              <AvatarPlaceholder className={classes.avatarPlaceholder} />
            </div>
          ) : (
            <img
              className={classes.avatarImage}
              src={`${images.avatar}/${avatarId}.jpg`}
              alt=""
              onError={() => setImageError(true)}
            />
          )}
        </MuiAvatar>
        <div className={classes.coatOfArmsRoot}>
          <CoatOfArms
            className={classes.coatOfArms}
            name={selectedOffice?.logo}
            placeholder={logo}
            alt=""
          />
        </div>
      </div>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        className={classes.button}
        onClick={() => setDialogOpen(true)}
      >
        Zmień zdjęcie
      </Button>
      <EditAvatar dialogOpen={dialogOpen} onClose={handleClose} />
    </React.Fragment>
  );
};

export default Avatar;
