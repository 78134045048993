import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Incident from "../../../../components/Incident/Incident";
import { makeStyles } from "@material-ui/core/styles";
import useIsMobile from "../../../../util/useIsMobile";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
    width: "100%",
    borderTop: "1px solid #DDDDDD",
    overflow: "hidden",
  },
  headerRoot: {
    width: "100%",
    padding: "15px 10px",
    borderBottom: "1px solid #DDDDDD",
    [theme.breakpoints.down("sm")]: {
      padding: "15px 35px",
    },
  },
  header: {
    fontSize: "20px",
    color: theme.palette.primary.main,
    fontWeight: "500",
    textTransform: "uppercase",
    textAlign: "center",
  },
  announcementsRoot: {
    display: "flex",
    flexGrow: "1",
    flexWrap: "wrap",
    overflow: "scroll",
  },
  noAnnouncements: {
    padding: "20px",
    width: "100%",
    fontSize: "20px",
    textTransform: "uppercase",
    color: theme.palette.text.disabled,
    minHeight: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Announcements = ({ announcements, isLoading }) => {
  const classes = useStyles();
  const isMobile = useIsMobile();

  return (
    <Box className={classes.root}>
      <Box className={classes.headerRoot}>
        <Typography className={classes.header}>Tablica ogłoszeń</Typography>
      </Box>
      <Box className={classes.announcementsRoot}>
        {!isLoading && announcements.length === 0 ? (
          <Typography className={classes.noAnnouncements}>Brak ogłoszeń</Typography>
        ) : isLoading ? (
          <Box className={classes.noAnnouncements}>
            <CircularProgress size={72} />
          </Box>
        ) : (
          announcements.map(announcement => (
            <Incident
              incident={announcement}
              key={announcement.id}
              isPageAnnouncement={!isMobile}
              disableTrim
              openInNewTab
            />
          ))
        )}
      </Box>
    </Box>
  );
};

export default Announcements;
