import kmLogo from "../../../static/km-logo.png";
import lsLogo from "../../../static/ls-logo.png";
import powiatRadomski from "../../../static/powiat-radomski.png";
import ePowiatRadomski from "../../../static/e-powiat-radomski.png";
import polskiLad from "../../../static/polski-lad.png";
import pl from "../../../static/pl.png";

export const images = {
  komunikator: kmLogo,
  localspot: lsLogo,
  powiatRadomski,
  ePowiatRadomski,
  polskiLad,
  pl,
};
