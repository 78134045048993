import { snackbarConstants } from "../redux/modules/snackbarReducer";
import { axios } from "./axiosConfig";

const codeMessages = {
  404: "Nie znaleziono żądanego elementu.",
  503: "Serwis jest niedostępny.",
};

const errorInterceptor = store => {
  axios.interceptors.response.use(
    next => Promise.resolve(next),
    error => {
      let message = error.response?.data?.message || error.message;
      try {
        message = JSON.parse(message).error.message;
      } catch {
        // Do nothing
      }
      const code = error.response?.status;

      const errorMessage =
        message === "Network Error"
          ? "Brak połączenia z Internetem."
          : !!error.response?.data?.message
          ? message
          : codeMessages[code]
          ? codeMessages[code]
          : `Wystąpił nieznany błąd.${code ? ` [${code}]` : ""}`;

      store.dispatch({
        type: snackbarConstants.OPEN_SNACKBAR,
        message: errorMessage,
        error: true,
      });

      return Promise.reject(error);
    }
  );
};

export default errorInterceptor;
