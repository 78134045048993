import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    padding: "48px 10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "48px 15px 10px",
    },
  },
  button: {
    marginBottom: "20px",
  },
  name: {
    fontSize: "25px",
    letterSpacing: "2.5px",
    fontWeight: "500",
    marginBottom: "18px",
    textTransform: "uppercase",
  },
  dataRoot: {
    width: "100%",
    maxWidth: "450px",
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    marginBottom: "30px",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
    },
  },
  fieldName: {
    fontWeight: "500",
    letterSpacing: "1.8px",
    marginRight: "5px",
  },
  value: {
    fontWeight: "300",
    letterSpacing: "1.8px",
    wordBreak: "break-word",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  cityRoot: {
    display: "flex",
    alignItems: "center",
  },
  coatOfArms: {
    width: "24px",
    height: "24px",
    minWidth: "20px",
    objectFit: "contain",
    objectPosition: "center",
    marginRight: "5px",
  },
  notFound: {
    color: theme.palette.text.disabled,
    fontSize: "18px",
    letterSpacing: "1.8px",
  },
}));
