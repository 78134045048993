import { axios } from "../../util/axiosConfig";
import { account } from "../urls";

const nickExists = nick =>
  axios
    .get(account.nickExists, {
      params: {
        nick,
      },
    })
    .then(response => {
      const data = response.data;
      return data.message !== "OK";
    });

export default nickExists;
