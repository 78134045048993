import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const Avatar = props => {
  return (
    <SvgIcon viewBox="0 0 17.922 18.124" {...props}>
      <g transform="translate(0.659 0.5)">
        <path
          id="Path_8"
          data-name="Path 8"
          d="M339,294.914a9.072,9.072,0,0,1,16.6,0"
          transform="translate(-339 -277.95)"
          fill="none"
          stroke="#076cfa"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <circle
          id="Ellipse_2"
          data-name="Ellipse 2"
          cx="4.331"
          cy="4.331"
          r="4.331"
          transform="translate(3.97)"
          fill="none"
          stroke="#076cfa"
          strokeLinecap="round"
          strokeWidth="1"
        />
      </g>
    </SvgIcon>
  );
};

export default Avatar;