import React from 'react';

import MobileApp from './components/MobileApp/MobileApp';
import Assets from './components/Assets/Assets';
import Partnership from './components/Partnership/Partnership';
import ForOfficesBanner from './components/ForOfficesBanner/ForOfficesBanner';
import Contact from './components/Contact/Contact';

const ForOfficesPage = () => {
    return (
        <React.Fragment>
            <ForOfficesBanner />
            <MobileApp />
            <Partnership />
            <Assets />
            <Contact />
        </React.Fragment>
    )
}

export default ForOfficesPage;
