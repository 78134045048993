import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import Checkbox from "../../../components/Checkbox/Checkbox";
import LabelWithPin from "../../../components/LabelWithPin/LabelWithPin";
import updateUserData from "../../../services/user/updateUserData";
import { useStyles as useFileStyles } from "./styles";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: "14px",
    letterSpacing: "1.4px",
  },
  row: {
    marginBottom: "20px",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "7px",
  },
}));

const fields = [
  {
    key: "mailingStatus",
    name: "Zgoda na maile o zmianie statusu",
  },
  {
    key: "mailingNote",
    name: "Zgoda o maile o nowym komentarzu",
  },
  {
    key: "mailingCyclic",
    name: "Zgoda na maile przypominające o zgłoszeniu",
  },
];

const MailNotifications = ({ userData, handleSave }) => {
  const classes = useStyles();
  const fileClasses = useFileStyles();
  const [data, setData] = useState({ ...userData });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setData({ ...userData });
  }, [userData]);

  const handleSavePress = () => {
    setIsLoading(true);
    updateUserData(data)
      .then(() => {
        handleSave(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEdit = key => () => {
    const tmpData = { ...data };
    tmpData[key] = !tmpData[key];
    setData(tmpData);
  };

  const edited = useMemo(() => {
    let edited = false;
    fields.forEach(field => {
      if (userData[field.key] !== data[field.key]) {
        edited = true;
        return;
      }
    });

    return edited;
  }, [data, userData]);

  return (
    <Box className={fileClasses.dataRoot}>
      <LabelWithPin
        size={20}
        fontWeight="400"
        textTransform="uppercase"
        label="Powiadomienia e-mail"
        marginBottom="20px"
        color="primary"
      />
      {fields.map(field => (
        <Box key={field.key} className={clsx(fileClasses.row, classes.row)}>
          <Checkbox
            checked={data[field.key]}
            label={<Typography className={classes.label}>{field.name}</Typography>}
            handleChange={handleEdit(field.key)}
          />
        </Box>
      ))}
      <Collapse in={edited}>
        <Box className={classes.buttonContainer}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSavePress}
            disabled={isLoading}
            startIcon={isLoading && <CircularProgress size={24} />}
          >
            Zapisz zmiany
          </Button>
        </Box>
      </Collapse>
    </Box>
  );
};

export default MailNotifications;
