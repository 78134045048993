import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import SplitScreen from "../../components/SplitScreen/SpitScreen";
import MobileMap from "../../components/Map/MobileMap";
import Incident from "../../components/Incident/Incident";
import ListHeader from "../../components/SplitScreen/components/ListHeader";
import UserDetails from "./UserDetails";
import Loader from "../../components/Loader/Loader";
import getUserDetails from "../../services/user/getUserDetails";
import getUserIncidents from "../../services/user/getUserIncidents";
import getAnnoucementsByCity from "../../services/townHall/getAnnoucementsByCity";
import { snackbarAction } from "../../redux/modules/snackbarReducer";
import { userAction } from "../../redux/modules/userReducer";
import useIsMobile from "../../util/useIsMobile";
import getGeoJson from "../../services/townHall/getGeoJson";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100%",
    flexGrow: "1",
    alignItems: "stretch",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  loaderRoot: {
    width: "100%",
    height: "calc(100vh - 78px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  border: {
    width: "100%",
    borderTop: "1px solid #DDDDDD",
  },
}));

const UserProfilePage = () => {
  const classes = useStyles();
  const isLoggedIn = useSelector(state => state.userReducer.isLoggedIn);
  const selectedOfficeId = useSelector(state => state.userReducer.selectedOffice);
  const townData = useSelector(state => state.userReducer.townData);
  const isLoadingTownData = useSelector(state => state.userReducer.isLoadingTownData);
  const selectedOffice = useMemo(
    () => townData?.find(t => t.id === selectedOfficeId),
    [townData, selectedOfficeId]
  );
  const isMobile = useIsMobile();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [userIncidents, setUserIncidents] = useState([]);
  const [isLoadingIncidents, setIsLoadingIncidents] = useState(true);
  const [officeAnnouncements, setOfficeAnnouncements] = useState([]);
  const [isLoadingAnnouncements, setIsLoadingAnnouncements] = useState(true);
  const [geoJson, setGeoJson] = useState();
  const [height, setHeight] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoadingTownData) {
      dispatch(userAction.getCityData());
    }
  }, []);

  const donwloadUserData = () => {
    setIsLoading(true);
    getUserDetails()
      .then(response => {
        setUserData(response?.results[0]);
        setIsLoading(false);
      })
      .catch(() => {
        history.push("/");
      });
  };

  const donwloadUserIncidents = () => {
    setIsLoadingIncidents(true);
    getUserIncidents()
      .then(incidents => {
        setUserIncidents(incidents || []);
        setIsLoadingIncidents(false);
      })
      .finally(() => {
        setIsLoadingIncidents(false);
      });
  };

  const downloadOfficeAnnouncements = () => {
    if (!selectedOffice) {
      return;
    }

    setIsLoadingAnnouncements(true);
    getAnnoucementsByCity(selectedOffice.id)
      .then(response => {
        setOfficeAnnouncements(response.results?.filter(a => a.latitude && a.longitude));
        setIsLoadingAnnouncements(false);
      })
      .finally(() => {
        setIsLoadingAnnouncements(false);
      });
  };

  const donwloadGeoJson = () => {
    if (!selectedOffice) {
      return;
    }

    getGeoJson(selectedOffice.geoJSON)
      .then(response => {
        setGeoJson(response);
      })
      .catch(() => {
        setGeoJson();
        dispatch(
          snackbarAction.openSnackbar({ message: "Błąd podczas pobierania mapy.", error: true })
        );
      });
  };

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    donwloadUserData();
    donwloadUserIncidents();
  }, [isLoggedIn]);

  useEffect(() => {
    downloadOfficeAnnouncements();
    donwloadGeoJson();
  }, [selectedOffice]);

  const renderLeftSide = useMemo(() => {
    if (isLoading) {
      return (
        <Box className={classes.loaderRoot}>
          <CircularProgress size={48} />
        </Box>
      );
    }

    return (
      <UserDetails
        userData={userData}
        handleRefresh={donwloadUserData}
        incidentCount={userIncidents.length}
        setHeight={setHeight}
      />
    );
  }, [isLoading, userData, userIncidents]);

  const renderLeftList = useMemo(() => {
    return (
      <React.Fragment>
        <Loader
          elements={userIncidents}
          isLoading={isLoadingIncidents}
          noElementsText="Brak zgłoszonych zgłoszeń"
        />
        {userIncidents.map(incident => (
          <Incident incident={incident} isIncident key={incident.id} disableTrim openInNewTab />
        ))}
      </React.Fragment>
    );
  }, [userIncidents, isLoadingIncidents]);

  const renderRightList = useMemo(() => {
    return (
      <React.Fragment>
        <Loader
          elements={officeAnnouncements}
          isLoading={selectedOfficeId > 0 && isLoadingAnnouncements}
          noElementsText={
            selectedOfficeId > 0
              ? "Nie znaleziono ogłoszeń z Twojego urzędu"
              : "Wybierz urząd by zobaczyć ogłoszenia.\n\nGdy Twoje miasto dołączy do naszego programu zyskasz dostęp do informacji o wywozie odpadów, rozkładzie jazdy komunikacji miejskiej oraz wielu innych funkcjonalności."
          }
        />
        {officeAnnouncements.map(announcement => (
          <Incident incident={announcement} key={announcement.id} disableTrim openInNewTab />
        ))}
      </React.Fragment>
    );
  }, [officeAnnouncements, isLoadingAnnouncements, selectedOfficeId]);

  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  if (isMobile) {
    return (
      <Box className={classes.root}>
        {renderLeftSide}
        <MobileMap geoJson={geoJson} announcements={officeAnnouncements} />
        <Box className={classes.border} />
        <ListHeader title="Twoje zgłoszenia" />
        {renderLeftList}
        <ListHeader title="Tablica ogłoszeń Twojego urzędu" />
        {renderRightList}
      </Box>
    );
  }

  return (
    <SplitScreen
      incidents={userIncidents}
      announcements={officeAnnouncements}
      renderLeftSide={renderLeftSide}
      geoJson={geoJson}
      leftListTitle="Twoje zgłoszenia"
      rightListTitle="Tablica ogłoszeń Twojego urzędu"
      renderLeftList={renderLeftList}
      renderRightList={renderRightList}
      height={height}
    />
  );
};

export default UserProfilePage;
