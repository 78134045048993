import React, { useEffect, useMemo, useState } from "react";
import BackRow from "../components/BackRow";
import { Box, Button, CircularProgress, InputAdornment, Typography } from "@material-ui/core";
import LabelWithPin from "../../../../../../components/LabelWithPin/LabelWithPin";
import TextField from "../../../../../../components/FilledInput/FilledInput";
import AcceptTerms from "../../../../../../components/AcceptTerms/AcceptTerms";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { fields } from "./fields";
import { useDispatch, useSelector } from "react-redux";
import { mapAction } from "../../../../../../redux/modules/mapReducer";
import { snackbarAction } from "../../../../../../redux/modules/snackbarReducer";
import { hasErrors } from "../../../../../../util/hasErrors";

import createIncident from "../../../../../../services/map/createIncident";
import getAddress from "../../../../../../services/map/getAddress";
import getCoordinates from "../../../../../../services/map/getCoordinates";

const useStyles = makeStyles(() => ({
  header: {
    fontSize: "20px",
    fontWeight: "500",
    letterSpacing: "2px",
    textTransform: "uppercase",
  },
  formRoot: {
    padding: "35px 30px 0px",
    height: "100%",
    maxHeight: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  form: {
    flexGrow: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  inputRoot: {
    marginBottom: "30px",
    width: "100%",
  },
  input: {
    width: "100%",
  },
  button: {
    height: "51px",
    width: "80%",
    borderRadius: "25.5px",
    fontSize: "25px",
    marginBottom: "30px",
  },
}));

const isAddressValid = components => {
  const country = components?.find(c => c.types.includes("country"));
  return country?.short_name === "PL";
};

const NewIncident = ({ setHeader }) => {
  const classes = useStyles();
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // Updating address field after changing its value
  const [isLoadingCoordinates, setIsLoadingCoordinates] = useState(false);
  // Updating address field after clicking on a map
  const [isLoadingAddress, setIsLoadingAddress] = useState(true);
  // Prevent reload if address hasn't changed
  const [oldAddress, setOldAddress] = useState("");
  const newMarkerPos = useSelector(state => state.mapReducer.newMarkerPos);
  const { northEast, southWest, activeStatuses, showFavourite, showArchive, showGov } = useSelector(
    state => state.mapReducer
  );
  const isLoggedIn = useSelector(state => state.userReducer.isLoggedIn);
  const dispatch = useDispatch();
  const history = useHistory();
  const isDisabled = useMemo(() => {
    return (
      hasErrors(errors) ||
      !values.category ||
      !values.address ||
      (!isLoggedIn && (!values.agreement || !values.termsAgreement1))
    );
  }, [isLoggedIn, values, errors]);

  useEffect(() => {
    if (setHeader) {
      setHeader(renderHeader());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!newMarkerPos) return;

    setIsLoadingAddress(true);
    getAddress(newMarkerPos)
      .then(r => {
        const address = r.formatted_address;
        setValues(v => ({ ...v, address }));
        setErrors(e => ({
          ...e,
          address: isAddressValid(r.address_components) ? null : "Nieprawidłowy adres!",
        }));
      })
      .catch(() => {
        setValues(v => ({ ...v, address: "" }));
        setErrors(e => ({ ...e, address: "Nie udało się pobrać adresu!" }));
      })
      .finally(() => {
        setIsLoadingAddress(false);
      });
  }, [newMarkerPos]);

  useEffect(() => {
    if (!newMarkerPos) {
      return;
    }

    setValues(v => ({ ...v, latitude: newMarkerPos.lat, longitude: newMarkerPos.lng }));
  }, [newMarkerPos]);

  const handleChange = (key, value) => {
    if (key === "image") {
      setImage(value);
    } else {
      const tmpVal = { ...values };
      tmpVal[key] = value;
      setValues(tmpVal);
    }
  };

  const searchAddress = () => {
    const a = values.address;
    if (a === oldAddress) {
      return;
    }
    setOldAddress(a);
    setIsLoadingCoordinates(true);
    getCoordinates({ address: a })
      .then(r => {
        if (!r) {
          setErrors(e => ({ ...e, address: "Nieprawidłowy adres!" }));
          return;
        }
        dispatch(mapAction.setNewMarker({ pos: r.geometry.location }));
      })
      .catch(() => {
        setErrors(e => ({ ...e, address: "Nie udało się pobrać adresu!" }));
      })
      .finally(() => {
        setIsLoadingCoordinates(false);
      });
  };

  const handleBlur = key => () => {
    switch (key) {
      case "address": {
        searchAddress();
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleCreate = () => {
    setIsLoading(true);
    createIncident(values, image)
      .then(() => {
        setTimeout(
          () =>
            dispatch(
              mapAction.getIncidents({
                northEast,
                southWest,
                activeStatuses,
                showFavourite,
                showArchive,
                showGov,
              })
            ),
          300
        );
        history.replace("/map");
        dispatch(
          snackbarAction.openSnackbar({
            error: false,
            message: "Zgłoszenie zostało przekazane do urzędu. Po weryfikacji pojawi się na mapie.",
          })
        );
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const goBack = () => {
    history.push("/map");
    dispatch(mapAction.clearNewMarker());
  };

  if (!newMarkerPos) {
    goBack();
  }

  const getAdronment = key => {
    switch (key) {
      case "address": {
        return isLoadingAddress || isLoadingCoordinates ? <CircularProgress size={24} /> : null;
      }
      default:
        return null;
    }
  };

  const renderAdornment = key => {
    const component = getAdronment(key);
    if (component) {
      return <InputAdornment position="end">{component}</InputAdornment>;
    }

    return null;
  };

  const renderHeader = () => (
    <BackRow onGoBack={goBack}>
      <Box width="100%" display="flex" alignItems="center" justifyContent="center">
        <Typography className={classes.header}>Nowe zgłoszenie</Typography>
      </Box>
    </BackRow>
  );

  return (
    <React.Fragment>
      {!setHeader && renderHeader()}
      <Box className={classes.formRoot} data-body-scroll-lock-ignore>
        <form className={classes.form}>
          {Object.keys(fields).map(key => {
            const field = fields[key];
            const Component = field.component;
            if (isLoggedIn && field.notLoggedIn) {
              return null;
            }

            return (
              <Box key={key} className={classes.inputRoot}>
                <LabelWithPin label={field.name} />
                {field.component ? (
                  <Component handleChange={(k, v) => handleChange(k, v)} />
                ) : (
                  <TextField
                    error={!!errors[key]}
                    helperText={errors[key]}
                    multiline={field.multiline}
                    minRows={field.rows}
                    placeholder={field.placeholder}
                    variant="filled"
                    className={classes.input}
                    value={values[key] || ""}
                    onChange={e => handleChange(key, e.target.value)}
                    onBlur={handleBlur(key)}
                    InputProps={{ endAdornment: renderAdornment(key) }}
                  />
                )}
              </Box>
            );
          })}
          {!isLoggedIn && (
            <Box marginBottom="20px">
              <AcceptTerms handleChange={handleChange} />
            </Box>
          )}
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => handleCreate()}
            disabled={isLoading || isDisabled}
          >
            {isLoading ? <CircularProgress /> : "Zgłoś"}
          </Button>
        </form>
      </Box>
    </React.Fragment>
  );
};

export default NewIncident;
