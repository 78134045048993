export const texts = [
  { text: "Podpisujesz umowę na dowolny okres…" },
  {
    text: "z możliwością skorzystania z darmowego okresu próbnego.",
    style: { alignSelf: "center" },
  },
  { text: "My pomagamy Ci w promocji zapewniając materiały marketingowe." },
  {
    text: "Nie ponosisz żadnych kosztów inwestycyjnych (bez serwerów, oprogramowania, utrzymania).",
    style: { alignSelf: "flex-end", maxWidth: "900px" },
  },
  { text: "Wpłacasz jednorazową opłatę za konfigurację i szkolenie" },
  {
    text: "Jedyne co pozostaje to opłata miesięczna zależna od liczby mieszkańców (1 lub 3 grosze /m-c)",
    style: { alignSelf: "flex-end", marginRight: "40px", maxWidth: "1025px" },
  },
];
