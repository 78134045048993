import React from "react";
import { Switch, Route } from "react-router-dom";
import LoginPage from "./LoginPage";
import RemindPassword from "./RemindPassword";
import RemindPasswordSuccess from "./RemindPasswordSuccess";

const LoginWrapper = () => (
  <Switch>
    <Route path="/login/resetuj-haslo/sukces">
      <RemindPasswordSuccess />
      </Route>
    <Route path="/login/resetuj-haslo">
      <RemindPassword />
    </Route>
    <Route path="/login">
      <LoginPage />
    </Route>
  </Switch>
);

export default LoginWrapper;
