import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const BoxIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 28.969 25.373">
      <g transform="translate(1 1)">
        <rect
          width="26.969"
          height="5.394"
          fill="none"
          stroke="currentColor"
          strokeWidth="2px"
        />
        <path
          d="M0,0H23.373V17.979H0Z"
          transform="translate(1.799 5.394)"
          fill="none"
          stroke="currentColor"
          strokeWidth="2px"
        />
        <line
          x2="7.192"
          transform="translate(9.889 10.788)"
          stroke="currentColor"
          strokeWidth="2px"
        />
      </g>
    </SvgIcon>
  );
};

export default BoxIcon;
