import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Pin } from "../../../static/pin-color.svg";
import loginBg from "../../../static/login/login-bg.png";
import mobile from "../../../static/MockupIphone.png";
import appStore from "../../../static/app-store.png";
import googlePlay from "../../../static/google-play.png";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "80px",
    paddingRight: "35px",
    justifyContent: "center",
    backgroundPositionY: "center",
    flexGrow: "1",
    width: "55%",
    backgroundImage: `url(${loginBg})`,
  },
  innerRoot: {
    display: "flex",
  },
  pin: {
    width: "22px",
    height: "37px",
    margin: "35px 12px 0",
  },
  textRoot: {
    maxWidth: "455px",
    display: "flex",
    flexDirection: "column",
    marginTop: "35px",
    flexGrow: "1",
  },
  topText: {
    fontWeight: "bolder",
    marginBottom: "16px",
    fontSize: "30px",
    lineHeight: "37px",
    letterSpacing: "3px",
    "&:nth-of-type(2)": {
      marginBottom: "30px",
    }
  },
  middleText: {
    flexGrow: "1",
    fontSize: "25px",
    letterSpacing: "2.5px",
    lineHeight: "30px",
  },
  bottomText: {
    height: "41px",
    fontWeight: "bolder",
    fontSize: "20px",
    letterSpacing: "2px",
  },
  image: {
    width: "340px",
    height: "490px",
    marginBottom: "20px",
    objectFit: "contain",
  },
  appStoreRoot: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  },
  appStore: {
    height: "36px",
    "&:last-of-type": {
      marginRight: "12.5px",
    }
  },
}));

const RightBanner = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.innerRoot}>
        <Pin className={classes.pin} />
        <Box className={classes.textRoot}>
          <Typography className={classes.topText}>
            Nielegalne wysypisko?
          </Typography>
          <Typography className={classes.topText}>
            Uszkodzona droga?
          </Typography>
          <Typography className={classes.middleText}>
            Te i inne problemy możesz zgłaszać mobilnie, za pomocą naszej aplikacji.
          </Typography>
          <Typography className={classes.bottomText}>Pobierz Komunikator Miejski!</Typography>
        </Box>
        <Box>
          <img
            src={mobile}
            alt="Telefon z aplikacją Komunikator Miejski"
            className={classes.image}
          />
          <Box className={classes.appStoreRoot}>
            <a
              href="https://apps.apple.com/pl/app/localspot/id1035268671?l=pl"
              target="_blank"
              rel="noreferrer"
              aria-label="Pobierz aplikację Komunikator Miejski z App Store"
            >
              <img src={appStore} alt="" className={classes.appStore} />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=pl.xentivo.komunikator"
              target="_blank"
              rel="noreferrer"
              aria-label="Pobierz aplikację Komunikator Miejski z Google Play"
              className={classes.appStore}
            >
              <img src={googlePlay} className={classes.appStore} alt="" />
            </a>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RightBanner;
