import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { userAction } from "../../../redux/modules/userReducer";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import CameraIcon from "@material-ui/icons/CameraAlt";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Dropzone from "react-dropzone";
import Cropper from "react-easy-crop";
import clsx from "clsx";
import { cropImage } from "../../../util/cropImage";
import updateProfilePicture from "../../../services/user/updateProfilePictrue";

const useStyles = makeStyles(theme => ({
  inputRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    backgroundColor: "#F7F7F7",
    borderRadius: "20px",
    border: "1px solid #DDDDDD",
    cursor: "pointer",
  },
  placeholderText: {
    fontSize: "18px",
    fontWeight: "500",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    marginRight: "10px",
  },
  icon: {
    color: theme.palette.primary.main,
  },
  secondaryIcon: {
    color: theme.palette.text.secondary,
  },
  cropper: {
    position: "relative",
    width: "100%",
    height: "300px",
    overflow: "hidden",
    marginBottom: "20px",
  },
}));

const EditAvatar = ({ dialogOpen, onClose }) => {
  const classes = useStyles();
  const [image, setImage] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [cropPixels, setCropPixels] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleRead = files => {
    const srcFile = files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(srcFile);
  };

  const onCropComplete = (cropArea, cropPixels) => setCropPixels(cropPixels);

  const handleSave = () => {
    setIsLoading(true);
    cropImage(image, cropPixels)
      .then(img => {
        updateProfilePicture(img)
          .then(() => {
            dispatch(userAction.getUserDetails());
            onClose(true);
          })
          .catch(() => {
            setIsLoading(false);
          });
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={onClose}
      TransitionProps={{ onExited: () => setImage(null) }}
      maxWidth="sm"
      fullWidth
      scroll="body"
    >
      <DialogTitle>Edytuj zdjęcie</DialogTitle>
      <DialogContent>
        {!image ? (
          <Dropzone accept="image/jpeg" multiple={false} onDropAccepted={handleRead}>
            {({ getRootProps, getInputProps }) => (
              <Box className={classes.inputRoot} {...getRootProps()}>
                <input {...getInputProps()} />
                <Typography className={classes.placeholderText}>
                  Kliknij lub upuść zdjęcie
                </Typography>
                <CameraIcon className={classes.icon} />
              </Box>
            )}
          </Dropzone>
        ) : (
          <Box className={clsx(classes.cropper, classes.inputRoot)}>
            <Cropper
              image={image}
              zoom={zoom}
              aspect={1}
              cropShape="round"
              crop={crop}
              onCropChange={crop => setCrop(crop)}
              onCropComplete={onCropComplete}
            />
          </Box>
        )}
        {image && (
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item>
              <ZoomOutIcon className={classes.secondaryIcon} />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Slider min={1} max={4} step={0.1} value={zoom} onChange={(e, v) => setZoom(v)} />
            </Grid>
            <Grid item>
              <ZoomInIcon className={classes.secondaryIcon} />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="primary" size="small">
          Anuluj
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleSave}
          disabled={isLoading || !image}
          startIcon={isLoading && <CircularProgress size={24} />}
        >
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAvatar;
