import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import LabelWithPin from "../../../components/LabelWithPin/LabelWithPin";
import TextField from "../../../components/TextField/TextField";
import passwordReset from "../../../services/user/passwordReset";
import Header from "../components/Header";
import Root from "../components/Root";
import { useLoginStyles } from "../useLoginStyles";

const fields = [
  {
    key: "password",
    label: "Hasło",
  },
  {
    key: "repeatPassword",
    label: "Powtórz hasło",
  },
];

const NewPassword = () => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const classes = useLoginStyles({ showMessage: error !== "" });
  const history = useHistory();
  const token = useLocation().state?.token;

  const handleChange = (key, value) => {
    const tmpData = { ...data };
    tmpData[key] = value;
    setData(tmpData);
  };

  const handleRequest = e => {
    e.preventDefault();
    setIsLoading(true);
    passwordReset(token, data.password)
      .then(() => {
        history.push("/nowe-haslo/sukces", { date: new Date().getTime() });
      })
      .catch(() => {
        setIsLoading(false);
        setError("Wystąpił błąd. Proszę spróbować ponownie.");
      });
  };

  if (!token || !/(\S+\.){2}(\S+)/.test(token)) {
    return <Redirect to="/login" />;
  }

  return (
    <Root>
      <form className={classes.root}>
        <Header header="Odzyskiwanie hasła" />
        <Typography className={classes.helperText}>
          Wpisz swoje nowe hasło w pola poniżej
        </Typography>
        {fields.map(field => (
          <Box width="100%" maxWidth="510px" key={field.key}>
            <LabelWithPin marginBottom="8px" marginRight="8px" label={field.label} />
            <TextField
              className={classes.input}
              placeholder={field.label}
              variant="filled"
              type="password"
              autoComplete="new-password"
              onChange={e => handleChange(field.key, e.target.value)}
            />
          </Box>
        ))}
        <Typography className={classes.errorMessage}>{error + ""}</Typography>
        <Button
          color="primary"
          variant="contained"
          className={classes.loginButton}
          type="submit"
          disabled={!data.password || data.password !== data.repeatPassword || isLoading}
          onClick={e => handleRequest(e)}
        >
          Resetuj hasło
        </Button>
      </form>
    </Root>
  );
};

export default NewPassword;
