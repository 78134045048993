import React, { useEffect, useState } from "react";
import getCityImage from "../../services/townHall/getCityImage";

const CoatOfArms = ({ name, placeholder, ...props }) => {
  const [data, setData] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!name) return;

    getCityImage(name)
      .then(data => setData(data))
      .catch(() => setError(true));
  }, [name]);

  if (placeholder && (error || !name)) return <img src={placeholder} alt="" {...props} />;

  if (!data) return <div {...props} />;

  return <img src={data} alt="" {...props} />;
};

export default CoatOfArms;
