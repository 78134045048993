export const snackbarConstants = {
  OPEN_SNACKBAR: "OPEN_SNACKBAR",
  CLOSE_SNACKBAR: "CLOSE_SNACKBAR",
};

export const snackbarAction = {
  openSnackbar: ({ message, error }) => ({
    type: snackbarConstants.OPEN_SNACKBAR,
    message,
    error,
  }),
  closeSnackbar: () => ({
    type: snackbarConstants.CLOSE_SNACKBAR,
  }),
};

const initialState = {
  open: false,
  message: "",
  error: false,
};

const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case snackbarConstants.OPEN_SNACKBAR: {
      return {
        ...state,
        open: true,
        message: action.message,
        error: action.error,
      };
    }

    case snackbarConstants.CLOSE_SNACKBAR: {
      return {
        ...state,
        open: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default snackbarReducer;
