import MuiButtonGroup from "@material-ui/core/ButtonGroup";
import MuiButton from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

export const Button = withStyles(theme => ({
  root: {
    borderRadius: "16px 16px 0 0",
    width: "100%",
    fontSize: "20px",
    letterSpacing: "2px",
    color: theme.palette.text.secondary,
  },
  textPrimary: {
    letterSpacing: "0.7px",
    color: theme.palette.primary.main,
    fontWeight: "600",
  },
}))(MuiButton);

export const ButtonGroup = withStyles(theme => ({
  root: {
    width: "100%",
    height: "50px",
    borderRadius: "16px 16px 0 0",
    borderBottom: "1px solid #DDDDDD",
    marginBottom: "20px",
    "& .MuiButtonGroup-groupedTextPrimary:not(:last-child)": {
      borderColor: "#DDDDDD",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0px",
    },
  },
}))(MuiButtonGroup);
