import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "20px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    whiteSpace: "break-spaces",
  },
  text: {
    fontSize: "20px",
    textTransform: "uppercase",
    color: theme.palette.text.disabled,
    textAlign: "center",
  },
}));

const Loader = ({ isLoading, isError, elements, noElementsText, errorText }) => {
  const classes = useStyles();

  if (!isLoading && !isError && elements?.length > 0) {
    return null;
  }

  return (
    <Box className={classes.root}>
      {isLoading ? (
        <CircularProgress size={48} />
      ) : (
        <Typography className={classes.text}>{isError ? errorText : noElementsText}</Typography>
      )}
    </Box>
  );
};

export default Loader;
