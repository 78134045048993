import FilledInput from "../FilledInput/FilledInput";
import { withStyles } from "@material-ui/core/styles";

const TextField = withStyles(theme => ({
  root: {
    "& .MuiFilledInput-root": {
      height: "64px",
      borderRadius: "32px !important",
      [theme.breakpoints.down("sm")]: {
        height: "38px",
        borderRadius: "19px !important",
      },
    },
    "& .MuiFilledInput-input": {
      fontSize: "20px",
      padding: "0 45px",
      "&::placeholder": {
        textTransform: "uppercase",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        padding: "0 35px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "0 20px",
        boxSizing: "border-box",
      },
    },
    "& .MuiFormHelperText-root": {
      fontSize: "16px",
      lineHeight: "28px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
  },
}))(FilledInput);

export default TextField;
