import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "../../../redux/modules/userReducer";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import logo from "../../../static/km-logo-no-text.png";
import Loader from "../../../components/Loader/Loader";
import CoatOfArms from "../../../components/CoatOfArms/CoatOfArms";
import useIsMobile from "../../../util/useIsMobile";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  headerRoot: {
    display: "flex",
    alignItems: "center",
    marginBottom: "25px",
  },
  icon: {
    width: "28px",
    height: "30px",
    objectFit: "contain",
    objectPosition: "center",
    marginRight: "10px",
  },
  header: {
    fontWeight: "400",
  },
  button: {
    width: "100%",
    maxWidth: "445px",
    marginBottom: "15px",
  },
  buttonLogo: {
    width: "24px",
    height: "29px",
    objectFit: "contain",
    objectPosition: "center",
  },
  inputRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    backgroundColor: "#F7F7F7",
    borderRadius: "20px",
    border: "1px solid #DDDDDD",
    cursor: "pointer",
  },
}));

const RoundButton = withStyles(theme => ({
  root: {
    height: "44px",
    borderRadius: "22px",
    padding: "5px 48px",
    justifyContent: "flex-start",
    boxSizing: "border-box",
    [theme.breakpoints.down("xs")]: {
      height: "36px",
      padding: "5px 20px",
    },
  },
  label: {
    fontSize: "20px",
    letterSpacing: "2px",
    fontWeight: "400",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
    },
    "& .MuiButton-startIcon": {
      marginRight: "30px",
      [theme.breakpoints.down("xs")]: {
        marginRight: "15px",
      },
    },
  },
}))(Button);

const EditOffice = ({ dialogOpen, onClose }) => {
  const classes = useStyles();
  const isMobile = useIsMobile("xs");
  const selectedOfficeId = useSelector(state => state.userReducer.selectedOffice);
  const [tmpSelectedId, setTmpSelectedId] = useState(selectedOfficeId);
  const townData = useSelector(state => state.userReducer.townData) || [];
  const isLoadingTownData = useSelector(state => state.userReducer.isLoadingTownData);

  const dispatch = useDispatch();
  const isLocalSpot = process.env.REACT_APP_LOCALSPOT_TYPE === "localspot";

  useEffect(() => {
    if (dialogOpen) {
      setTmpSelectedId(selectedOfficeId);
    }
  }, [dialogOpen, selectedOfficeId]);

  const handleSave = () => {
    dispatch(userAction.setSelectedOffice({ selectedOffice: tmpSelectedId }));
    onClose();
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      scroll="body"
    >
      <DialogTitle>{selectedOfficeId ? "Zmień" : "Wybierz"} urząd</DialogTitle>
      <DialogContent className={classes.root}>
        <Box className={classes.headerRoot}>
          <img
            src={logo}
            alt={isLocalSpot ? "logo LocalSpot" : "logo Komunikatora Miejskiego"}
            className={classes.icon}
          />
          <Typography component="h3" variant="h3" className={classes.header}>
            Wybierz z listy urzędów partnerskich
          </Typography>
        </Box>
        <Loader isLoading={isLoadingTownData} elements={townData} />
        {townData.map(town => (
          <RoundButton
            key={town.id}
            startIcon={<CoatOfArms name={town.logo} className={classes.buttonLogo} />}
            className={classes.button}
            variant="outlined"
            color={town.id === tmpSelectedId ? "primary" : "default"}
            onClick={() => setTmpSelectedId(town.id)}
          >
            {town.gmina ? "Gmina " : ""}
            {town.name}
          </RoundButton>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="primary" size="small">
          Anuluj
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={tmpSelectedId < 0 || tmpSelectedId === selectedOfficeId}
          onClick={handleSave}
        >
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditOffice;
