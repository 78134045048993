import { axios } from "../../util/axiosConfig";
import { incidents } from "../urls";

const getIncidents = ({
  northEast,
  southWest,
  activeStatuses,
  showFavourite,
  showArchive,
  showGov,
}) =>
  axios
    .get(incidents.search, {
      params: {
        "northEast.lat": northEast.lat,
        "northEast.lng": northEast.lng,
        "southWest.lat": southWest.lat,
        "southWest.lng": southWest.lng,
        scope: showArchive ? 1 : 2,
        heartFilter: showFavourite ? true : false,
        status: activeStatuses.sort().join(","),
        gov: showGov,
      },
    })
    .then(response => response.data);

export default getIncidents;
