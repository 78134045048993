import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Map from "../Map/TownHallMap";
import List from "./components/List";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100%",
    flexGrow: "1",
    alignItems: "stretch",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up("md")]: {
      minHeight: "700px",
      height: props => props.height ? props.height : "calc(100vh - 78px)",
    },
  },
  sidebar: {
    width: "30%",
    borderRight: "1px solid #DDDDDD",
    [theme.breakpoints.down(1280)]: {
      width: "40%",
    },
  },
  rightSide: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(1280)]: {
      width: "60%",
    },
  },
  mapContainer: {
    width: "100%",
    height: "calc(calc(100vh - 78px) * 0.5)",
    minHeight: "400px",
  },
  announcements: {
    display: "flex",
    flexGrow: "1",
    width: "100%",
    borderTop: "1px solid #DDDDDD",
    overflow: "hidden",
  },
}));

const SplitScreen = ({
  renderLeftSide,
  incidents,
  announcements,
  geoJson,
  renderLeftList,
  renderRightList,
  leftListTitle,
  rightListTitle,
  renderAnnouncements,
  height,
}) => {
  const classes = useStyles({ height });

  return (
    <Box className={classes.root}>
      <Box className={classes.sidebar}>
        <Box>{renderLeftSide}</Box>
      </Box>
      <Box className={classes.rightSide}>
        <Map
          incidents={incidents}
          announcements={announcements}
          geoJson={geoJson}
          className={classes.mapContainer}
        />
        {renderAnnouncements ? (
          renderAnnouncements
        ) : (
          <Box className={classes.announcements}>
            <List title={leftListTitle} renderList={renderLeftList} />
            <List title={rightListTitle} renderList={renderRightList} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SplitScreen;
