import { ButtonBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { images } from "../../../../../../services/urls";
import noPhoto from "../../../../../../static/map/sidebar/no-photo-lg.png";
import noPhotoLs from "../../../../../../static/map/sidebar/no-photo-ls-lg.png";

const useStyles = makeStyles(theme => ({
  imageRoot: {
    margin: props => (props.isComment ? "auto" : "0"),
    padding: props => (props.isComment ? "18px 0 0" : "18px 13px 18px 30px"),
    boxSizing: "content-box",
    display: "flex",
    justifyContent: "center",
    borderBottom: props => (props.isComment ? "" : "1px solid #DDDDDD"),
  },
  image: {
    maxWidth: "100%",
    maxHeight: "360px",
    objectPosition: "center",
    borderRadius: "13px",
    backgroundColor: theme.palette.text.disabled,
  },
}));

const Image = ({ markerId, imageId, isIncident, isComment, handleDialogOpen }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const classes = useStyles({ isComment, isLoading, error });
  const isLocalSpot = process.env.REACT_APP_LOCALSPOT_TYPE === "localspot";
  const errorImage = isLocalSpot ? noPhotoLs : noPhoto;

  useEffect(() => {
    setError(false);
    setIsLoading(true);
  }, [imageId, markerId]);

  const handleImageLoaded = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
    setError(true);
  };

  const handleImageClick = () => {
    if (!isLoading && !error) {
      handleDialogOpen(imageId);
    }
  };

  return (
    <ButtonBase
      disableRipple
      className={classes.imageRoot}
      onClick={handleImageClick}
      aria-label={!isLoading && !error ? "Pokaż zdjęcia w modalu" : ""}
      disabled={isLoading || error}
    >
      <img
        onLoad={handleImageLoaded}
        onError={handleImageError}
        src={
          error
            ? errorImage
            : isIncident
            ? images.incident + `/${markerId}/${imageId}.jpg`
            : isComment
            ? images.incident + `/${markerId}/comment/${imageId}.jpg`
            : images.announcement + `/${imageId}.jpg`
        }
        alt={error ? "Brak zdjęcia" : ""}
        className={classes.image}
      />
    </ButtonBase>
  );
};

export default Image;
