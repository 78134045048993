import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { quotes } from "./quotes";
import quoteIcon from "../../../../static/quote.png";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "140px",
    width: "100%",
    display: "flex",
    alignItems: "stretch",
    padding: "10px 50px",
    background: "#14387B",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 20px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none"
    },
  },
  iconButton: {
    color: "#FFFFFF",
    width: "72px",
    height: "72px",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      width: "48px",
      height: "48px",
    },
  },
  icon: {
    fontSize: "64px",
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },
  spacer: {
    flexGrow: "1",
    margin: "0px 20px",
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      margin: "0px 10px",
    }
  },
  quoteRoot: {
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
  },
  quoteRootInvisible: {
    display: "flex",
    visibility: "hidden",
  },
  quoteIcon: {
    margin: "8px 14px 0px 0px",
    height: "32px",
    width: "35px",
  },
  quoteText: {
    fontSize: "25px",
    letterSpacing: "2.5px",
    fontWeight: "500",
    lineHeight: "36px",
    color: "#FFFFFF",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      letterSpacing: "2px",
      lineHeight: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
      lineHeight: "25px",
    },
  },
  span: {
    fontWeight: "600",
  },
  borderBottom: {
    width: "175px",
    height: "3px",
    borderRadius: "1.5px",
    backgroundColor: "#3D64B8",
  },
  author: {
    fontSize: "16px",
    letterSpacing: "1.6px",
    lineHeight: "25px",
    fontWeight: "500",
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
      letterSpacing: "1.3px",
      lineHeight: "20px",
    },
  },
}));

const Quote = ({ quote }) => {
  const classes = useStyles();
  return (
    <Box display="flex">
      <img src={quoteIcon} alt="" className={classes.quoteIcon} />
      <Box>
        <Typography className={classes.quoteText}>
          {quote.text.split("|").map((text, index) => {
            const boldIndex = quote.startsWithBold ? 0 : 1;
            return index % 2 === boldIndex ? (
              <span key={index} className={classes.span}>
                {text}
              </span>
            ) : (
              text
            );
          })}
        </Typography>
        <Box className={classes.borderBottom} />
        <Typography className={classes.author}>{quote.author}</Typography>
      </Box>
    </Box>
  );
};

const getOppositeDirection = dir => (dir === "right" ? "left" : "right");

const Refferals = () => {
  const classes = useStyles();
  const [page, setPage] = useState(null);
  const [direction, setDirection] = useState("right");
  const [firstRender, setRender] = useState(true);

  useEffect(() => {
    const page = sessionStorage.getItem("localspotQuotePage");
    if (page) {
      setPage(Number.parseInt(page));
    } else {
      setPage(0);
    }
  }, []);

  useEffect(() => {
    if (page === null) {
      return;
    }
    sessionStorage.setItem("localspotQuotePage", page);
  }, [page]);

  const handleEnter = () => {
    setRender(false);
  };

  const handleClick = val => {
    const newPage = page + val;
    setDirection(val > 0 ? "left" : "right");
    if (newPage === quotes.length) {
      setPage(0);
    } else if (newPage === -1) {
      setPage(quotes.length - 1);
    } else {
      setPage(newPage);
    }
  };

  return (
    <Box className={classes.root}>
      <IconButton
        aria-label="Przejdź do poprzedniego cytatu"
        onClick={() => handleClick(-1)}
        className={classes.iconButton}
      >
        <ChevronLeftIcon className={classes.icon} />
      </IconButton>
      <Box className={classes.spacer}>
        {quotes.map((quote, index) => (
          <Slide
            in={page === index}
            key={index}
            direction={page === index ? getOppositeDirection(direction) : direction}
            timeout={firstRender ? 0 : undefined}
            onEntered={() => handleEnter()}
            mountOnEnter
            unmountOnExit
          >
            <Box className={classes.quoteRoot}>
              <Quote quote={quote} />
            </Box>
          </Slide>
        ))}
      </Box>
      <IconButton
        aria-label="Przejdź do następnego cytatu"
        onClick={() => handleClick(1)}
        className={classes.iconButton}
      >
        <ChevronRightIcon className={classes.icon} />
      </IconButton>
    </Box>
  );
};

export default Refferals;
