import React from "react";
import Collapse from "@material-ui/core/Collapse";
import FilterBar from "./components/FilterBar";
import List from "./components/List";
import { useSelector } from "react-redux";
import { govMarkers } from "../../../../../static/map/govMarkers";
import { mapAction } from "../../../../../redux/modules/mapReducer";
import useIsMobile from "../../../../../util/useIsMobile";

const AnnouncementsList = ({ showCollapse }) => {
  const isMobile = useIsMobile();
  const activeTypes = useSelector(state => state.mapReducer.activeTypes);
  const announcements = useSelector(state => state.mapReducer.announcements);

  const Wrapper = isMobile ? Collapse : React.Fragment;
  const wrapperProps = isMobile ? { in: showCollapse } : undefined;

  return (
    <React.Fragment>
      <Wrapper {...wrapperProps}>
        <FilterBar
          types={["GOV", "BUS", "OSI", "ATR"]}
          activeIcons={govMarkers}
          active={activeTypes}
          changeActive={mapAction.changeActiveTypes}
          searchAction={mapAction.searchAnnouncements}
          placeholder="SZUKAJ OGŁOSZEŃ"
        />
      </Wrapper>
      <List markers={announcements} />
    </React.Fragment>
  );
};

export default AnnouncementsList;
