import { put, call, takeLatest } from "redux-saga/effects";
import { userConstants } from "../modules/userReducer";
import login from "../../services/user/login";
import getAccountDetails from "../../services/user/getAccountDetails";
import getAllCitiesData from "../../services/townHall/getAllCitiesData";

function* loginUser({ email, password }) {
  try {
    const payload = yield call(login, { email, password });
    if (payload.message === "OK") {
      yield put({
        type: userConstants.LOGIN.SUCCESS,
        results: payload.results,
        token: payload.token,
      });
    } else {
      yield put({ type: userConstants.LOGIN.FAILED, message: payload.message });
    }
  } catch (error) {
    yield put({ type: userConstants.LOGIN.FAILED, error });
  }
}

function* getUserDetails() {
  try {
    const payload = yield call(getAccountDetails);
    if (payload.message === "OK") {
      yield put({
        type: userConstants.LOGIN.SUCCESS,
        results: payload.results,
        token: payload.token,
      });
    } else {
      yield put({ type: userConstants.LOGIN.FAILED, message: payload.message });
    }
  } catch (error) {
    yield put({ type: userConstants.LOGIN.FAILED, error });
  }
}

function* downloadTownData() {
  try {
    const payload = yield call(getAllCitiesData);
    yield put({ type: userConstants.GET_CITY_DATA.SUCCESS, results: payload });
  } catch (error) {
    yield put({ type: userConstants.GET_CITY_DATA.FAILED, error });
  }
}

function* watchUser() {
  yield takeLatest(userConstants.LOGIN.ACTION, loginUser);
  yield takeLatest(userConstants.GET_USER_DETAILS, getUserDetails);
  yield takeLatest(userConstants.GET_CITY_DATA.ACTION, downloadTownData);
}

export default watchUser;
