import React from "react";
import MuiCheckbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { withStyles } from "@material-ui/styles";

const StyledCheckbox = withStyles(() => ({
  root: {
    width: "24px",
    height: "24px",
    marginRight: "10px",
  },
}))(MuiCheckbox);

const StyledLabel = withStyles(() => ({
  root: {
    margin: "0px",
  },
}))(FormControlLabel);

const Checkbox = ({ handleChange, label, labelProps, ...checkboxProps }) => {
  return (
    <StyledLabel
      control={
        <StyledCheckbox
          color="primary"
          checkedIcon={<CheckCircleIcon />}
          icon={<RadioButtonUncheckedIcon />}
          onChange={handleChange}
          disableRipple
          {...checkboxProps}
        />
      }
      label={label}
      {...labelProps}
    />
  );
};

export default Checkbox;
