import { all, fork } from "redux-saga/effects";

import watchMap from "./mapSaga";
import watchUser from "./userSaga";

function* rootSaga() {
  yield all([fork(watchMap), fork(watchUser)]);
}

export default rootSaga;
