import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import LabelWithPin from "../../../components/LabelWithPin/LabelWithPin";
import { useStyles } from "./styles";
import EditOffice from "./EditOffice";
import CoatOfArms from "../../../components/CoatOfArms/CoatOfArms";

const Office = () => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const townData = useSelector(state => state.userReducer.townData);
  const selectedOfficeId = useSelector(state => state.userReducer.selectedOffice);
  const selectedOffice = useMemo(
    () => townData?.find(t => t.id === selectedOfficeId),
    [selectedOfficeId, townData]
  );

  return (
    <React.Fragment>
      <Box className={classes.dataRoot}>
        <LabelWithPin
          size={20}
          fontWeight="400"
          textTransform="uppercase"
          label="Mój urząd"
          marginBottom="20px"
          color="primary"
        />
        <Box className={classes.row}>
          <Box className={classes.cityRoot}>
            {selectedOffice && (
              <CoatOfArms name={selectedOffice.logo} className={classes.coatOfArms} />
            )}
            <Typography className={selectedOffice ? classes.value : classes.notFound}>
              {selectedOffice
                ? (selectedOffice.gmina ? "gmina " : "") + selectedOffice.name
                : "Nie wybrano"}
            </Typography>
          </Box>
        </Box>
      </Box>
      {selectedOffice && (
        <Button
          variant="contained"
          size="small"
          color="primary"
          className={classes.button}
          component={Link}
          to={`/city/${selectedOffice.geoJSON}`}
        >
          Zobacz stronę urzędu
        </Button>
      )}
      <Button
        variant="outlined"
        size="small"
        color="primary"
        className={classes.button}
        onClick={() => setDialogOpen(true)}
      >
        {selectedOffice ? "Zmień" : "Wybierz"} urząd
      </Button>
      <EditOffice dialogOpen={dialogOpen} onClose={() => setDialogOpen(false)} />
    </React.Fragment>
  );
};

export default Office;
