import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import activateAccount from "../../../services/user/activateAccount";
import Header from "../components/Header";
import Root from "../components/Root";
import { useLoginStyles } from "../useLoginStyles";

const ActivateAccount = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const classes = useLoginStyles({ showMessage: error !== "" });
  const history = useHistory();
  const { token } = useParams();

  useEffect(() => {
    if (!token) {
      return;
    }

    activateAccount(token)
      .then(response => {
        const { message, code } = response;
        if (code !== "000") {
          setError(message);
          setIsLoading(false);
        } else {
          history.push(`/aktywuj/${token}/sukces`, { date: new Date().getTime() });
        }
      })
      .catch(error => {
        const message = error?.response?.data?.message;
        setError(message ? message : "Wystąpił nieznany błąd. Spróbuj ponownie później.");
        setIsLoading(false);
      });
  }, [token]);

  if (!token || !/(\S+\.){2}(\S+)/.test(token)) {
    return <Redirect to="/login" />;
  }

  return (
    <Root>
      <Box className={classes.root}>
        <Header header="Aktywacja konta" />
        {isLoading ? (
          <CircularProgress size={48} />
        ) : (
          <Typography className={classes.helperText}>{error}</Typography>
        )}
      </Box>
    </Root>
  );
};

export default ActivateAccount;
