import React, { useEffect, useMemo, useState } from "react";
import Box from "@material-ui/core/Box";
import BackRow from "./components/BackRow";
import Typography from "@material-ui/core/Typography";
import TextField from "../../../../../components/FilledInput/FilledInput";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import Dropzone from "react-dropzone";
import AddIcon from "@material-ui/icons/Add";
import AttachmentIcon from "@material-ui/icons/AttachFile";
import Image from "./components/Image";
import ImageDialog from "./components/ImageDialog";
import CheckIcon from "@material-ui/icons/Check";
import Comment from "./components/Comment";
import { makeStyles } from "@material-ui/core/styles";
import { markers } from "../../../../../static/map/sidebar/markers";
import { images as imagesUrl } from "../../../../../services/urls";
import { mapAction } from "../../../../../redux/modules/mapReducer";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import closeIncident from "../../../../../services/map/closeIncident";
import getIncidentComments from "../../../../../services/map/getIncidentComments";
import newComment from "../../../../../services/map/newComment";
import createIncidentHeart from "../../../../../services/map/createIncidentHeart";
import createAnnouncementHeart from "../../../../../services/map/createAnnouncementHeart";
import removeIncidentHeart from "../../../../../services/map/removeIncidentHeart";

const colors = {
  1: "#D72D21",
  2: "#EFA852",
  3: "#418B5C",
  4: "#BABABA",
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "contents",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      height: "calc(100% + 20px)",
      position: "relative",
      top: "-20px",
      zIndex: "1",
    },
  },
  titleAndIcon: {
    display: "flex",
    flexGrow: "1",
    padding: "13px 25px 8px 15px",
  },
  titleRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "0",
    flexGrow: "1",
    marginRight: "10px",
  },
  title: {
    fontSize: "20px",
    letterSpacing: "2px",
    fontWeight: "600",
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  address: {
    fontSize: "18px",
    letterSpacing: "1.8px",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  circle: {
    minWidth: "34px",
    width: "34px",
    height: "34px",
    borderRadius: "50%",
    backgroundColor: props => props.color,
    boxShadow: "0px 3px 6px #00000040",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  circleIcon: {
    width: "22px",
    height: "22px",
    filter: "drop-shadow(0 3px 3px #00000040)",
  },
  contentRoot: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  closeButtonRoot: {
    padding: "18px 0",
    display: "flex",
    justifyContent: "center",
    borderBottom: "1px solid #DDDDDD",
  },
  container: {
    padding: "18px 0",
    display: "flex",
    justifyContent: "center",
    borderBottom: "1px solid #DDDDDD",
  },
  commentsRoot: {
    flexGrow: "1",
    height: "0",
  },
  addCommentRoot: {
    padding: "9px 32px 15px",
    borderTop: "1px solid #DDDDDD",
  },
  addCommentRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&:not(:last-child)": {
      marginBottom: "7px",
    },
  },
  input: {
    width: "70%",
  },
  iconButton: {
    width: "40px",
    height: "40px",
    border: "1px solid #DDDDDD",
    color: theme.palette.primary.main,
    display: "flex !important",
  },
  commentAvatar: {
    borderRadius: "50%",
    height: "36px",
    width: "36px",
    objectFit: "cover",
    marginRight: "5px",
  },
  commentName: {
    fontSize: "15px",
    letterSpacing: "0.75px",
    textTransform: "uppercase",
  },
  commentImage: {
    width: "100%",
    objectFit: "cover",
    objectPosition: "center",
    maxHeight: "400px",
    borderRadius: "13px",
  },
  attachment: {
    fontSize: "13px",
    letterSpacing: "1.3px",
  },
  attachmentName: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
}));

const ActiveMarker = ({ setHeader }) => {
  const incidents = useSelector(state => state.mapReducer.orgIncidents);
  const announcements = useSelector(state => state.mapReducer.orgAnnouncements);
  const activeMarker = useSelector(state => state.mapReducer.activeMarker);
  const activeMarkerError = useSelector(state => state.mapReducer.activeMarkerError);
  const isLoggedIn = useSelector(state => state.userReducer.isLoggedIn);
  const avatar = useSelector(state => state.userReducer.avatar);
  const name = useSelector(state => state.userReducer.name);
  const [comments, setComments] = useState([]);
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [images, setImages] = useState([]);
  const [recentImageId, setRecentImageId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addedFile, setAddedFile] = useState(null);
  const [file, setFile] = useState(null);
  const [nick, setNick] = useState("");
  const [comment, setComment] = useState("");
  const [isLikingComment, setIsLikingComment] = useState(false);
  const [isUploadingComment, setIsUploadingComment] = useState(false);
  const [closingIncident, setClosingIncident] = useState(false);
  const classes = useStyles({ color: activeMarker && colors[activeMarker.status] });
  const { type, id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const isIncident = type === "details";
  const toDispatch = isIncident
    ? mapAction.searchSingleIncident
    : mapAction.searchSingleAnnouncement;
  const showCloseButton = useMemo(
    () =>
      activeMarker && activeMarker.author && activeMarker.closeable && activeMarker.status === "1",
    [activeMarker]
  );

  useEffect(() => {
    if (setHeader) {
      if (activeMarker) {
        setHeader(renderHeader());
      } else {
        setHeader(null);
      }
    }

    if (activeMarker) {
      const imageId = activeMarker.bigOriginalImgId || activeMarker.bigImgId;
      if (imageId) {
        setImages(images => [imageId, ...images.filter(i => i !== imageId)]);
      }
    }
  }, [activeMarker]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const isIncident = type === "details";
    const arr = isIncident ? incidents : announcements;
    const marker = arr.find(m => m.id === id);
    dispatch(mapAction.clearNewMarker());
    setImages([]);
    setRecentImageId(null);
    if (!marker) {
      dispatch(toDispatch({ id }));
    } else {
      dispatch(mapAction.setActiveMarkerId({ id, marker, type }));
    }
  }, [type, id]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadComments = () => {
    if (type !== "details" || isLoadingComments) {
      return;
    }
    setIsLoadingComments(true);
    getIncidentComments(id)
      .then(response => {
        const comments = response.results.reverse();
        const imageIds = comments.map(c => c.bigImgId).filter(id => !!id);
        setComments(comments);
        setImages(images => [...images, ...imageIds.filter(i => !images.includes(i))]);
      })
      .catch(() => {
        setComments([]);
      })
      .finally(() => {
        setIsLoadingComments(false);
      });
  };

  useEffect(() => {
    setComments([]);
    setComment("");
    loadComments();
  }, [type, id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setClosingIncident(false);
  }, [activeMarker]);

  const goBack = () => {
    dispatch(mapAction.clearActiveMarker());
    history.push("/map");
  };

  const onImageError = imageId => () => {
    setImages(images => images.filter(i => i !== imageId));
    loadComments();
  };

  const handleDialogOpen = imageId => {
    setRecentImageId(imageId);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const getImageUrl = imageId => {
    const index = images.findIndex(img => img === imageId);
    const markerImage = activeMarker?.bigOriginalImgId || activeMarker?.bigImgId;
    if (index === 0 && markerImage) {
      // Marker has an image attached and it's the first one in images array
      return isIncident
        ? imagesUrl.incident + `/${id}/${imageId}.jpg`
        : imagesUrl.announcement + `/${imageId}.jpg`;
    } else {
      return imagesUrl.incident + `/${id}/comment/${imageId}.jpg`;
    }
  };

  const handleNewImage = files => {
    const file = files[0];
    setFile(null);
    const reader = new FileReader();

    reader.onabort = () => setAddedFile(null);
    reader.onerror = () => setAddedFile(null);
    reader.onload = () => {
      const str = reader.result;
      setAddedFile(str);
      setFile(file);
    };
    reader.readAsDataURL(file);
  };

  const submitComment = () => {
    setIsUploadingComment(true);
    newComment(nick, comment, file, id)
      .then(() => {
        setComment("");
        setFile(null);
        setAddedFile(null);
        dispatch(toDispatch({ id }));
        loadComments();
      })
      .finally(() => {
        setIsUploadingComment(false);
      });
  };

  const handleHeartPress = () => {
    if (isLikingComment) {
      return;
    }

    setIsLikingComment(true);
    if (activeMarker.hearted) {
      removeHeart();
    } else {
      addHeart();
    }
  };

  const addHeart = () => {
    const addFunc = isIncident ? createIncidentHeart : createAnnouncementHeart;
    addFunc(nick, id)
      .then(() => {
        dispatch(toDispatch({ id }));
        loadComments();
      })
      .finally(() => setIsLikingComment(false));
  };

  const removeHeart = () => {
    if (!isIncident) {
      // Backend doesn't support unliking announcements
      return;
    }

    removeIncidentHeart(nick, id)
      .then(() => {
        dispatch(mapAction.searchSingleIncident({ id }));
        loadComments();
      })
      .finally(() => setIsLikingComment(false));
  };

  const handleCloseIncident = () => {
    setClosingIncident(true);
    closeIncident(id)
      .then(response => {
        if (response.code !== "000") {
          setClosingIncident(false);
        } else {
          dispatch(toDispatch({ id }));
          loadComments();
        }
      })
      .catch(() => {
        setClosingIncident(false);
      });
  };

  const renderHeader = () => {
    if (!activeMarker) {
      return null;
    }

    return (
      <BackRow onGoBack={goBack}>
        <Box className={classes.titleAndIcon}>
          <Box className={classes.titleRoot}>
            <Typography className={classes.title}>
              {isIncident && `#${activeMarker.id} `}
              {activeMarker.categoryName}
            </Typography>
            {!activeMarker.address || activeMarker.address === "null" ? (
              <Box style={{ height: "24px" }} />
            ) : (
              <Typography className={classes.address}>
                {activeMarker.address.replace(", Polska", "")}
              </Typography>
            )}
          </Box>
          {isIncident && (
            <Box className={classes.circle}>
              <img
                src={markers[activeMarker.status]}
                alt={activeMarker.statusName}
                className={classes.circleIcon}
              />
            </Box>
          )}
        </Box>
      </BackRow>
    );
  };

  if (type !== "details" && type !== "ogloszenie") {
    goBack();
  }

  if (!activeMarker && activeMarkerError) {
    goBack();
  }

  if (!activeMarker) {
    return null;
  }

  return (
    <Box className={classes.root}>
      {!setHeader && renderHeader()}
      <Box className={classes.contentRoot} data-body-scroll-lock-ignore>
        <Image
          markerId={activeMarker.id}
          isIncident={isIncident}
          imageId={activeMarker.bigImgId || activeMarker.mediumImgId}
          handleDialogOpen={handleDialogOpen}
        />
        {showCloseButton && (
          <Box className={classes.container}>
            <Button
              variant="contained"
              color="primary"
              disabled={closingIncident}
              startIcon={closingIncident ? <CircularProgress size={24} /> : <CheckIcon />}
              onClick={handleCloseIncident}
            >
              Zamknij zgłoszenie
            </Button>
          </Box>
        )}
        <Box className={classes.commentsRoot}>
          <Comment
            comment={activeMarker}
            commentCount={comments.length}
            isTopComment
            isIncident={isIncident}
            isLikingComment={isLikingComment}
            handleHeartPress={handleHeartPress}
          />
          {comments.map(comment => (
            <Comment
              comment={comment}
              key={comment.createDate}
              markerId={activeMarker.id}
              handleDialogOpen={handleDialogOpen}
              isIncident
            />
          ))}
        </Box>
      </Box>
      {isIncident && (
        <Box className={classes.addCommentRoot} key={`${id}-${comments.length}`}>
          <Box className={classes.addCommentRow}>
            {isLoggedIn ? (
              <Box display="flex" alignItems="center">
                <img
                  src={avatar ? imagesUrl.avatar + `/${avatar}.jpg` : markers.avatar}
                  alt={name}
                  className={classes.commentAvatar}
                />
                <Typography className={classes.commentName}>{name}</Typography>
              </Box>
            ) : (
              <TextField
                placeholder="Podpis"
                variant="filled"
                className={classes.input}
                value={nick}
                onChange={e => setNick(e.target.value)}
              />
            )}
            <Fab
              color="primary"
              size="small"
              onClick={() => submitComment()}
              disabled={!comment || isUploadingComment}
            >
              <AddIcon />
            </Fab>
          </Box>
          <Box className={classes.addCommentRow}>
            <TextField
              placeholder="Komentarz"
              variant="filled"
              multiline
              maxRows={5}
              className={classes.input}
              onChange={e => setComment(e.target.value)}
            />
            <Dropzone
              multiple={false}
              accept={["image/jpeg", "application/pdf"]}
              onDropAccepted={handleNewImage}
              noDrag
            >
              {({ getRootProps, getInputProps }) => (
                <Box {...getRootProps()}>
                  <input {...getInputProps()} />
                  <IconButton className={classes.iconButton}>
                    <AttachmentIcon />
                  </IconButton>
                </Box>
              )}
            </Dropzone>
          </Box>
          {file?.type === "application/pdf" && (
            <Box>
              <Typography className={classes.attachment}>
                Dodano załącznik <span className={classes.attachmentName}>{file.name}</span>
              </Typography>
            </Box>
          )}
          {addedFile && file?.type !== "application/pdf" && (
            <img
              src={addedFile}
              alt="Zdjęcie dodane przez użytkownika"
              className={classes.commentImage}
            />
          )}
        </Box>
      )}
      <ImageDialog
        dialogOpen={dialogOpen}
        images={images}
        onClose={handleDialogClose}
        getImageUrl={getImageUrl}
        recentId={recentImageId}
        onError={onImageError}
      />
    </Box>
  );
};

export default ActiveMarker;
