import axios from "axios";

const axiosInit = axios.create();
axiosInit.interceptors.request.use(
  config => {
    const token = window.localStorage.getItem("apiKey");
    config.baseURL = process.env.REACT_APP_BASE_URL;
    config.headers = {
      "app-version": "1.0.0",
      "Accept-Language": "pl-PL,pl;q=0.9,en-US;q=0.8,en;q=0.7",
      token: token,
    };
    !token && delete config.headers.token;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export { axiosInit as axios };
