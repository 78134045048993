import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import LeftIcon from "@material-ui/icons/KeyboardArrowLeftRounded";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  topRoot: {
    height: "74px",
    display: "flex",
    borderBottom: "1px solid #DDDDDD",
  },
  button: {
    height: "100%",
    borderRadius: "14px 0 0 0",
    borderRight: "1px solid #DDDDDD",
    width: "60px",
    minWidth: "unset",
  },
  backIcon: {
    fontSize: "60px",
  },
}));

const BackRow = ({ onGoBack, ...props }) => {
  const classes = useStyles();
  return (
    <Box className={classes.topRoot}>
      <Button onClick={() => onGoBack()} color="primary" className={classes.button}>
        <LeftIcon className={classes.backIcon} />
      </Button>
      {props.children}
    </Box>
  );
};

export default BackRow;
