import React from "react";
import FilledInput from "../FilledInput/FilledInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

const SearchField = React.forwardRef(({ endAdornment, ...props }, ref) => (
  <FilledInput
    ref={ref}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          {endAdornment ? endAdornment : <SearchIcon />}
        </InputAdornment>
      ),
    }}
    {...props}
  />
));

export default SearchField;
