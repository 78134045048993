import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import getAllCitiesData from "../../services/townHall/getAllCitiesData";
import BluePinIcon from "../../static/BluePinIcon";
import map from "../../static/topBanner/map.png";
import County from "./components/County/County";
import Refferals from "./components/Referrals/Referrals";

const useStyles = makeStyles(theme => ({
  outerRoot: {
    minHeight: "calc(100vh - 78px)",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minHeight: "calc(100vh - 60px)",
      height: "unset",
    },
  },
  root: {
    width: "100%",
    flexGrow: "1",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      height: "unset",
    },
  },
  image: {
    position: "relative",
    minWidth: "calc(100% - max(60%, 800px) + 320px)",
    backgroundSize: "cover",
    backgroundPositionX: "right",
    left: "-320px",
    [theme.breakpoints.down("md")]: {
      left: "-320px",
      minWidth: "calc(100% - max(70%, 800px) + 320px)",
      backgroundPosition: "center",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mapRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: "max(60%, 800px)",
    padding: "20px 320px 55px 50px",
    backgroundImage: `url(${map})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "right",
    backgroundPositionY: "-2px",
    zIndex: "1",
    [theme.breakpoints.down("md")]: {
      minWidth: "max(70%, 800px)",
      padding: "0px 285px 30px 100px",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
      width: "100%",
      backgroundPositionX: "left",
      padding: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "37px",
    },
    [theme.breakpoints.down(300)]: {
      padding: "20px",
      height: "fit-content",
    },
  },
  textRoot: {
    display: "flex",
    flexGrow: "1",
    alignItems: "center",
  },
  icon: {
    width: "40px",
    height: "54px",
    marginRight: "40px",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      marginRight: "24px",
      width: "24px",
      height: "32px",
    },
    [theme.breakpoints.down(300)]: {
      display: "none",
    },
  },
  header: {
    fontWeight: "600",
    whiteSpace: "break-spaces",
    lineHeight: "46px",
    [theme.breakpoints.down("md")]: {
      fontSize: "25px",
      letterSpacing: "2.5px",
      lineHeight: "30px",
      marginBottom: "15px",
      whiteSpace: "unset",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      letterSpacing: "2px",
      lineHeight: "30px",
    },
  },
  middleText: {
    fontSize: "25px",
    letterSpacing: "2.5px",
    lineHeight: "38px",
    maxWidth: "570px",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      letterSpacing: "2px",
      lineHeight: "30px",
    },
    [theme.breakpoints.down(theme.breakpoints.values.small)]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
      lineHeight: "25px",
    },
  },
  countiesHeader: {
    fontSize: "20px",
    letterSpacing: "2px",
    textAlign: "center",
    marginBottom: "20px",
    textTransform: "uppercase",
    fontWeight: "500",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
      letterSpacing: "1.8px",
      lineHeight: "27px",
    },
    [theme.breakpoints.down(theme.breakpoints.values.small)]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
      lineHeight: "25px",
    },
  },
  skeleton: {
    transform: "none",
  },
}));

const empty = Array(7).fill(0);

const TopBanner = ({ src, topText, middleText }) => {
  const classes = useStyles({ src });
  const [cities, setCities] = useState([]);

  useEffect(() => {
    getAllCitiesData().then(response => setCities(response));
  }, []);

  return (
    <Box className={classes.outerRoot}>
      <Box className={classes.root}>
        <Box className={classes.mapRoot}>
          <Box className={classes.textRoot}>
            <Box display="flex">
              <BluePinIcon className={classes.icon} />
              <Box>
                <Typography variant="h1" component="h2" className={classes.header}>
                  {topText}
                </Typography>
                <Typography className={classes.middleText}>{middleText}</Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography className={classes.countiesHeader}>
              Zobacz co zyskały miasta które już z nami są
            </Typography>
            <Grid container spacing={1} justifyContent="center">
              {cities.length
                ? cities.slice(0, 7).map(city => (
                    <Grid item key={city.name}>
                      <County name={city.name} url={`/city/${city.geoJSON}`} src={city.logo} />
                    </Grid>
                  ))
                : empty.map((_, i) => (
                    <Grid item key={i}>
                      <Skeleton width={76} height={92} className={classes.skeleton} />
                    </Grid>
                  ))}
            </Grid>
          </Box>
        </Box>
        <div style={{ backgroundImage: `url(${src})` }} className={classes.image} />
      </Box>
      <Refferals />
    </Box>
  );
};

export default TopBanner;
