import { useEffect, useState } from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";
import "leaflet.markercluster";

const MarkerCluster = ({ markers, iconCreateFunction }) => {
  const map = useMap();
  const [mcg, setMcg] = useState(null);

  useEffect(() => {
    const props = {
      chunkedLoading: true,
      showCoverageOnHover: false,
    };

    if (iconCreateFunction) props.iconCreateFunction = iconCreateFunction;
    const tmpMcg = L.markerClusterGroup(props);
    setMcg(tmpMcg);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!mcg) {
      return;
    }

    mcg.clearLayers();
    if (markers.length > 0) {
      mcg.addLayers(markers);
      map.addLayer(mcg);
    }

    return () => map.removeLayer(mcg);
  }, [markers, mcg, map]);

  return null;
};

export default MarkerCluster;
