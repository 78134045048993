import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Root from "../components/Root";
import Header from "../components/Header";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "600px",
  },
  text: {
    fontSize: "20px",
    letterSpacing: "2px",
    lineHeight: "30px",
    marginBottom: "25px",
    "&:last-of-type": {
      marginBottom: "50px",
    }
  },
  bottomText: {
    fontSize: "18px",
    letterSpacing: "1.8px",
    lineHeight: "30px",
    marginBottom: "65px",
    color: theme.palette.text.secondary,
  },
  loginButton: {
    height: "51px",
    minWidth: "305px",
    fontSize: "20px",
    fontWeight: "500",
    borderRadius: "25.5px",
    "&:not(:last-of-type)": {
      marginBottom: "25px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "37px",
      width: "170px",
      fontSize: "16px",
      borderRadius: "18.5px",
    },
  },
}));

const SuccessPage = ({ header, texts, buttons, buttonActions, disabledButtons }) => {
  const classes = useStyles();

  return (
    <Root>
      <Box className={classes.root}>
        <Header header={header} showSuccess />
        <Typography className={classes.text}>{texts[0]}</Typography>
        {texts[1] && <Typography className={classes.bottomText}>{texts[1]}</Typography>}
        <Button
          color="primary"
          variant="contained"
          className={classes.loginButton}
          disabled={disabledButtons && disabledButtons[0]}
          onClick={buttonActions[0]}
        >
          {buttons[0]}
        </Button>
        {buttons[1] && (
          <Button
            color="primary"
            variant="outlined"
            className={classes.loginButton}
            disabled={disabledButtons && disabledButtons[1]}
            onClick={buttonActions[1]}
          >
            {buttons[1]}
          </Button>
        )}
      </Box>
    </Root>
  );
};

export default SuccessPage;
