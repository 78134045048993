import React from "react";
import { Switch, Route } from "react-router-dom";
import RegisterPage from "./RegisterPage";
import Root from "../components/Root";
import Success from "./Success";

const RegisterWrapper = () => {
  return (
    <Switch>
      <Route path="/rejestracja/sukces">
        <Root>
          <Success />
        </Root>
      </Route>
      <Route path="/rejestracja">
        <RegisterPage />
      </Route>
    </Switch>
  );
};

export default RegisterWrapper;
