import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import CoatOfArms from "../../components/CoatOfArms/CoatOfArms";
import Loader from "../../components/Loader/Loader";
import MobileMap from "../../components/Map/MobileMap";
import SplitScreen from "../../components/SplitScreen/SpitScreen";
import { snackbarAction } from "../../redux/modules/snackbarReducer";
import getAnnoucementsByCity from "../../services/townHall/getAnnoucementsByCity";
import getCityData from "../../services/townHall/getCityData";
import getGeoJson from "../../services/townHall/getGeoJson";
import useIsMobile from "../../util/useIsMobile";
import AirQuality from "./components/AirQuality/AirQuality";
import Announcements from "./components/Announcements/Announcements";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100%",
    flexGrow: "1",
    alignItems: "stretch",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  sidebar: {
    width: "30%",
    borderRight: "1px solid #DDDDDD",
    [theme.breakpoints.down(1280)]: {
      width: "35%",
    },
  },
  rightSide: {
    width: "70%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(1280)]: {
      width: "65%",
    },
  },
  announcements: {
    flexGrow: "1",
    width: "100%",
    borderTop: "1px solid #DDDDDD",
  },
  detailBox: {
    padding: "25px 15px 25px 9%",
    borderBottom: "1px solid #DDDDDD",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "15px 35px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "fit-content",
    },
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start",
    },
  },
  coatOfArms: {
    height: "128px",
    width: "128px",
    minWidth: "128px",
    marginRight: "40px",
    objectFit: "contain",
    [theme.breakpoints.down(1280)]: {
      height: "64px",
      width: "64px",
      minWidth: "64px",
      marginRight: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "96px",
      width: "96px",
      minWidth: "96px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "64px",
      width: "64px",
      minWidth: "64px",
    },
  },
  image: {
    width: "100%",
    height: "100%",
  },
  addressContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  header: {
    fontSize: "20px",
    letterSpacing: "2px",
    fontWeight: "bolder",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      letterSpacing: "1.8px",
    },
  },
  cityName: {
    fontWeight: "bold",
    fontSize: "30px",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      lineHeight: "20px",
    },
  },
  address: {
    fontSize: "20px",
    letterSpacing: "2px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
    },
  },
  detailText: {
    fontSize: "18px",
    letterSpacing: "1.8px",
    display: "block",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
    },
  },
  highlight: {
    color: theme.palette.primary.main,
  },
}));

const TownPage = () => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const [announcements, setAnnoucements] = useState([]);
  const [isLoadingAnnouncements, setIsLoadingAnnouncements] = useState(true);
  const [city, setCity] = useState();
  const [isLoadingCity, setIsLoadingCity] = useState(true);
  const [geoJson, setGeoJson] = useState();
  const { city: cityName } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    getCityData(cityName)
      .then(cityData => {
        setCity(cityData);
      })
      .catch(e => {
        if (e?.response?.status === 404) {
          dispatch(
            snackbarAction.openSnackbar({
              message: "Takie miasto nie istnieje w systemie.",
              error: true,
            })
          );
        }
      })
      .finally(() => {
        setIsLoadingCity(false);
      });
  }, [cityName]);

  useEffect(() => {
    if (!city) return;

    getAnnoucementsByCity(city.id)
      .then(data => {
        setAnnoucements(data.results?.filter(a => a.latitude && a.longitude));
      })
      .catch(() => {
        setAnnoucements([]);
      })
      .finally(() => {
        setIsLoadingAnnouncements(false);
      });
  }, [city]);

  useEffect(() => {
    if (!city) return;

    getGeoJson(city.geoJSON)
      .then(geoJson => setGeoJson(geoJson))
      .catch(() =>
        dispatch(
          snackbarAction.openSnackbar({ message: "Błąd podczas pobierania mapy.", error: true })
        )
      );
  }, [city]);

  const renderCityDetails = useMemo(() => {
    if (isLoadingCity) {
      return <Loader isLoading />;
    }

    if (!city) {
      return null;
    }

    return (
      <Box className={classes.detailBox}>
        <Box display="flex" alignItems="center" alignSelf="center">
          <CoatOfArms className={classes.coatOfArms} name={city.logo} />
          <Box className={classes.addressContainer}>
            <Typography className={classes.header}>
              {city.powiat ? "" : city.gmina ? "Gmina" : "Miasto"}
            </Typography>
            <Typography className={classes.cityName}>{city.name}</Typography>
            <Typography className={classes.address}>{city.address}</Typography>
          </Box>
        </Box>
      </Box>
    );
  }, [city, isLoadingCity]);

  const renderOpeningHours = useMemo(() => {
    if (!city) {
      return null;
    }

    let day = new Date().getDay();
    const nextDay = day;
    day = day > 0 ? day - 1 : 6;
    const openingHours = city.openingHours[day];
    const now = new Date().getHours() * 60 + new Date().getMinutes();
    // Empty object - city office is closed
    const from = openingHours?.from ? openingHours.from.split(":") : -1;
    const to = openingHours?.to ? openingHours.to.split(":") : -1;
    const fromTime = from !== -1 ? Number.parseInt(from[0]) * 60 + Number.parseInt(from[1]) : -1;
    const toTime = to !== -1 ? Number.parseInt(to[0]) * 60 + Number.parseInt(to[1]) : -1;

    let text;

    if (!openingHours || !openingHours.from || now > toTime) {
      text = "jutro ";
      const nextOpeningHours = city.openingHours[nextDay];
      if (!nextOpeningHours) {
        text += "nieczynne";
      } else {
        text += `czynne od ${nextOpeningHours.from} do ${nextOpeningHours.to}`;
      }
    } else if (now < fromTime) {
      text = `dziś czynne od ${openingHours.from} do ${openingHours.to}`;
    } else {
      text = `dziś czynne do ${openingHours.to}`;
    }

    return (
      <Box className={classes.detailBox}>
        <Typography className={classes.header}>Godziny otwarcia</Typography>
        <Typography className={classes.detailText}>
          <span className={classes.highlight}>
            {fromTime <= now && now <= toTime ? "Czynne" : "Nieczynne"}
          </span>
          , {text}
        </Typography>
      </Box>
    );
  }, [city]);

  const renderContact = useMemo(() => {
    if (!city) {
      return null;
    }

    return (
      <Box className={classes.detailBox}>
        <Typography className={classes.header}>Kontakt</Typography>
        <Link underline="hover" href={`tel:${city.tel}`} className={classes.detailText}>
          {city.tel}
        </Link>
        <Link underline="hover" href={`mailto:${city.email}`} className={classes.detailText}>
          {city.email}
        </Link>
        <Link
          underline="hover"
          href={city.www.replace("www.", "https://")}
          className={classes.detailText}
          target="_blank"
        >
          {city.www}
        </Link>
        <Link underline="hover" href={``} className={classes.detailText}>
          {city.phone}
        </Link>
      </Box>
    );
  }, [city]);

  if (!city && !isLoadingCity) {
    return <Redirect to="/" />;
  }

  if (city && isMobile) {
    return (
      <Box className={classes.root}>
        {renderCityDetails}
        <MobileMap announcements={announcements} geoJson={geoJson} />
        <Box>
          {renderOpeningHours}
          {renderContact}
          <AirQuality airStation={city?.airStation} airStationAddress={city?.airStationAddress} />
          <Announcements announcements={announcements} />
        </Box>
      </Box>
    );
  }

  return (
    <SplitScreen
      announcements={announcements}
      geoJson={geoJson}
      renderLeftSide={
        <React.Fragment>
          {renderCityDetails}
          {renderOpeningHours}
          {renderContact}
          {city && (
            <AirQuality airStation={city?.airStation} airStationAddress={city?.airStationAddress} />
          )}
        </React.Fragment>
      }
      renderAnnouncements={
        <Announcements announcements={announcements} isLoading={isLoadingAnnouncements} />
      }
    />
  );
};

export default TownPage;
