import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import MobileStepper from "@material-ui/core/MobileStepper";
import IconButton from "@material-ui/core/IconButton";
import SwipeableViews from "react-swipeable-views";
import { virtualize } from "react-swipeable-views-utils";
import { mod } from "react-swipeable-views-core";
import { makeStyles } from "@material-ui/core/styles";
import BackIcon from "@material-ui/icons/ChevronLeft";
import NextIcon from "@material-ui/icons/ChevronRight";
import "react-swipeable-views/dist/legacy-browser-support.css"; //legacy browser support for SwipeableViews

const VirtualizedSwipeableViews = virtualize(SwipeableViews);

const useStyles = makeStyles(() => ({
  imageContainer: {
    position: "relative",
    overflow: "hidden",
    height: "100%",
    maxHeight: "640px",
  },
  image: {
    width: "100%",
    height: "100%",
    maxHeight: "640px",
    objectFit: "contain",
    objectPosition: "center",
    position: "relative",
  },
  backgroudImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
    opacity: "0.3",
    objectFit: "cover",
    objectPosition: "center",
    filter: "blur(3px)",
  },
  buttonContainer: {
    padding: "10px",
    height: "68px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  stepper: {
    position: "initial",
    background: "unset",
    minWidth: "unset",
  },
}));

const useDialogStyles = makeStyles(() => ({
  paper: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
}));

const ImageDialog = ({ dialogOpen, onClose, images, recentId, getImageUrl, onError }) => {
  const classes = useStyles();
  const dialogClases = useDialogStyles();
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const idx = images.findIndex(image => image === recentId);
    if (idx > -1) {
      setIndex(idx);
    }
  }, [images, recentId]);

  const renderSlide = ({ key, index }) => {
    const imgId = images[mod(index, images.length)];

    return (
      <div key={key} className={classes.imageContainer}>
        <img src={getImageUrl(imgId)} className={classes.backgroudImage} alt="" />
        <img src={getImageUrl(imgId)} onError={onError(imgId)} className={classes.image} alt="" />
      </div>
    );
  };

  const handleButtonClick = val => () => {
    setIndex(index => {
      const newIndex = index + val;
      if (newIndex < 0) {
        return images.length - 1;
      } else if (newIndex >= images.length) {
        return 0;
      }
      return newIndex;
    });
  };

  return (
    <Dialog
      open={dialogOpen && !!images.length}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      classes={dialogClases}
    >
      <VirtualizedSwipeableViews
        slideRenderer={renderSlide}
        slideCount={images.length}
        onChangeIndex={index => setIndex(index)}
        index={index}
        enableMouseEvents
      />
      <Box className={classes.buttonContainer}>
        {images.length > 1 && (
          <IconButton onClick={handleButtonClick(-1)} aria-label="Pokaż poprzednie zdjęcie">
            <BackIcon />
          </IconButton>
        )}
        <MobileStepper
          variant="dots"
          activeStep={index}
          steps={images.length}
          className={classes.stepper}
        />
        {images.length > 1 && (
          <IconButton onClick={handleButtonClick(1)} aria-label="Pokaż następne zdjęcie">
            <NextIcon />
          </IconButton>
        )}
      </Box>
    </Dialog>
  );
};

export default ImageDialog;
