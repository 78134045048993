import outline1 from "./outline-1.png";
import outline2 from "./outline-2.png";
import outline3 from "./outline-3.png";
import outline4 from "./outline-4.png";
import outlineGov from "./outline-gov.png";

export const outline = {
  1: outline1,
  2: outline2,
  3: outline3,
  4: outline4,
  ATR: outlineGov,
  BUS: outlineGov,
  GOV: outlineGov,
  OSI: outlineGov,
};
