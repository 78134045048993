const isLocalSpot = process.env.REACT_APP_LOCALSPOT_TYPE === "localspot";
const pageAddress = isLocalSpot ? "localspot" : "komunikatormiejski";

const powiatRadomski = `I. Postanowienia ogólne

Serwis Komunikator Powiatu Radomskiego jest platformą służącą rozwijaniu idei społeczeństwa obywatelskiego. Prowadzi do aktywizacji postaw proobywatelskich, promowania aktywności obywatelskich dotyczących przestrzeni publicznej i budowania lokalnych społeczności.
Niniejszy Regulamin określa warunki i zasady korzystania z Serwisu prowadzonego przez Administratora. Regulamin określa prawa i obowiązki Użytkowników oraz Administratora.
Warunkiem korzystania z Serwisu jest zapoznanie się z Regulaminem i jego akceptacja.

II. Definicje

Regulamin oznacza niniejszy regulamin.
Serwis oznacza serwis internetowy prowadzony pod nazwą domenową www.powiatradomski.komunikatromiejski.pl, w ramach którek Administrator świadczy usługi drogą elektroniczną.
Administrator oznacza Starostwo Powiatowe w Radomiu ul. Tadeusza Mazowieckiego 7, 26-600 Radom.
Użytkownik oznacza osobę fizyczną, osobę prawną lub jednostkę organizacyjną nieposiadającą osobowości prawnej, ale posiadającą zdolność prawną, korzystającą z Serwisu.
Użytkownik Zarejestrowany oznacza Użytkownika posiadającego aktywne konto w Serwisie.
Użytkownik Niezarejestrowany oznacza Użytkownika nie posiadającego aktywnego konta w Serwisie.
Użytkownik Zalogowany oznacza Użytkownika Zarejestrowanego, który pozostaje uwierzytelniony w Serwisie.
Użytkownik Gość oznacza Użytkownika, który pozostaje nieuwierzytelniony w Serwisie.
Zgłoszenie oznacza informacje dotyczące problemu w przestrzeni publicznej wprowadzone do Serwisu przez Użytkownika i przekazane do Administratora w celach interwencyjnych lub oznacza Zapytanie.
Zapytanie oznacza pytanie wprowadzone do Serwisu przez Użytkownika w celu uzyskania informacji od Administratora.
Komentarz oznacza uwagę lub dodatkowe objaśnienie umieszczone pod Zgłoszeniem, wprowadzone przez Użytkownika lub Administratora w celach informacyjnych.
Powiadomienie oznacza wiadomość wysłaną z Serwisu do Użytkownika poprzez email lub inny kanał komunikacyjny.
Podpis oznacza nazwę (inaczej pseudonim, nick) jaką Użytkownik określił w swoim profilu i pod jaką publikuje Zgłoszenia i Komentarze w Serwisie.

III. Wymagania techniczne

W celu prawidłowego korzystania z Serwisu Użytkownik powinien zapewnić:
Połączenie z siecią Internet o przepustowości min. 1 Mbps,|Urządzenie umożliwiające korzystanie z zasobów sieci Internet,|Zaktualizowaną przeglądarkę stron internetowych Firefox, Chrome, Opera, Internet Explorer, Safari, lub zaktualizowaną aplikację mobilną Komunikator Miejski na urządzeniu z systemem operacyjnym iOS lub Android.

IV. Rejestracja Użytkownika i zarządzanie profilem

Aby uzyskać status Użytkownika Zarejestrowanego należy założyć konto w Serwisie. Proces zakładania konta wymaga poprawnego wypełnienia formularza rejestracyjnego, a następnie dokonania aktywacji konta poprzez kliknięcie w link aktywacyjny w otrzymanym Powiadomieniu.
W formularzu rejestracyjnym Użytkownik podaje: adres e-mail, Podpis i hasło.
Podany przez Użytkownika podczas rejestracji adres e-mail stanowi login Użytkownika do Serwisu i wraz z hasłem wykorzystywane są do uwierzytelniania Użytkownika w trakcie logowania do Serwisu.
Podanie dodatkowych danych takich jak: imię, nazwisko, numer telefonu w celu dalszego kontaktu z Użytkownikiem, nie jest obligatoryjne i może zostać dokonane w dowolnym momencie poprzez formularz profilowy.
Podany przez Użytkownika Podpis jest publikowany w Serwisie pod Zgłoszeniami i Komentarzami wprowadzonymi przez Użytkownika.
Podane przez Użytkownika adres e-mail, imię, nazwisko i numer telefonu nie są publikowane w Serwisie.
W dowolnym momencie, poprzez formularz profilowy, Użytkownik może zmienić swoje dane, za wyjątkiem adresu e-mail i Podpisu.
Zabronione jest podawanie Podpisu jeśli ma on charakter obraźliwy, wulgarny, sprzeczny z dobrymi obyczajami lub zasadami współżycia społecznego lub będącej nazwą własną podlegającą ochronie prawnej lub w inny sposób niezgodnej z Regulaminem.
Po poprawnym wypełnieniu formularza rejestracyjnego, Użytkownik otrzyma, na podany w formularzu adres e-mail, Powiadomienie zawierające link aktywujący konto Użytkownika. Aktywacja konta wymaga kliknięcie w ten link.
Użytkownik wysyłając formularz rejestracyjny potwierdza, że zapoznał się z Regulaminem i zobowiązuje się go przestrzegać.
Użytkownik zobowiązany jest utrzymywać dane profilowe kompletne i zgodne ze stanem faktycznym oraz dbać by nie naruszały praw osób trzecich.
Dostęp do konta możliwy jest po uwierzytelnieniu Użytkownika. Pomyślne uwierzytelnienie wymaga wprowadzenia aktualnego adresu e-mail (loginu) i hasła.
Podanie nieprawdziwych danych lub informacji albo posłużenie się cudzymi danymi w celu skorzystania z Serwisu może podlegać odpowiedzialności karnej oraz odpowiedzialności odszkodowawczej.
Podane przez Użytkowników w Serwisie dane osobowe są zbierane i przetwarzane zgodnie z obowiązującymi przepisami prawa oraz zgodnie z polityką prywatności zawartą w Załączniku nr 1 – „Polityka Prywatności”.
Użytkownik zobowiązuje się nie udostępniać swojego hasła osobom trzecim pod rygorem poniesienia odpowiedzialności za szkody z tego powodu wynikłe na zasadach ogólnych wynikających z przepisów prawa. W przypadku wejścia osoby trzeciej w posiadanie hasła, a także w przypadku uzasadnionego podejrzenia takiej sytuacji, Użytkownik powinien niezwłocznie zmienić hasło oraz zawiadomić o tym Administratora.

V. Zasady działania i korzystania z Serwisu

Administrator zapewnia platformę wspierającą aktywności obywatelskie dotyczące przestrzeni publicznej i budowanie lokalnych społeczności.
Użytkownik może korzystać z poniższych usług świadczonych przez Serwis:
Wprowadzania nowych Zgłoszeń,|Popierania wprowadzonych Zgłoszeń,|Wprowadzania Komentarzy do Zgłoszeń,|Otrzymywania Powiadomień związanych z obsługą Zgłoszeń (m.in. informacje o postępach, zapytania o szczegóły),|Otrzymywania Powiadomień związanych z obsługą konta w Serwisie (m.in. resetowanie hasła, aktywacja konta),|Otrzymywania Powiadomień mogących wpłynąć na jakość życia mieszkańców, w tym o aktualnych wydarzeniach i zdarzeniach w przestrzeni publicznej.
Korzystanie z Serwisu jest nieodpłatne.
Korzystanie z Serwisu możliwe jest przez Użytkowników Zalogowanych i Użytkowników Gość.
Niektóre funkcje Serwisu są dostępne tylko dla Użytkowników Zalogowanych.
Niektóre funkcje Serwisu są dostępne dla Użytkowników Gość pod warunkiem podania adresu email.
Użytkownik może wprowadzić nowe Zgłoszenie w Serwisie w celu poinformowania Administratora o zaobserwowanym problemie w przestrzeni publicznej.
Użytkownik może wprowadzić Komentarz pod Zgłoszeniem w celu przekazania dodatkowego objaśnienia lub uwagi. Komentarze umożliwiają komunikację pomiędzy Użytkownikami oraz między Użytkownikami i Administratorem.
Użytkownik może poprzeć Zgłoszenie wprowadzone wcześniej w Serwisie przez innego Użytkownika, jeśli jest zainteresowany przyspieszeniem działań interwencyjnych.
Użytkownik Gość wprowadzając formularz rejestracyjny potwierdza, że zapoznał się z Regulaminem i zobowiązuje się go przestrzegać.
Treść Zgłoszeń i Komentarzy jest publikowana w Serwisie.
Treść Zgłoszeń jest przekazywana do Administratora w celu ich obsługi.
Niepełna (m.in. niepełny, niezrozumiały opis lub jego brak) lub nieprecyzyjna (m.in. nieprecyzyjne bądź niezgodne ze stanem faktycznym określenie lokalizacji) treść Zgłoszenia może uniemożliwić poprawną obsługę Zgłoszenia lub znacząco wydłużyć czas jego obsługi.
Użytkownicy korzystający z urządzeń wyposażonych w funkcję geolokalizacji, po wyrażeniu na to zgody, mogą korzystać z automatycznej geolokalizacji w Serwisie.
Adres miejsca określony na formularzu Zgłoszenia możne zostać wprowadzony ręcznie przez Użytkownika lub z wykorzystaniem automatycznej geolokalizacji.
Dane kontaktowe zawarte w profilu Użytkownika wprowadzającego Zgłoszenie są przekazywane Administratorowi w celu obsługi Zgłoszenia oraz późniejszego kontaktu tych podmiotów, w tym przekazywania informacji mogących wpłynąć na jakość życia mieszkańców.
Brak danych kontaktowych uniemożliwia otrzymywanie Powiadomień związanych z obsługą Zgłoszenia (m.in. informacji o postępach prac, zapytań o szczegóły), w szczególnych przypadkach może uniemożliwić poprawną obsługę Zgłoszenia.

VI. Odpowiedzialność Użytkowników

Użytkownicy odpowiedzialni są za wszelkie naruszenia obowiązującego prawa oraz postanowień niniejszego Regulaminu podczas korzystania z Serwisu.
Zakazane jest wprowadzanie przez Użytkowników jakiejkolwiek treści o charakterze bezprawnym, w szczególności:
naruszających w jakikolwiek sposób obowiązujące prawo i dobre obyczaje,|wzywających do nienawiści rasowej, etnicznej, wyznaniowej,|zawierających treści pornograficzne,|pochwalających faszyzm, nazizm, komunizm,|propagujących przemoc, obrażających uczucia religijne,|uznawanych za spam.
Zakazane jest wprowadzanie przez Użytkowników danych niezgodnych z rzeczywistością, w szczególności:
nieprawdziwych danych profilowych,|nieprawdziwych Zgłoszeń,|nieprawdziwych informacji w treści Zgłoszeń.
Użytkownik ponosi pełną odpowiedzialność za złamanie obowiązującego prawa bądź szkodę wywołaną jego działaniami w Serwisie, w szczególności za podanie niezgodnych z prawem informacji lub nieprawdziwych danych osobowych, naruszenie dóbr osobistych lub praw autorskich oraz praw pokrewnych, a także ujawnienie tajemnicy służbowej lub innej informacji poufnej.
Administrator zastrzega sobie prawo do odmowy świadczenia usług wobec Użytkownika, w przypadku naruszenia lub uzasadnionego podejrzenia naruszenia przez Użytkownika obowiązującego prawa lub postanowień niniejszego Regulaminu.

VII. Odpowiedzialność Administratora

Administrator jest podmiotem świadczącym usługi drogą elektroniczną w postaci Serwisu - platformy służącej rozwijaniu idei społeczeństwa obywatelskiego. W szczególności Serwis wspiera aktywności obywatelskie dotyczące przestrzeni publicznej umożliwiając Użytkownikom zgłaszanie problemów do Administratora oraz umożliwiając komunikację związaną z ich rozwiązywaniem.
Administrator nie ponosi odpowiedzialności za zakłócenia w prawidłowym funkcjonowaniu Serwisu, a także utraty danych Użytkownika powstałych w wyniku działania siły wyższej, osób trzecich, a także w wyniku działania Administratora zmierzającego do poprawy Serwisu. Administrator zobowiązuje się w miarę możliwości informować z wyprzedzeniem Użytkowników Serwisu o zakłóceniach w jego funkcjonowaniu, w szczególności o przerwach konserwacyjnych.
Administrator nie odpowiada za działania osób trzecich polegające na niezgodnym z powszechnie obowiązującym prawem lub niniejszym Regulaminem wykorzystywaniu Kont Użytkowników.
Zawartość Serwisu jest dostarczana w formie „jest, jaki jest”, w najszerszym zakresie dozwolonym przez obowiązujące prawo, z wyłączeniem wszelkiej gwarancji, wyraźnej lub domniemanej, co do treści lub sposobu funkcjonowania Serwisu. Administrator nie ponosi odpowiedzialności za jakiekolwiek szkody dowolnego rodzaju wynikające z korzystania z Serwisu, a także treści w nim zawartej i nie udziela żadnych gwarancji jakiegokolwiek rodzaju wyrażonych wprost, dorozumianych, ustawowych lub w inny sposób, w tym, bez ograniczeń, gwarancji przydatności do określonego celu, lub braku ukrytych lub innych wad, lub braku błędów. Administrator nie odpowiada ponadto wobec Użytkowników i osób trzecich za:
treści zamieszczane przez Użytkowników w Serwisie,|jakiekolwiek szkody Użytkownika spowodowane nieprawidłowym zapisem lub odczytem treści przez Użytkowników,|sposób w jaki konta Użytkownika będą wykorzystywane przez Użytkowników,|treść zamieszczanych Zgłoszeń i Komentarzy,|szkody powstałe w wyniku wyłączenia lub awarii systemu teleinformatycznego, awarii sieci energetycznej.

VIII. Rozwiązanie umowy o świadczenie usług drogą elektroniczną

Umowa o świadczenie Usług zawierana jest na czas nieoznaczony.
Użytkownik może w każdym czasie rozwiązać umowę o świadczenie Usług, usuwając konto Użytkownika w Serwisie.
Administrator zastrzega sobie uprawnienie do wypowiedzenia umowy o świadczenie Usług w trybie natychmiastowym i usunięcia konta Użytkownika korzystającego z Serwisu w sposób sprzeczny z prawem lub postanowieniami Regulaminu. Administrator uprawniony jest także do zawieszenia świadczenia usług lub zablokowania konta Użytkownika na czas potrzebny do wyjaśnienia okoliczności wskazujących na prawdopodobieństwo działania Użytkownika sprzecznie z prawem lub Regulaminem.
Administrator może odmówić świadczenia Usług i usunąć Konto Użytkownika, jeżeli zostało ono założone ponownie po wypowiedzeniu umowy przez Administrator lub usunięciu Konta Użytkownika z Serwisu na skutek naruszenia przez Użytkownika przepisów prawa lub postanowień Regulaminu.

IX. Wsparcie Użytkowników i reklamacje

W przypadku napotkania problemów, w tym problemów technicznych, w trakcie użytkowania Serwisu, Użytkownik może kierować prośby o wsparcie na adres poczty elektronicznej kontakt@${pageAddress}.pl.
Użytkownik może złożyć reklamację, jeżeli usługi przewidziane w niniejszym Regulaminie nie są realizowane lub są realizowane niezgodnie z jego postanowieniami.
Reklamacja powinna zawierać oznaczenie osoby zgłaszającej umożliwiające jej identyfikację oraz wyczerpujący opis problemu.
Reklamację należy kierować na adres Starostwo Powiatowe w Radomiu ul. Tadeusza Mazowieckiego 7, e-mail: plucedarski@spradom.eu.
Odpowiedź na reklamację zostanie wysłana na adres e-mail Użytkownika podany podczas Rejestracji, przypisany do Konta Użytkownika lub podany w składanej reklamacji w ciągu 14 dni od otrzymania prawidłowo przesłanej reklamacji lub pisemnie na adres wskazany w reklamacji.
Administrator zastrzega sobie prawo do wydłużenia terminu wskazanego w ustępie poprzednim w przypadku, gdy rozpatrzenie reklamacji będzie szczególnie utrudnione, a także gdy napotka inne przeszkody niezależne od Administratora. Administrator ponadto zastrzega, że rozpatrzenie reklamacji może wymagać uzyskania od Użytkownika dodatkowych wyjaśnień. Czas udzielania wyjaśnień przez Użytkownika każdorazowo wydłuża okres rozpoznania reklamacji.
Administrator nie ponosi odpowiedzialności za niewykonanie lub nienależyte wykonanie zobowiązań wynikających z Regulaminu, o ile niewykonanie lub nienależyte wykonanie zobowiązań jest spowodowane okolicznościami, na które nie miał wpływu mimo zachowania należytej staranności. W przypadku wystąpienia siły wyższej wykonanie usług zostanie zawieszone na okres równy okresowi działania siły wyższej.

X. Prawa autorskie

Nazwa Serwisu, wygląd graficzny, oprogramowanie, kod strony oraz baza danych podlegają ochronie prawnej jako utwór prawa autorskiego.

XI. Postanowienia końcowe

Sądem właściwym do rozstrzygania sporów jest sąd powszechny wg właściwości ogólnej.
Niniejszy Regulamin sporządzony został zgodnie z prawem polskim i wyłącznie to prawo winno być stosowane do jego interpretacji, w szczególności w przypadkach nieuregulowanych niniejszym Regulaminem, a także w sytuacji sporu mającego za przedmiot postanowienia niniejszego Regulaminu.
Uznanie jakiegokolwiek postanowienia Regulaminu lub jego części za nieważne lub niewykonalne, nie wpływa na ważność i skuteczność pozostałych postanowień Regulaminu.
Żadne opóźnienie lub odstąpienie od dochodzenia praw wynikających z Regulaminu nie może być traktowane jako ich zrzeczenie. Zrzeczenie się określonych uprawnień (roszczeń), w związku z jedną konkretną sytuacją, nie oznacza ogólnego zrzeczenia się wszystkich przysługujących jej środków prawnych. Częściowe wykorzystanie przysługujących Stronie uprawnień nie wyklucza późniejszego skorzystania ze wszystkich praw, jej przysługujących.
Administrator zastrzega sobie prawo jednostronnego wprowadzenia zmian w niniejszym Regulaminie.
Wszelkie zmiany obowiązują od dnia wskazanego w zmienionym Regulaminie, który to dzień nie może jednak przypadać wcześniej niż na dzień opublikowania zmienionego Regulaminu.
W terminie 14 dni od dnia opublikowania zmienionego Regulaminu, Użytkownik może złożyć oświadczenie o braku akceptacji zmienionego Regulaminu. Oświadczenie traktowane będzie jako wypowiedzenie przez Użytkownika stosunku prawnego będącego podstawą świadczenia usług określonych w niniejszym Regulaminie. Oświadczenie należy kierować na adres należy kierować na adres Starostwo Powiatowe w Radomiu ul. Tadeusza Mazowieckiego 7, e-mail: plucedarski@spradom.eu.
Niniejszy Regulamin obowiązuje od dnia 1. lipca 2022r.

XII. Załącznik nr 1 – Polityka prywatności

Zasadą Serwisu jest przestrzeganie wszelkich obowiązujących przepisów prawa dotyczących prywatności i ochrony danych osobowych. W celu zapewnienia bezpieczeństwa i prywatności Użytkowników podczas korzystania z Serwisu sformułowana zostaje niniejsza Polityka Prywatności stanowiąca integralną część Regulaminu.
Administratorem danych osobowych jest Administrator – Starostwo Powiatowe w Radomiu ul. Tadeusza Mazowieckiego 7, 26-600 Radom.
Dane osobowe są przetwarzane przez Administratora na zasadach określonych w niniejszym Regulaminie, w celu rozwijania idei społeczeństwa obywatelskiego i wspierania aktywności obywatelskich dotyczących przestrzeni publicznej, w tym przekazywania informacji mogących wpłynąć na jakość życia mieszkańców. Dane osobowe są przetwarzane również przez Administratora w celu obsługi Zgłoszeń oraz późniejszego ich kontaktu z osobami zgłaszającymi, w tym przekazywania informacji mogących wpłynąć na jakość życia mieszkańców. Dane osobowe są przetwarzane również przez Administratora w celu poprawiania jakości Serwisu.
Dane kontaktowe do Inspektora Ochrony Danych: Starostwo Powiatowe w Radomiu, ul. T. Mazowieckiego 7, 26-600 Radom, pok. 124, tel. (48) 36-55-801 wew. 181; (48) 340-40-64 wew. 181, e-mail: bkubik@spradom.eu.
Użytkownik ma prawo wglądu do swoich danych osobowych oraz prawo do ich poprawiania. W przypadku, gdy podane przez Użytkownika dane osobowe stały się nieaktualne, Użytkownik zobowiązany jest do niezwłocznego ich zaktualizowania.
Administrator nie przekazuje, nie sprzedaje i nie użycza zgromadzonych danych osobowych Użytkowników innym osobom lub podmiotom, bez zgody Użytkownika. Przetwarzane przez Administratora dane osobowe Użytkowników mogą zostać udostępnione uprawnionym organom państwa na ich żądanie, na potrzeby prowadzonych przez nie postępowań.
Podanie danych osobowych ma charakter dobrowolny, jednak brak zgody na przetwarzanie danych osobowych uniemożliwi korzystanie z niektórych funkcji Serwisu. 
Przysługuje Pani/Panu prawo wniesienia skargi do organu nadzorczego - Prezes Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa. 
W trakcie korzystania z Serwisu, Administrator gromadzi następujące dane:
treść zapytań HTTP,|datę i czas otrzymania zapytań HTTP,|dane wprowadzane przez Użytkowników za pomocą formularzy dostępnych w Serwisie,
Administrator zastrzega sobie prawo jednostronnego wprowadzenia zmian w niniejszej Polityce Prywatności.`;

export const defaultTermsOfUse = `I. Postanowienia ogólne

Serwis ${
  isLocalSpot ? "LocalSpot" : "Komunikator Miejski"
} jest platformą służącą rozwijaniu idei społeczeństwa obywatelskiego. Prowadzi do aktywizacji postaw proobywatelskich, promowania aktywności obywatelskich dotyczących przestrzeni publicznej i budowania lokalnych społeczności.
Niniejszy Regulamin określa warunki i zasady korzystania z Serwisu prowadzonego przez Administratora. Regulamin określa prawa i obowiązki Użytkowników oraz Administratora.
Warunkiem korzystania z Serwisu jest zapoznanie się z Regulaminem i jego akceptacja.

II. Definicje

Regulamin oznacza niniejszy regulamin.
Serwis oznacza serwis internetowy prowadzony pod nazwami domenowymi www.${pageAddress}.pl i ${pageAddress}.pl, w ramach którego Administrator świadczy usługi drogą elektroniczną.
Administrator oznacza spółkę XENTIVO Sp. z o.o. z siedzibą w Warszawie przy ulicy Bakalarskiej 34. KRS 0000348199, NIP 5222940717, REGON 142216840.
Użytkownik oznacza osobę fizyczną, osobę prawną lub jednostkę organizacyjną nieposiadającą osobowości prawnej, ale posiadającą zdolność prawną, korzystającą z Serwisu.
Użytkownik Zarejestrowany oznacza Użytkownika posiadającego aktywne konto w Serwisie.
Użytkownik Niezarejestrowany oznacza Użytkownika nie posiadającego aktywnego konta w Serwisie.
Użytkownik Zalogowany oznacza Użytkownika Zarejestrowanego, który pozostaje uwierzytelniony w Serwisie.
Użytkownik Gość oznacza Użytkownika, który pozostaje nieuwierzytelniony w Serwisie.
Instytucja partnerska oznacza podmiot publiczny lub niepubliczny, któremu Serwis przekazał Zgłoszenie, właściwy do obsługi Zgłoszenia. Aktualna lista Instytucji partnerskich znajduje się w Załączniku nr 2.
Zgłoszenie oznacza informacje dotyczące problemu w przestrzeni publicznej wprowadzone do Serwisu przez Użytkownika i przekazane do właściwej Instytucji partnerskiej w celach interwencyjnych lub oznacza Zapytanie.
Zapytanie oznacza pytanie wprowadzone do Serwisu przez Użytkownika w celu uzyskania informacji od właściwej Instytucji partnerskiej.
Komentarz oznacza uwagę lub dodatkowe objaśnienie umieszczone pod Zgłoszeniem, wprowadzone przez Użytkownika lub Instytucję partnerską w celach informacyjnych.
Powiadomienie oznacza wiadomość wysłaną z Serwisu do Użytkownika poprzez email lub inny kanał komunikacyjny.
Podpis oznacza nazwę (inaczej pseudonim, nick) jaką Użytkownik określił w swoim profilu i pod jaką publikuje Zgłoszenia i Komentarze w Serwisie.

III. Wymagania techniczne

W celu prawidłowego korzystania z Serwisu Użytkownik powinien zapewnić:
Połączenie z siecią Internet o przepustowości min. 1 Mbps,|Urządzenie umożliwiające korzystanie z zasobów sieci Internet,|Zaktualizowaną przeglądarkę stron internetowych Firefox, Chrome, Opera, Internet Explorer, Safari, lub zaktualizowaną aplikację mobilną Komunikator Miejski na urządzeniu z systemem operacyjnym iOS lub Android.

IV. Rejestracja Użytkownika i zarządzanie profilem

Aby uzyskać status Użytkownika Zarejestrowanego należy założyć konto w Serwisie. Proces zakładania konta wymaga poprawnego wypełnienia formularza rejestracyjnego, a następnie dokonania aktywacji konta poprzez kliknięcie w link aktywacyjny w otrzymanym Powiadomieniu.
W formularzu rejestracyjnym Użytkownik podaje: adres e-mail, Podpis i hasło.
Podany przez Użytkownika podczas rejestracji adres e-mail stanowi login Użytkownika do Serwisu i wraz z hasłem wykorzystywane są do uwierzytelniania Użytkownika w trakcie logowania do Serwisu.
Podanie dodatkowych danych takich jak: imię, nazwisko, numer telefonu w celu dalszego kontaktu z Użytkownikiem, nie jest obligatoryjne i może zostać dokonane w dowolnym momencie poprzez formularz profilowy.
Podany przez Użytkownika Podpis jest publikowany w Serwisie pod Zgłoszeniami i Komentarzami wprowadzonymi przez Użytkownika.
Podane przez Użytkownika adres e-mail, imię, nazwisko i numer telefonu nie są publikowane w Serwisie.
W dowolnym momencie, poprzez formularz profilowy, Użytkownik może zmienić swoje dane, za wyjątkiem adresu e-mail i Podpisu.
Zabronione jest podawanie Podpisu jeśli ma on charakter obraźliwy, wulgarny, sprzeczny z dobrymi obyczajami lub zasadami współżycia społecznego lub będącej nazwą własną podlegającą ochronie prawnej lub w inny sposób niezgodnej z Regulaminem.
Po poprawnym wypełnieniu formularza rejestracyjnego, Użytkownik otrzyma, na podany w formularzu adres e-mail, Powiadomienie zawierające link aktywujący konto Użytkownika. Aktywacja konta wymaga kliknięcie w ten link.
Użytkownik wysyłając formularz rejestracyjny potwierdza, że zapoznał się z Regulaminem i zobowiązuje się go przestrzegać.
Użytkownik zobowiązany jest utrzymywać dane profilowe kompletne i zgodne ze stanem faktycznym oraz dbać by nie naruszały praw osób trzecich.
Dostęp do konta możliwy jest po uwierzytelnieniu Użytkownika. Pomyślne uwierzytelnienie wymaga wprowadzenia aktualnego adresu e-mail (loginu) i hasła.
Podanie nieprawdziwych danych lub informacji albo posłużenie się cudzymi danymi w celu skorzystania z Serwisu może podlegać odpowiedzialności karnej oraz odpowiedzialności odszkodowawczej.
Podane przez Użytkowników w Serwisie dane osobowe są zbierane i przetwarzane zgodnie z obowiązującymi przepisami prawa oraz zgodnie z polityką prywatności zawartą w Załączniku nr 1 – „Polityka Prywatności”.
Użytkownik zobowiązuje się nie udostępniać swojego hasła osobom trzecim pod rygorem poniesienia odpowiedzialności za szkody z tego powodu wynikłe na zasadach ogólnych wynikających z przepisów prawa. W przypadku wejścia osoby trzeciej w posiadanie hasła, a także w przypadku uzasadnionego podejrzenia takiej sytuacji, Użytkownik powinien niezwłocznie zmienić hasło oraz zawiadomić o tym Administratora.

V. Zasady działania i korzystania z Serwisu

Administrator zapewnia platformę wspierającą aktywności obywatelskie dotyczące przestrzeni publicznej i budowanie lokalnych społeczności.
Użytkownik może korzystać z poniższych usług świadczonych przez Serwis:
Wprowadzania nowych Zgłoszeń,|Popierania wprowadzonych Zgłoszeń,|Wprowadzania Komentarzy do Zgłoszeń,|Zamykania własnych Zgłoszeń, gdy właściwa Instytucja partnerska nie posiada takiej możliwości,|Otrzymywania Powiadomień związanych z obsługą Zgłoszeń (m.in. informacje o postępach, zapytania o szczegóły),|Otrzymywania Powiadomień związanych z obsługą konta w Serwisie (m.in. resetowanie hasła, aktywacja konta),|Otrzymywania Powiadomień mogących wpłynąć na jakość życia mieszkańców, w tym o aktualnych wydarzeniach i zdarzeniach w przestrzeni publicznej.
Korzystanie z Serwisu jest nieodpłatne.
Korzystanie z Serwisu możliwe jest przez Użytkowników Zalogowanych i Użytkowników Gość.
Niektóre funkcje Serwisu są dostępne tylko dla Użytkowników Zalogowanych.
Niektóre funkcje Serwisu są dostępne dla Użytkowników Gość pod warunkiem podania adresu email.
Użytkownik może wprowadzić nowe Zgłoszenie w Serwisie w celu poinformowania właściwych Instytucji partnerskich o zaobserwowanym problemie w przestrzeni publicznej.
Użytkownik może wprowadzić Komentarz pod Zgłoszeniem w celu przekazania dodatkowego objaśnienia lub uwagi. Komentarze umożliwiają komunikację pomiędzy Użytkownikami oraz między Użytkownikami i Instytucjami partnerskimi.
Użytkownik może poprzeć Zgłoszenie wprowadzone wcześniej w Serwisie przez innego Użytkownika, jeśli jest zainteresowany przyspieszeniem działań interwencyjnych.
Użytkownik Gość wprowadzając formularz rejestracyjny potwierdza, że zapoznał się z Regulaminem i zobowiązuje się go przestrzegać.
Treść Zgłoszeń i Komentarzy jest publikowana w Serwisie.
Treść Zgłoszeń jest przekazywana do Instytucji partnerskiej w celu ich obsługi.
Niepełna (m.in. niepełny, niezrozumiały opis lub jego brak) lub nieprecyzyjna (m.in. nieprecyzyjne bądź niezgodne ze stanem faktycznym określenie lokalizacji) treść Zgłoszenia może uniemożliwić poprawną obsługę Zgłoszenia lub znacząco wydłużyć czas jego obsługi.
Użytkownicy korzystający z urządzeń wyposażonych w funkcję geolokalizacji, po wyrażeniu na to zgody, mogą korzystać z automatycznej geolokalizacji w Serwisie.
Adres miejsca określony na formularzu Zgłoszenia możne zostać wprowadzony ręcznie przez Użytkownika lub z wykorzystaniem automatycznej geolokalizacji.
Dane kontaktowe zawarte w profilu Użytkownika wprowadzającego Zgłoszenie są przekazywane Instytucjom partnerskim w celu obsługi Zgłoszenia oraz późniejszego kontaktu tych podmiotów, w tym przekazywania informacji mogących wpłynąć na jakość życia mieszkańców.
Brak danych kontaktowych uniemożliwia otrzymywanie Powiadomień związanych z obsługą Zgłoszenia (m.in. informacji o postępach prac, zapytań o szczegóły), w szczególnych przypadkach może uniemożliwić poprawną obsługę Zgłoszenia.

VI. Odpowiedzialność Użytkowników

Użytkownicy odpowiedzialni są za wszelkie naruszenia obowiązującego prawa oraz postanowień niniejszego Regulaminu podczas korzystania z Serwisu.
Zakazane jest wprowadzanie przez Użytkowników jakiejkolwiek treści o charakterze bezprawnym, w szczególności:
naruszających w jakikolwiek sposób obowiązujące prawo i dobre obyczaje,|wzywających do nienawiści rasowej, etnicznej, wyznaniowej,|zawierających treści pornograficzne,|pochwalających faszyzm, nazizm, komunizm,|propagujących przemoc, obrażających uczucia religijne,|uznawanych za spam.
Zakazane jest wprowadzanie przez Użytkowników danych niezgodnych z rzeczywistością, w szczególności:
nieprawdziwych danych profilowych,|nieprawdziwych Zgłoszeń,|nieprawdziwych informacji w treści Zgłoszeń.
Użytkownik ponosi pełną odpowiedzialność za złamanie obowiązującego prawa bądź szkodę wywołaną jego działaniami w Serwisie, w szczególności za podanie niezgodnych z prawem informacji lub nieprawdziwych danych osobowych, naruszenie dóbr osobistych lub praw autorskich oraz praw pokrewnych, a także ujawnienie tajemnicy służbowej lub innej informacji poufnej.
Administrator zastrzega sobie prawo do odmowy świadczenia usług wobec Użytkownika, w przypadku naruszenia lub uzasadnionego podejrzenia naruszenia przez Użytkownika obowiązującego prawa lub postanowień niniejszego Regulaminu.

VII. Odpowiedzialność Administratora

Administrator jest podmiotem świadczącym usługi drogą elektroniczną w postaci Serwisu - platformy służącej rozwijaniu idei społeczeństwa obywatelskiego. W szczególności Serwis wspiera aktywności obywatelskie dotyczące przestrzeni publicznej umożliwiając Użytkownikom zgłaszanie problemów do właściwych Instytucji partnerskich oraz umożliwiając komunikację związaną z ich rozwiązywaniem.
Administrator nie ponosi odpowiedzialności za zakłócenia w prawidłowym funkcjonowaniu Serwisu, a także utraty danych Użytkownika powstałych w wyniku działania siły wyższej, osób trzecich, a także w wyniku działania Administratora zmierzającego do poprawy Serwisu. Administrator zobowiązuje się w miarę możliwości informować z wyprzedzeniem Użytkowników Serwisu o zakłóceniach w jego funkcjonowaniu, w szczególności o przerwach konserwacyjnych.
Administrator nie odpowiada za działania osób trzecich polegające na niezgodnym z powszechnie obowiązującym prawem lub niniejszym Regulaminem wykorzystywaniu Kont Użytkowników.
Zawartość Serwisu jest dostarczana w formie „jest, jaki jest”, w najszerszym zakresie dozwolonym przez obowiązujące prawo, z wyłączeniem wszelkiej gwarancji, wyraźnej lub domniemanej, co do treści lub sposobu funkcjonowania Serwisu. Administrator nie ponosi odpowiedzialności za jakiekolwiek szkody dowolnego rodzaju wynikające z korzystania z Serwisu, a także treści w nim zawartej i nie udziela żadnych gwarancji jakiegokolwiek rodzaju wyrażonych wprost, dorozumianych, ustawowych lub w inny sposób, w tym, bez ograniczeń, gwarancji przydatności do określonego celu, lub braku ukrytych lub innych wad, lub braku błędów. Administrator nie odpowiada ponadto wobec Użytkowników i osób trzecich za:
treści zamieszczane przez Użytkowników w Serwisie,|jakiekolwiek szkody Użytkownika spowodowane nieprawidłowym zapisem lub odczytem treści przez Użytkowników,|sposób w jaki konta Użytkownika będą wykorzystywane przez Użytkowników,|treść zamieszczanych Zgłoszeń i Komentarzy,|szkody powstałe w wyniku wyłączenia lub awarii systemu teleinformatycznego, awarii sieci energetycznej.

VIII. Rozwiązanie umowy o świadczenie usług drogą elektroniczną

Umowa o świadczenie Usług zawierana jest na czas nieoznaczony.
Użytkownik może w każdym czasie rozwiązać umowę o świadczenie Usług, usuwając konto Użytkownika w Serwisie.
Administrator zastrzega sobie uprawnienie do wypowiedzenia umowy o świadczenie Usług w trybie natychmiastowym i usunięcia konta Użytkownika korzystającego z Serwisu w sposób sprzeczny z prawem lub postanowieniami Regulaminu. Administrator uprawniony jest także do zawieszenia świadczenia usług lub zablokowania konta Użytkownika na czas potrzebny do wyjaśnienia okoliczności wskazujących na prawdopodobieństwo działania Użytkownika sprzecznie z prawem lub Regulaminem.
Administrator może odmówić świadczenia Usług i usunąć Konto Użytkownika, jeżeli zostało ono założone ponownie po wypowiedzeniu umowy przez Administrator lub usunięciu Konta Użytkownika z Serwisu na skutek naruszenia przez Użytkownika przepisów prawa lub postanowień Regulaminu.

IX. Wsparcie Użytkowników i reklamacje

W przypadku napotkania problemów, w tym problemów technicznych, w trakcie użytkowania Serwisu, Użytkownik może kierować prośby o wsparcie na adres poczty elektronicznej wsparcie@${pageAddress}.pl.
Użytkownik może złożyć reklamację, jeżeli usługi przewidziane w niniejszym Regulaminie nie są realizowane lub są realizowane niezgodnie z jego postanowieniami.
Reklamacja powinna zawierać oznaczenie osoby zgłaszającej umożliwiające jej identyfikację oraz wyczerpujący opis problemu.
Reklamację należy kierować na adres 02-212 Warszawa, ul. Bakalarska 34, XENTIVO Sp. z o.o. lub adres poczty elektronicznej reklamacje@${pageAddress}.pl.
Odpowiedź na reklamację zostanie wysłana na adres e-mail Użytkownika podany podczas Rejestracji, przypisany do Konta Użytkownika lub podany w składanej reklamacji w ciągu 14 dni od otrzymania prawidłowo przesłanej reklamacji lub pisemnie na adres wskazany w reklamacji.
Administrator zastrzega sobie prawo do wydłużenia terminu wskazanego w ustępie poprzednim w przypadku, gdy rozpatrzenie reklamacji będzie szczególnie utrudnione, a także gdy napotka inne przeszkody niezależne od Administratora. Administrator ponadto zastrzega, że rozpatrzenie reklamacji może wymagać uzyskania od Użytkownika dodatkowych wyjaśnień. Czas udzielania wyjaśnień przez Użytkownika każdorazowo wydłuża okres rozpoznania reklamacji.
Administrator nie ponosi odpowiedzialności za niewykonanie lub nienależyte wykonanie zobowiązań wynikających z Regulaminu, o ile niewykonanie lub nienależyte wykonanie zobowiązań jest spowodowane okolicznościami, na które nie miał wpływu mimo zachowania należytej staranności. W przypadku wystąpienia siły wyższej wykonanie usług zostanie zawieszone na okres równy okresowi działania siły wyższej.

X. Prawa autorskie

Nazwa Serwisu, wygląd graficzny, oprogramowanie, kod strony oraz baza danych podlegają ochronie prawnej jako utwór prawa autorskiego.

XI. Postanowienia końcowe

Sądem właściwym do rozstrzygania sporów jest sąd powszechny wg właściwości ogólnej.
Niniejszy Regulamin sporządzony został zgodnie z prawem polskim i wyłącznie to prawo winno być stosowane do jego interpretacji, w szczególności w przypadkach nieuregulowanych niniejszym Regulaminem, a także w sytuacji sporu mającego za przedmiot postanowienia niniejszego Regulaminu.
Uznanie jakiegokolwiek postanowienia Regulaminu lub jego części za nieważne lub niewykonalne, nie wpływa na ważność i skuteczność pozostałych postanowień Regulaminu.
Żadne opóźnienie lub odstąpienie od dochodzenia praw wynikających z Regulaminu nie może być traktowane jako ich zrzeczenie. Zrzeczenie się określonych uprawnień (roszczeń), w związku z jedną konkretną sytuacją, nie oznacza ogólnego zrzeczenia się wszystkich przysługujących jej środków prawnych. Częściowe wykorzystanie przysługujących Stronie uprawnień nie wyklucza późniejszego skorzystania ze wszystkich praw, jej przysługujących.
Administrator zastrzega sobie prawo jednostronnego wprowadzenia zmian w niniejszym Regulaminie.
Wszelkie zmiany obowiązują od dnia wskazanego w zmienionym Regulaminie, który to dzień nie może jednak przypadać wcześniej niż na dzień opublikowania zmienionego Regulaminu.
W terminie 14 dni od dnia opublikowania zmienionego Regulaminu, Użytkownik może złożyć oświadczenie o braku akceptacji zmienionego Regulaminu. Oświadczenie traktowane będzie jako wypowiedzenie przez Użytkownika stosunku prawnego będącego podstawą świadczenia usług określonych w niniejszym Regulaminie. Oświadczenie należy kierować na adres 02-212 Warszawa, ul. Bakalarska 34, XENTIVO Sp. z o.o. lub adres poczty elektronicznej reklamacje@${pageAddress}.pl.
Niniejszy Regulamin obowiązuje od dnia 30.09.2015 r.

XII. Załącznik nr 1 – Polityka prywatności

Zasadą Serwisu jest przestrzeganie wszelkich obowiązujących przepisów prawa dotyczących prywatności i ochrony danych osobowych. W celu zapewnienia bezpieczeństwa i prywatności Użytkowników podczas korzystania z Serwisu sformułowana zostaje niniejsza Polityka Prywatności stanowiąca integralną część Regulaminu.
Administratorem danych osobowych jest Administrator – XENTIVO Sp. z o.o. z siedzibą w Warszawie przy ulicy Bakalarskiej 34, KRS 0000348199, NIP 5222940717, REGON 142216840.
Dane osobowe są przetwarzane przez Administratora na zasadach określonych w niniejszym Regulaminie, w celu rozwijania idei społeczeństwa obywatelskiego i wspierania aktywności obywatelskich dotyczących przestrzeni publicznej, w tym przekazywania informacji mogących wpłynąć na jakość życia mieszkańców. Dane osobowe są przetwarzane również przez Administratora w celu obsługi Zgłoszeń oraz późniejszego ich kontaktu z osobami zgłaszającymi, w tym przekazywania informacji mogących wpłynąć na jakość życia mieszkańców. Dane osobowe są przetwarzane również przez Administratora w celu poprawiania jakości Serwisu.
Administrator udostępnia dane osobowe Instytucjom partnerskim, które przetwarzają dane osobowe w celu obsługi Zgłoszeń oraz późniejszego kontaktu z osobami zgłaszającymi, w tym przekazywania informacji mogących wpłynąć na jakość życia mieszkańców.
Użytkownik ma prawo wglądu do swoich danych osobowych oraz prawo do ich poprawiania. W przypadku, gdy podane przez Użytkownika dane osobowe stały się nieaktualne, Użytkownik zobowiązany jest do niezwłocznego ich zaktualizowania.
Administrator nie przekazuje, za wyjątkiem Instytucji partnerskich, nie sprzedaje i nie użycza zgromadzonych danych osobowych Użytkowników innym osobom lub podmiotom, bez zgody Użytkownika. Przetwarzane przez Administratora dane osobowe Użytkowników mogą zostać udostępnione uprawnionym organom państwa na ich żądanie, na potrzeby prowadzonych przez nie postępowań.
Podanie danych osobowych ma charakter dobrowolny, jednak brak zgody na przetwarzanie danych osobowych uniemożliwi korzystanie z niektórych funkcji Serwisu.
W trakcie korzystania z Serwisu, Administrator gromadzi następujące dane:
treść zapytań HTTP,|datę i czas otrzymania zapytań HTTP,|dane wprowadzane przez Użytkowników za pomocą formularzy dostępnych w Serwisie,
Administrator zastrzega sobie prawo jednostronnego wprowadzenia zmian w niniejszej Polityce Prywatności.

XIII. Załącznik nr 2 – Lista Instytucji partnerskich

Jednostki samorządu terytorialnego właściwe do obsługi Zgłoszeń.`;

export const termsOfService = `Szanujemy prawo do poufności i dbamy o bezpieczeństwo danych naszych użytkowników. Informacje zawarte w tej polityce wyjaśniają, jakie dane gromadzimy w aplikacji mobilnej ${isLocalSpot ? "LocalSpot" : "Komunikator Miejski"}, a także czemu służy ich przetwarzanie.
Pamiętaj, że korzystanie z zawartości aplikacji mobilnej ${isLocalSpot ? "LocalSpot" : "Komunikator Miejski"} warunkują Regulamin,  oraz polityka prywatności, którą właśnie czytasz. Zapoznanie z zawartymi w nich treściami potwierdzasz:
w formularzu rejestracyjnym nowego zgłoszenia|w formularzu rejestracyjnym w trakcie tworzenia konta użytkownika 
Kiedy korzystasz z zawartości portalu jako niezalogowany użytkownik (anonimowo) nie udzielasz nam zgody na przetwarzanie Twoich danych osobowych. Możesz udzielić nam zgody jedynie w zakresie wykorzystania danych przesłanych w zgłoszeniu

I. Informacje o stosowaniu plików cookies

W portalu ${isLocalSpot ? "LocalSpot" : "Komunikator Miejski"} stosuje się tzw. ciasteczka (ang. „cookies”), czyli niewielkie pliki w postaci informacji tekstowych, które zapisują się na urządzeniach końcowych użytkowników (np. komputerach lub smartfonach) za pośrednictwem serwera ww. portalu, zgodnie z art. 173 ust. 1 i 2 ustawy Prawo telekomunikacyjne.
Zgody na używanie plików cookies i udostępnienie tym plikom dostępu do Twojego urządzenia końcowego udzielasz podczas pierwszej wizyty na portalu ${isLocalSpot ? "LocalSpot" : "Komunikator Miejski"}, kiedy wyświetla Ci się okno z informacją o stosowaniu plików cookies z odesłaniem do dedykowanej polityce podstrony. Zaakceptowanie i zamknięcie wspomnianej informacji jest równoznaczne z wyrażeniem przez Ciebie zgody na wykorzystywanie plików cookies przez portal ${isLocalSpot ? "LocalSpot" : "Komunikator Miejski"} oraz usługi cyfrowe przez niego udostępniane, zgodnie z postanowieniami tej polityki prywatności.
Pliki cookies pobierają zwykle takie informacje, jak: nazwę domeny portalu internetowego, z którego pochodzą, indywidualny numer, adres IP oraz czas ich przechowywania na urządzeniu końcowym użytkownika. Kiedy użytkownik ponownie odwiedza portal ${isLocalSpot ? "LocalSpot" : "Komunikator Miejski"}, jego przeglądarka internetowa przesyła zapisane na urządzeniu końcowym pliki cookies z powrotem do tego portalu. Dzięki temu serwer, na którym znajduje się portal ${isLocalSpot ? "LocalSpot" : "Komunikator Miejski"}, rozpoznaje preferencje użytkownika.
Portal ${isLocalSpot ? "LocalSpot" : "Komunikator Miejski"} wykorzystuje pliki cookies absolutnie niezbędne do prawidłowego funkcjonowania portalu, dzięki czemu zapewnia bezpieczeństwo i dostęp do jego podstawowych funkcji. Pliki cookies absolutnie niezbędne nie przechowują danych osobowych.
W każdej chwili możesz wycofać zgodę, o której mowa wyżej, poprzez dokonanie zmian w ustawieniach swojej przeglądarki internetowej. Masz możliwość m.in. ograniczenia zakresu zbieranych plików cookies, zablokowania ich zapisywania lub ich usunięcia. Aby tego dokonać, przejrzyj instrukcje, które pomogą Ci zarządzać plikami cookies: Microsoft Edge, Mozilla Firefox, Google Chrome, Opera, Safari.
Usunięcie plików cookies lub ich zablokowanie może prowadzić do utrudnień w korzystaniu z niektórych funkcjonalności portalu ${isLocalSpot ? "LocalSpot" : "Komunikator Miejski"}, w tym udostępnianych za jego pośrednictwem usług cyfrowych.
Korzystanie z portalu ${isLocalSpot ? "LocalSpot" : "Komunikator Miejski"} bez dokonywania zmian w ustawieniach przeglądarki internetowej jest równoznaczne z wyrażeniem zgody na używanie plików cookies i udostępnienie tym plikom dostępu do Twojego urządzenia końcowego.

II. Informacje na temat logów serwera

Korzystanie z portalu ${isLocalSpot ? "LocalSpot" : "Komunikator Miejski"} wiąże się z przesyłaniem zapytań do serwera, na którym jest on przechowywany. Każde takie zapytanie jest zapisywane w logach serwera, zawierających m.in.: adres IP Twojego urządzenia końcowego, informacje o systemie operacyjnym oraz przeglądarce internetowej, z jakich korzystasz, a także datę i czas serwera, na którym zostały zapisane i w którym są przechowywane.
Podobnie, jak pliki cookies, logi serwera nie zawierają danych osobowych i nie są wykorzystywane w celu identyfikowania użytkowników portalu ${isLocalSpot ? "LocalSpot" : "Komunikator Miejski"}.
Logi serwera stanowią jedynie materiał pomocniczy, który służy do administrowania portalem ${isLocalSpot ? "LocalSpot" : "Komunikator Miejski"}. Zawartość logów serwera nie jest ujawniania osobom, które nie posiadają upoważnienia do administrowania serwerem.`

export const termsOfUse = {
  powiatRadomski,
};
