import { createSagaAction } from "../../util/createSagaAction";

export const userConstants = {
  LOGIN: createSagaAction("LOGIN"),
  LOGOUT: "LOGOUT",
  GET_USER_DETAILS: "GET_USER_DETAILS",
  CLEAR_MESSAGES: "CLEAR_MESSAGES",
  SET_SELECTED_OFFICE: "SET_SELECTED_OFFICE",
  GET_CITY_DATA: createSagaAction("GET_CITY_DATA"),
};

export const userAction = {
  login: ({ email, password }) => ({
    type: userConstants.LOGIN.ACTION,
    email,
    password,
  }),
  logout: () => ({
    type: userConstants.LOGOUT,
  }),
  getUserDetails: () => ({
    type: userConstants.GET_USER_DETAILS,
  }),
  clearMessages: () => ({
    type: userConstants.CLEAR_MESSAGES,
  }),
  setSelectedOffice: ({ selectedOffice }) => ({
    type: userConstants.SET_SELECTED_OFFICE,
    selectedOffice,
  }),
  getCityData: () => ({
    type: userConstants.GET_CITY_DATA.ACTION,
  }),
};

const initialState = {
  isLoggedIn: false,
  isLoadingDetails: false,
  isLoggingIn: false,
  name: "",
  avatar: null,
  apiKey: "",

  loginErrorMessage: null,

  selectedOffice: -1,

  isLoadingTownData: false,
  townData: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.LOGIN.ACTION: {
      return {
        ...state,
        isLoggingIn: true,
      };
    }
    case userConstants.LOGIN.SUCCESS: {
      const user = action.results && action.results[0] ? action.results[0] : null;
      window.localStorage.setItem("apiKey", action.token);
      return {
        ...state,
        isLoggedIn: true,
        isLoggingIn: false,
        isLoadingDetails: false,
        name: user ? user.nick : "Zalogowano",
        avatar: user ? user.avatar : null,
        apiKey: action.token,
        loginErrorMessage: null,
        selectedOffice: Number.parseInt(localStorage.getItem("localspotSelectedOffice") || "-1"),
      };
    }
    case userConstants.LOGIN.FAILED: {
      window.localStorage.removeItem("apiKey");
      return {
        ...state,
        isLoggedIn: false,
        isLoggingIn: false,
        isLoadingDetails: false,
        name: "",
        avatar: null,
        apiKey: "",
        loginErrorMessage: action.message ? action.message : "Wystąpił błąd",
      };
    }

    case userConstants.LOGOUT: {
      window.localStorage.removeItem("apiKey");
      return {
        ...initialState,
        selectedOffice: undefined,
      };
    }

    case userConstants.GET_USER_DETAILS: {
      return {
        ...state,
        isLoadingDetails: true,
      };
    }

    case userConstants.CLEAR_MESSAGES: {
      return {
        ...state,
        loginErrorMessage: null,
      };
    }

    case userConstants.SET_SELECTED_OFFICE: {
      localStorage.setItem("localspotSelectedOffice", action.selectedOffice);
      return {
        ...state,
        selectedOffice: action.selectedOffice,
      };
    }

    case userConstants.GET_CITY_DATA.ACTION: {
      return {
        ...state,
        isLoadingTownData: true,
      };
    }
    case userConstants.GET_CITY_DATA.SUCCESS: {
      return {
        ...state,
        townData: action.results,
        isLoadingTownData: false,
      };
    }
    case userConstants.GET_CITY_DATA.FAILED: {
      return {
        ...state,
        townData: [],
        isLoadingTownData: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
