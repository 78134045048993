import L from "leaflet";

const createMarker = ({ marker, pos, icon, small, type, history, tooltip, openInNewTab }) => {
  const handleClick = () => {
    if (type === "gov" && marker.www) {
      window.open(marker.www, "_blank");
    } else if (type === "incident" || type === "announcement") {
      const link = `/map/${type === "incident" ? "details" : "ogloszenie"}/${marker.id}`;
      if (openInNewTab) {
        window.open(link, "_blank");
      } else {
        history.push(link);
      }
    }
  };

  const point = new L.Marker(pos, {
    icon: L.icon({
      iconUrl: icon,
      iconSize: small ? [37, 43.5] : [49, 58],
      iconAnchor: small ? [14, 40] : [24.5, 49],
      tooltipAnchor: small ? [14, 40] : [20, -29],
    }),
  });
  point.on("click", handleClick);

  if (tooltip) {
    point.bindTooltip(new L.Tooltip(tooltip));
  }

  return point;
};

export default createMarker;
