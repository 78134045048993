import React from "react";
import Box from "@material-ui/core/Box";
import RightBanner from "../components/RightBanner";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "700px",
    display: "flex",
    width: "100%",
    flexGrow: "1",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("md")]: {
      minHeight: "unset",
    },
  },
  leftMenu: {
    width: "45%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "900px",
    padding: props => (props.padding ? props.padding : "110px 0px"),
    [theme.breakpoints.down("md")]: {
      width: "100%",
      minHeight: "unset",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "50px 30px !important",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "30px 15px !important",
    },
  },
}));

const Root = ({ children, padding }) => {
  const classes = useStyles({ padding });

  return (
    <Box className={classes.root}>
      <Box className={classes.leftMenu}>{children}</Box>
      <Hidden mdDown>
        <RightBanner />
      </Hidden>
    </Box>
  );
};

export default Root;
