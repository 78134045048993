import React, { useRef } from "react";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    width: "100%",
    marginBottom: props => props.marginBottom && `${props.marginBottom}px`,
  },
  button: {
    width: "240px",
    fontSize: "22px",
    letterSpacing: "2.2px",
    height: "50px",
    borderRadius: "25px",
    position: "absolute",
    bottom: "25px",
    left: "50%",
    transform: "translateX(-50%)",
  },
  buttonIcon: {
    fontSize: "36px !important",
  },
}));

const withScrollButton = (WrappedComponent, marginBottom) => {
  const HocComponent = () => {
    const classes = useStyles({ marginBottom });
    const scrollRef = useRef();

    const handleScroll = () => {
      if (!scrollRef.current) {
        return;
      }

      const offset = scrollRef.current.getBoundingClientRect().top;
      window.scrollBy({ top: offset - 78, behavior: "smooth" });
    };

    return (
      <React.Fragment>
        <div className={classes.root}>
          <WrappedComponent />
          <Hidden smDown>
            <Button
              variant="contained"
              color="primary"
              endIcon={<ExpandMoreIcon className={classes.buttonIcon} />}
              onClick={() => handleScroll()}
              className={classes.button}
            >
              Dalej
            </Button>
          </Hidden>
          <div ref={scrollRef} />
        </div>
      </React.Fragment>
    );
  };

  return HocComponent;
};

export default withScrollButton;
