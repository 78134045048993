import React, { useEffect } from "react";
import withLayout from "../components/withLayout/withLayout";
import LandingPage from "./LandingPage/LandingPage";
import TermsOfUsePage from "../screens/TermsOfUsePage/TermsOfUsePage";
import ForOfficesPage from "../screens/ForOfficesPage/ForOfficesPage";
import LoginWrapper from "./Login/Login/LoginWrapper";
import RegisterWrapper from "./Login/Register/RegisterWrapper";
import TokenRedirect from "./Login/PasswordReset/TokenRedirect";
import PasswordResetWrapper from "./Login/PasswordReset/PasswordResetWrapper";
import ActivateAccountRedirect from "./Login/ActivateAccount/ActivateAccountRedirect";
import ActivateAccountWrapper from "./Login/ActivateAccount/ActivateAccountWrapper";
import MapPage from "./MapPage/MapPage";
import TownPage from "./TownPage/TownPage";
import UserProfilePage from "./UserProfilePage/UserProfilePage";
import { getDisabledLinks } from "../util/getFilteredLinks";
import { useDispatch } from "react-redux";
import { userAction } from "../redux/modules/userReducer";
import { Switch, Route, Redirect } from "react-router-dom";
import DeleteAccountRedirect from "./Login/DeleteAccount/DeleteAccountRedirect";
import DeleteAccountWrapper from "./Login/DeleteAccount/DeleteAccountWrapper";

const disabledLinks = getDisabledLinks();

const links = [
  {
    link: '/terms',
    component: TermsOfUsePage,
  },
  {
    link: "/regulamin",
    component: TermsOfUsePage,
  },
  {
    link: "/dla-urzedow",
    component: ForOfficesPage,
  },
  {
    link: "/login",
    component: LoginWrapper,
  },
  {
    link: "/rejestracja",
    component: RegisterWrapper,
  },
  {
    link: "/recovery/:token",
    component: TokenRedirect,
  },
  {
    link: "/remove/:token",
    component: DeleteAccountRedirect,
  },
  {
    link: "/usun-konto",
    component: DeleteAccountWrapper,
  },
  {
    link: "/nowe-haslo",
    component: PasswordResetWrapper,
  },
  {
    link: "/activate/:token",
    component: ActivateAccountRedirect,
  },
  {
    link: "/aktywuj",
    component: ActivateAccountWrapper,
  },
  {
    link: "/map",
    component: MapPage,
  },
  {
    link: "/city/:city",
    component: TownPage,
  },
  {
    link: "/konto",
    component: UserProfilePage,
  },
  {
    link: "/",
    component: LandingPage,
    exact: true,
  },
].filter(({ link }) => !disabledLinks.includes(link));

const App = () => {
  const isLocalSpot = process.env.REACT_APP_LOCALSPOT_TYPE === "localspot";
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.localStorage.getItem("apiKey")) {
      dispatch(userAction.getUserDetails());
    }

    if (isLocalSpot) {
      document.title = "LocalSpot";
    } else {
      document.title = "Komunikator Miejski";
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Switch>
      {links.map(({ link, component }) => {
        const PageComponent = component;

        return (
          <Route exact={!!link.exact} path={link} key={link}>
            <PageComponent />
          </Route>
        );
      })}
      <Route path="*">
        <Redirect to={process.env.REACT_APP_HOME_REDIRECT || "/"} />
      </Route>
    </Switch>
  );
};

export default withLayout(App);
