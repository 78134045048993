const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = url;
  });

export async function cropImage(imageSrc, pixelCrop, quality = 0.8) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = image.width;
  canvas.height = image.height;

  ctx.drawImage(image, 0, 0);
  const data = ctx.getImageData(0, 0, image.width, image.height);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(data, 0 - pixelCrop.x, 0 - pixelCrop.y);

  return new Promise((resolve) => {
    canvas.toBlob(
      (file) => {
        resolve(file);
      },
      "image/jpeg",
      quality
    );
  });
}
