import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Hidden from "@material-ui/core/Hidden";
import BluePinIcon from "../../../../static/BluePinIcon";
import { makeStyles } from "@material-ui/core/styles";
import contactBackground from "../../../../static/contact-bg.png";
import partnership from "../../../../static/partnership.png";

const useStyles = makeStyles(theme => ({
  icon: {
    height: "45px",
    width: "33px",
    color: theme.palette.primary.main,
    marginRight: "16px",
    [theme.breakpoints.down("xs")]: {
      width: "24px",
      height: "32px",
      marginRight: "30px",
    },
  },
  contactRoot: {
    display: "flex",
    backgroundImage: `url(${contactBackground})`,
    backgroundSize: "cover",
    backgroundPositionX: "right",
    backgroundPositionY: "bottom",
    minHeight: "calc(100vh - 78px)",
    width: "calc(100% - 20px)",
    padding: "0px min(150px, 8%) 0px min(200px, 11%)",
    marginRight: "20px",
    marginBottom: "100px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "50px",
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "calc(100vh - 60px)",
      padding: "36px 16px 36px 36px",
    },
  },
  textRoot: {
    flexGrow: "1",
    display: "flex",
    marginTop: "150px",
    [theme.breakpoints.down("md")]: {
      marginTop: "0px",
      flexGrow: "unset",
    },
  },
  contactIcon: {
    [theme.breakpoints.down("xs")]: {
      marginRight: "11px",
    },
  },
  contactHeader: {
    maxWidth: "700px",
    marginBottom: "41.5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
      letterSpacing: "2.5px",
      lineHeight: "38px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      letterSpacing: "2px",
      lineHeight: "30px",
    },
  },
  imageRoot: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  image: {
    marginTop: "80px",
    width: "516px",
    height: "516px",
    borderRadius: "50%",
    boxShadow: "0px 6px 6px #00000045",
  },
  text: {
    fontSize: "30px",
    fontWeight: "500",
    lineHeight: "46px",
    maxWidth: "795px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      letterSpacing: "2px",
      lineHeight: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
      lineHeight: "25px",
    },
  },
  link: {
    display: "block",
    fontWeight: "600",
    wordBreak: "break-word",
  },
}));

const Contact = () => {
  const classes = useStyles();
  const isLocalSpot = process.env.REACT_APP_LOCALSPOT_TYPE === "localspot";

  return (
    <Box className={classes.contactRoot}>
      <Box className={classes.textRoot}>
        <BluePinIcon className={`${classes.icon} ${classes.contactIcon}`} />
        <Box>
          <Typography component="h2" variant="h2" className={classes.contactHeader}>
            Chcesz dowiedzieć się więcej? Skontaktuj się z nami!
          </Typography>
          <Typography className={classes.text}>
            Z przyjemnością zaprezentuję całe rozwiązanie i przedstawię więcej szczegółów.
          </Typography>
          <Typography className={classes.text}>Bożena Bulik</Typography>
          <Link className={`${classes.text} ${classes.link}`} href="tel:795 524 038">
            +48 795 524 038
          </Link>
          <Link
            className={`${classes.text} ${classes.link}`}
            href={`mailto:bozena.bulik@${isLocalSpot ? "localspot" : "komunikatormiejski"}.pl`}
          >
            bozena.bulik@{isLocalSpot ? "localspot" : "komunikatormiejski"}.pl
          </Link>
        </Box>
      </Box>
      <Hidden smDown>
        <Box className={classes.imageRoot}>
          <img src={partnership} alt="" className={classes.image} />
        </Box>
      </Hidden>
    </Box>
  );
};

export default Contact;
