import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const HeartIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 21.192 17.843">
      <path
        d="M568.973,977.062a5.023,5.023,0,0,0-7.07-.092l-2.071,2.017-2.07-2.125a5,5,0,0,0-7.163,6.979l2.071,2.125,6.9,7.238.078-.076,7.186-6.955-.023-.024,2.07-2.017A5.023,5.023,0,0,0,568.973,977.062Z"
        transform="translate(-549.19 -975.361)"
      />
    </SvgIcon>
  );
};

export default HeartIcon;
