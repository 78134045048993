import { createSagaAction } from "../../util/createSagaAction";

export const mapConstants = {
  CHANGE_BOUNDS: "CHANGE_BOUNDS",
  GET_ANNOUNCEMENTS: createSagaAction("GET_ANNOUNCEMENTS"),
  GET_INCIDENTS: createSagaAction("GET_INCIDENTS"),
  CHANGE_ACTIVE_STATUSES: "CHANGE_ACTIVE_STATUSES",
  CHANGE_ACTIVE_TYPES: "CHANGE_ACTIVE_TYPES",
  SEARCH_INCIDENTS: "SEARCH_INCIDENTS",
  SEARCH_ANNOUNCEMENTS: "SEARCH_ANNOUNCEMENTS",
  TOGGLE_FAVOURITE: "TOGGLE_FAVOURITE",
  TOGGLE_ARCHIVE: "TOGGLE_ARCHIVE",
  SET_ACTIVE_MARKER: "SET_ACTIVE_MARKER",
  SEARCH_SINGLE_INCIDENT: createSagaAction("SEARCH_SINGLE_INCIDENT"),
  SEARCH_SINGLE_ANNOUNCEMENT: createSagaAction("SEARCH_SINGLE_ANNOUNCEMENT"),
  CLEAR_ACTIVE_MARKER: "CLEAR_ACTIVE_MARKER",
  SET_NEW_MARKER: "SET_NEW_MARKER",
  CLEAR_NEW_MARKER: "CLEAR_NEW_MARKER",
  SEARCH_CATEGORIES: createSagaAction("SEARCH_CATEGORIES"),
};

export const mapAction = {
  changeBounds: ({ northEast, southWest, center }) => ({
    type: mapConstants.CHANGE_BOUNDS,
    northEast,
    southWest,
    center,
  }),
  getAnnouncements: ({ northEast, southWest, activeTypes, showFavourite }) => ({
    type: mapConstants.GET_ANNOUNCEMENTS.ACTION,
    northEast,
    southWest,
    activeTypes,
    showFavourite
  }),
  getIncidents: ({
    northEast,
    southWest,
    activeStatuses,
    showFavourite,
    showArchive,
    showGov,
  }) => ({
    type: mapConstants.GET_INCIDENTS.ACTION,
    northEast,
    southWest,
    activeStatuses,
    showFavourite,
    showArchive,
    showGov,
  }),
  changeActiveStatuses: ({ toggle }) => ({
    type: mapConstants.CHANGE_ACTIVE_STATUSES,
    toggle,
  }),
  changeActiveTypes: ({ toggle }) => ({
    type: mapConstants.CHANGE_ACTIVE_TYPES,
    toggle,
  }),
  searchIncidents: ({ search }) => ({
    type: mapConstants.SEARCH_INCIDENTS,
    search,
  }),
  searchAnnouncements: ({ search }) => ({
    type: mapConstants.SEARCH_ANNOUNCEMENTS,
    search,
  }),
  toggleFavourite: () => ({
    type: mapConstants.TOGGLE_FAVOURITE,
  }),
  toggleArchive: () => ({
    type: mapConstants.TOGGLE_ARCHIVE,
  }),
  setActiveMarkerId: ({ id, marker, type }) => ({
    type: mapConstants.SET_ACTIVE_MARKER,
    marker,
    id,
    markerType: type,
  }),
  searchSingleIncident: ({ id }) => ({
    type: mapConstants.SEARCH_SINGLE_INCIDENT.ACTION,
    id,
  }),
  searchSingleAnnouncement: ({ id }) => ({
    type: mapConstants.SEARCH_SINGLE_ANNOUNCEMENT.ACTION,
    id,
  }),
  clearActiveMarker: () => ({
    type: mapConstants.CLEAR_ACTIVE_MARKER,
  }),
  setNewMarker: ({ pos }) => ({
    type: mapConstants.SET_NEW_MARKER,
    pos,
  }),
  clearNewMarker: () => ({
    type: mapConstants.CLEAR_NEW_MARKER,
  }),
  searchCategories: () => ({
    type: mapConstants.SEARCH_CATEGORIES.ACTION,
  }),
};

const initialState = {
  northEast: null,
  southWest: null,
  center: null,
  mapZoom: null,

  announcements: [], //attractions, bus stops, warnings
  govs: [],
  incidents: [],
  orgAnnouncements: [],
  filteredAnnouncements: [],
  orgIncidents: [],

  isLoadingAnnouncements: false,
  isLoadingIncidents: false,

  activeStatuses: [1, 2, 3, 4],
  activeTypes: ["GOV", "OSI", "BUS", "ATR"],
  showGov: true,
  incidentsSearch: "",
  announcementsSearch: "",
  showFavourite: false,
  showArchive: false,

  activeMarker: null,
  activeMarkerId: "",
  activeMarkerError: false,
  activeMarkerType: "",

  newMarkerPos: null,
  newMarkerAddress: "",
  categories: [],
};

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case mapConstants.CHANGE_BOUNDS: {
      return {
        ...state,
        mapZoom: null,
        northEast: action.northEast,
        southWest: action.southWest,
        center: action.center,
      };
    }

    case mapConstants.GET_ANNOUNCEMENTS.ACTION: {
      return {
        ...state,
        isLoadingAnnouncements: true,
      };
    }
    case mapConstants.GET_ANNOUNCEMENTS.SUCCESS: {
      const search = state.announcementsSearch;
      const announcements = [...action.announcements].filter(
        a =>
          search === "" ||
          a.description.toLowerCase().includes(search) ||
          a.categoryName.toLowerCase().includes(search)
      );
      return {
        ...state,
        isLoadingAnnouncements: false,
        announcements: announcements,
        orgAnnouncements: action.announcements,
      };
    }
    case mapConstants.GET_ANNOUNCEMENTS.FAILED: {
      return {
        ...state,
        isLoadingAnnouncements: false,
        announcements: [],
        orgAnnouncements: [],
      };
    }

    case mapConstants.GET_INCIDENTS.ACTION: {
      return {
        ...state,
        isLoadingIncidents: true,
      };
    }
    case mapConstants.GET_INCIDENTS.SUCCESS: {
      const search = state.incidentsSearch;
      const incidents = [...action.incidents].filter(
        incident =>
          search === "" ||
          incident.description.toLowerCase().includes(search) ||
          incident.categoryName.toLowerCase().includes(search) ||
          incident.id.includes(search)
      );
      return {
        ...state,
        isLoadingIncidents: false,
        orgIncidents: action.incidents,
        incidents: incidents,
        govs: action.govs,
      };
    }
    case mapConstants.GET_INCIDENTS.FAILED: {
      return {
        ...state,
        isLoadingIncidents: false,
        orgIncidents: [],
        incidents: [],
        govs: [],
      };
    }

    case mapConstants.CHANGE_ACTIVE_STATUSES: {
      let statuses = [...state.activeStatuses];
      if (statuses.includes(action.toggle)) {
        statuses = statuses.filter(v => v !== action.toggle);
      } else {
        statuses.push(action.toggle);
      }
      return {
        ...state,
        activeStatuses: statuses,
      };
    }

    case mapConstants.CHANGE_ACTIVE_TYPES: {
      let types = [...state.activeTypes];
      if (types.includes(action.toggle)) {
        types = types.filter(v => v !== action.toggle);
      } else {
        types.push(action.toggle);
      }
      return {
        ...state,
        activeTypes: types,
        showGov: action.toggle === "GOV" ? !state.showGov : state.showGov,
      };
    }

    case mapConstants.SEARCH_INCIDENTS: {
      const search = action.search ? action.search.toLowerCase() : "";
      const incidents = [...state.orgIncidents].filter(
        incident =>
          search === "" ||
          incident.description.toLowerCase().includes(search) ||
          incident.categoryName.toLowerCase().includes(search) ||
          incident.id.includes(search)
      );
      return {
        ...state,
        incidentsSearch: search,
        incidents,
      };
    }

    case mapConstants.SEARCH_ANNOUNCEMENTS: {
      const search = action.search ? action.search.toLowerCase() : "";
      const announcements = [...state.orgAnnouncements].filter(
        a =>
          search === "" ||
          a.description.toLowerCase().includes(search) ||
          a.title.toLowerCase().includes(search)
      );
      return {
        ...state,
        announcementsSearch: search,
        announcements,
      };
    }

    case mapConstants.TOGGLE_FAVOURITE: {
      return {
        ...state,
        showFavourite: !state.showFavourite,
      };
    }
    case mapConstants.TOGGLE_ARCHIVE: {
      return {
        ...state,
        showArchive: !state.showArchive,
      };
    }

    case mapConstants.SET_ACTIVE_MARKER: {
      return {
        ...state,
        mapZoom: null,
        activeMarkerError: false,
        activeMarkerId: action.id,
        activeMarker: action.marker,
        activeMarkerType: action.markerType === "details" ? "incident" : "announcement",
      };
    }

    case mapConstants.SEARCH_SINGLE_INCIDENT.ACTION: {
      return {
        ...state,
        activeMarkerError: false,
        activeMarkerId: action.id,
        activeMarkerType: "incident",
      };
    }
    case mapConstants.SEARCH_SINGLE_INCIDENT.SUCCESS: {
      return {
        ...state,
        activeMarker: action.marker,
        mapZoom: 14,
      };
    }
    case mapConstants.SEARCH_SINGLE_INCIDENT.FAILED: {
      return {
        ...state,
        activeMarkerId: "",
        activeMarker: null,
        activeMarkerError: true,
        mapZoom: null,
      };
    }

    case mapConstants.SEARCH_SINGLE_ANNOUNCEMENT.ACTION: {
      return {
        ...state,
        activeMarkerError: false,
        activeMarkerId: action.id,
        activeMarkerType: "announcement",
      };
    }
    case mapConstants.SEARCH_SINGLE_ANNOUNCEMENT.SUCCESS: {
      return {
        ...state,
        activeMarker: action.marker,
        mapZoom: 14,
      };
    }
    case mapConstants.SEARCH_SINGLE_ANNOUNCEMENT.FAILED: {
      return {
        ...state,
        activeMarker: null,
        activeMarkerError: true,
        mapZoom: null,
      };
    }

    case mapConstants.CLEAR_ACTIVE_MARKER: {
      return {
        ...state,
        activeMarkerId: "",
        activeMarker: null,
        activeMarkerError: false,
        activeMarkerType: "",
      };
    }

    case mapConstants.SET_NEW_MARKER: {
      return {
        ...state,
        newMarkerPos: action.pos,
        activeMarker: null,
        activeMarkerId: "",
        activeMarkerType: "",
      };
    }

    case mapConstants.CLEAR_NEW_MARKER: {
      return {
        ...state,
        newMarkerPos: null,
        newMarkerAddress: null,
      };
    }

    case mapConstants.SEARCH_CATEGORIES.SUCCESS: {
      const enabledCategories = (process.env.REACT_APP_ENABLE_CATEGORIES || "")
        .split(",")
        .filter(s => !!s);

      return {
        ...state,
        categories: enabledCategories.length
          ? action.categories.filter(category => enabledCategories.includes(category.code))
          : action.categories,
      };
    }
    case mapConstants.SEARCH_CATEGORIES.FAILED: {
      return {
        ...state,
        categories: [],
      };
    }

    default: {
      return state;
    }
  }
};

export default mapReducer;
