import React, { useMemo, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Zoom from "@material-ui/core/Zoom";
import Collapse from "@material-ui/core/Collapse";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import LoginBox from "./LoginBox";
import Hidden from "../../Hidden/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useLocation } from "react-router-dom";
import { getDisabledLinks } from "../../../util/getFilteredLinks";
import { images } from "./images";
import { Typography } from "@material-ui/core";

const disabledLinks = getDisabledLinks();

const links = [
  {
    name: "Strona główna",
    link: "/",
  },
  {
    name: "Dla urzędów",
    link: "/dla-urzedow",
  },
  {
    name: "Regulamin",
    link: "/regulamin",
  },
  {
    name: "Mapa",
    link: "/map",
  },
].filter(({ link }) => !disabledLinks.includes(link));

const useStyles = makeStyles(theme => ({
  appbar: {
    boxShadow: "0 3px 6px #0000004E",
    [theme.breakpoints.down("sm")]: {
      height: "unset",
    },
  },
  toolbar: {
    height: "78px",
    minHeight: "unset",
    padding: "0 44px 0px calc(10% - 12px)",
    [theme.breakpoints.down("md")]: {
      paddingRight: "min(30px, 3%)",
      paddingLeft: "min(30px, 3%)",
    },
    [theme.breakpoints.down(1280)]: {
      padding: "0 30px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "60px",
    },
  },
  image: {
    height: "50px",
    display: "flex",
    alignItems: "center",
    flexShrink: "1",
    marginRight: "15px",
    [theme.breakpoints.down(600)]: {
      marginRight: "unset",
    },
  },
  logo: {
    height: "50px",
    objectFit: "contain",
    width: "100%",
    [theme.breakpoints.down(600)]: {
      width: "200px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "160px",
    },
  },
  secondLogo: {
    objectFit: "contain",
    height: "40px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "15px",
      width: "fit-content",
    },
  },
  spacer: {
    [theme.breakpoints.down(1280)]: {
      flexGrow: "1",
    },
  },
  links: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "30px",
    flexGrow: "1",
    height: "100%",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "unset",
    },
    [theme.breakpoints.down(1280)]: {
      flexDirection: "column",
      alignItems: "flex-start",
      "@media (max-height: 599.95px)": {
        flexDirection: "row",
        flexWrap: "wrap",
      },
    },
    [theme.breakpoints.down("xs")]: {
      flexGrow: "1",
      justifyContent: "center",
    },
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    fontSize: "18px",
    textTransform: "uppercase",
    textAlign: "center",
    marginRight: "10px",
    [theme.breakpoints.down(1280)]: {
      textAlign: "left",
      fontSize: "20px",
      marginRight: "0px",
      marginBottom: "20px",
      "@media (max-height: 599.95px)": {
        flexBasis: "50%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "45px",
    },
  },
  activeLink: {
    color: theme.palette.primary.main,
    fontWeight: "500",
  },
  iconButton: {
    position: "relative",
    width: "24px",
    height: "24px",
  },
  icon: {
    position: "absolute",
  },
  collapseRoot: {
    padding: "30px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "10px", // 15px (regular image margin) - (60px (toolbar height) - 50px (logo height)) / 2
      height: "calc(100vh - 60px)",
      display: "flex",
      flexDirection: "column",
    },
  },
}));

const isUrlExternal = url => {
  if (!url) return false;

  if (!url.includes(window.location.host)) return true;

  return false;
};

const getLogoAriaLabel = url => {
  let aria = process.env.REACT_APP_LOGO_ARIA_LABEL || "Strona główna";
  return getAriaLabel(url, aria);
};

const getAriaLabel = (url, ariaLabel) => {
  ariaLabel += isUrlExternal(url) ? ". Link otworzy się w nowej karcie." : "";
  return ariaLabel;
};

const Header = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const location = useLocation();
  const isLocalSpot = process.env.REACT_APP_LOCALSPOT_TYPE === "localspot";
  const extraLogos = (process.env.REACT_APP_EXTRA_LOGOS || "").split(",");
  const extraLinks = (process.env.REACT_APP_EXTRA_LINKS || "").split(",");
  const extraAriaLabels = (process.env.REACT_APP_EXTRA_ARIA_LABELS || "").split(",");

  const renderExtraLogos = useMemo(() => {
    return extraLogos.map((key, index) =>
      !!extraLinks[index] ? (
        <Link
          to={{ pathname: extraLinks[index] }}
          target={isUrlExternal(extraLinks[index]) ? "_blank" : "_self"}
          className={classes.image}
          onClick={handleNavBarHide}
          aria-label={getAriaLabel(extraLinks[index], extraAriaLabels[index])}
          key={key}
        >
          <img
            className={classes.secondLogo}
            src={images[key]}
            alt={extraAriaLabels[index] || ""}
          />
        </Link>
      ) : (
        <div className={classes.image} key={key}>
          <img
            className={classes.secondLogo}
            src={images[key]}
            alt={extraAriaLabels[index] || ""}
          />
        </div>
      )
    );
  }, [extraLinks, extraAriaLabels, extraLogos, classes]);

  const renderLinks = useMemo(
    () => (
      <Box className={classes.links}>
        {links.map(({ link, name }) => (
          <Link
            className={`${classes.link} ${link === location.pathname ? classes.activeLink : ""}`}
            to={link}
            key={link}
            onClick={handleNavBarHide}
          >
            {name}
          </Link>
        ))}
      </Box>
    ),
    [location, classes]
  );

  const handleNavBarHide = () => {
    setOpen(false);
  };

  const handleMenuButtonPress = () => {
    setOpen(open => !open);
  };

  const handleVisiblityChange = e => {
    setOpen(false);
  };

  return (
    <AppBar className={classes.appbar} position="sticky" color="inherit" elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Link
          to={{ pathname: process.env.REACT_APP_LOGO_LINK || "/" }}
          target={isUrlExternal(process.env.REACT_APP_LOGO_LINK) ? "_blank" : "_self"}
          className={classes.image}
          onClick={handleNavBarHide}
          aria-label={getLogoAriaLabel(process.env.REACT_APP_LOGO_LINK)}
        >
          <img
            className={classes.logo}
            src={images[process.env.REACT_APP_LOGO_KEY]}
            alt="Logo serwisu"
          />
        </Link>
        <Typography variant="srOnly" component="h1">
          {isLocalSpot ? "LocalSpot" : "Komunikator Miejski"}
        </Typography>
        <Hidden hiddenDown="xs">{renderExtraLogos}</Hidden>
        <Box className={classes.spacer} />
        <Hidden hiddenDown={1280} onChange={handleVisiblityChange}>
          {renderLinks}
          <LoginBox />
        </Hidden>
        <Hidden hiddenUp={1280}>
          <IconButton
            className={classes.iconButton}
            color="primary"
            onClick={handleMenuButtonPress}
          >
            <Zoom in={open}>
              <CloseIcon className={classes.icon} />
            </Zoom>
            <Zoom in={!open}>
              <MenuIcon className={classes.icon} />
            </Zoom>
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden hiddenUp={1280}>
        <Collapse in={open}>
          <Box className={classes.collapseRoot}>
            <Hidden hiddenUp="sm">{renderExtraLogos}</Hidden>
            {renderLinks}
            <LoginBox />
          </Box>
        </Collapse>
      </Hidden>
    </AppBar>
  );
};

export default Header;
