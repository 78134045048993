import React from "react";
import TopBanner from "../../../../components/TopBanner/TopBanner";
import banner2 from "../../../../static/topBanner/banner2.jpg";

const ForOfficesBanner = () => {
  const isLocalSpot = process.env.REACT_APP_LOCALSPOT_TYPE === "localspot";
  return (
    <TopBanner
      src={banner2}
      topText={isLocalSpot ? "LocalSpot" : "Komunikator Miejski"}
      middleText="Nowoczesna e-usługa przeznaczona dla jednostek samorządu terytorialnego."
    />
  );
};

export default ForOfficesBanner;
