import Link from "@material-ui/core/Link";

export const urlRegex = /(https?:\/\/\S+)/gi;
export const wwwRegex = /www\.\S+/gi;
export const httpRegex = /https?:\/\//gi;
export const emailRegex = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/gi;

export const createLinks = (text = "", maxLength) => {
  const replaced = text
    .split(" ")
    .map(token => {
      if (wwwRegex.test(token) && !httpRegex.test(token)) {
        return "http://" + token;
      }

      return token;
    })
    .join(" ");
  const splitText = replaced.split(urlRegex);
  const returnText = [];
  let length = 0;
  for (let i = 0; (!maxLength || length < maxLength) && i < splitText.length; i++) {
    length += splitText[i].length;
    let tmpText = splitText[i];

    if (maxLength && length > maxLength) {
      tmpText = splitText[i].substring(0, splitText[i].length - (length - maxLength)) + "...";
    }

    returnText[i] =
      i % 2 ? (
        <Link
          href={splitText[i]}
          target="_blank"
          color="primary"
          underline="hover"
          onClick={e => e.stopPropagation()}
          key={i}
        >
          {tmpText}
        </Link>
      ) : (
        tmpText
      );
  }

  return returnText;
};
