import { Box, Button, Collapse, makeStyles } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React, { useState } from "react";
import TownHallMap from "../../components/Map/TownHallMap";

const useStyles = makeStyles(theme => ({
  collapseRoot: {
    position: "relative",
  },
  collapseButton: {
    position: "absolute",
    width: "200px",
    top: "10px",
    right: "10px",
    zIndex: "1001",
  },
  icon: {
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.standard,
    }),
  },
  openIcon: {
    transform: "rotate(180deg)",
  },
  mapContainer: {
    width: "100%",
    height: "50%",
    minHeight: "400px",
  },
  afterMapSpacer: {
    position: "relative",
    zIndex: "1000",
    height: "58px",
    transition: theme.transitions.create("height", {
      duration: theme.transitions.duration.standard,
    }),
  },
  afterMapSpacerOpen: {
    height: "0px",
  },
  mapShadow: {
    position: "absolute",
    width: "100%",
    height: "10px",
    transition: theme.transitions.create("box-shadow", {
      duration: theme.transitions.duration.standard,
    }),
  },
  mapShadowOpen: {
    boxShadow: "0px -6px 6px #0000004D",
  },
}));

const MobileMap = ({ announcements, geoJson }) => {
  const classes = useStyles();
  const [collapseOpen, setCollpaseOpen] = useState(false);

  const handlePressExpand = () => {
    setCollpaseOpen(open => !open);
  };

  return (
    <React.Fragment>
      <Box className={classes.collapseRoot}>
        <Button
          variant="contained"
          color="primary"
          className={classes.collapseButton}
          endIcon={
            <ExpandMore className={clsx(classes.icon, { [classes.openIcon]: collapseOpen })} />
          }
          onClick={handlePressExpand}
        >
          {collapseOpen ? "Ukryj" : "Pokaż"} mapę
        </Button>
        <Collapse in={collapseOpen}>
          <TownHallMap
            className={classes.mapContainer}
            geoJson={geoJson}
            announcements={announcements}
          />
        </Collapse>
      </Box>
      <Box
        className={clsx(classes.afterMapSpacer, {
          [classes.afterMapSpacerOpen]: collapseOpen,
        })}
      >
        <Box
          className={clsx(classes.mapShadow, {
            [classes.mapShadowOpen]: collapseOpen,
          })}
        />
      </Box>
    </React.Fragment>
  );
};

export default MobileMap;
