import attraction from "./gov/attraction.png";
import bus from "./gov/bus.png";
import gov from "./gov/gov.png";
import warn from "./gov/warn.png";

export const govMarkers = {
  ATR: attraction,
  BUS: bus,
  GOV: gov,
  OSI: warn,
};
