import React, { useEffect, useState, useRef } from "react";
import SuccessPage from "../components/SuccessPage";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import requestPasswordReset from "../../../services/user/requestPasswordReset";

const RemindPasswordSuccess = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const isLocalSpot = process.env.REACT_APP_LOCALSPOT_TYPE === "localspot";
  const email = useLocation().state?.email;
  const history = useHistory();
  const timeout = useRef(null);

  useEffect(() => {
    if (sent) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => setSent(false), 60 * 1000);
    }
    return () => clearTimeout(timeout.current);
  }, [sent]);

  const handleResend = () => {
    setIsLoading(true);
    requestPasswordReset(email)
      .then(() => setSent(true))
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (!email) {
    return <Redirect to="/login" />;
  }

  return (
    <SuccessPage
      header="E-mail został wysłany"
      texts={[
        <React.Fragment>
          Na adres <span style={{ fontWeight: "bold" }}>{email}</span> wysłaliśmy maila. Jeśli ten
          e-mail jest połączony z kontem w serwisie{" "}
          {isLocalSpot ? "LocalSpot" : "Komunikator Miejski"}, będziesz mógł zresetować swoje hasło.
        </React.Fragment>,
        "Nie otrzymałeś maila? Upewnij się że wprowadziłeś poprawny adres e-mail, oraz sprawdź skrzynkę spam.",
      ]}
      buttons={["Wyślij ponownie", "Powrót do logowania"]}
      buttonActions={[() => handleResend(), () => history.push("/login")]}
      disabledButtons={[isLoading || sent]}
    />
  );
};

export default RemindPasswordSuccess;
