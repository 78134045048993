import React from "react";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import BluePinIcon from "../../../../static/BluePinIcon";
import { makeStyles } from "@material-ui/core/styles";
import withScrollButton from "../../../../components/withScrollButton/withScrollButton";
import background from "../../../../static/download-bg.png";
import mockup from "../../../../static/MockupIphone.png";
import appStore from "../../../../static/app-store.png";
import googlePlay from "../../../../static/google-play.png";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "calc(100vh - 78px)",
    width: "100%",
    padding: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "37px",
      flexDirection: "column",
      height: "fit-content",
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "calc(100vh - 60px)",
    },
  },
  imageRoot: {
    margin: "0px 100px",
    height: "611px",
    width: "420px",
    [theme.breakpoints.down("md")]: {
      width: "308px",
      height: "448px",
      margin: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      objectFit: "contain",
      flexShrink: "1",
      minHeight: "200px",
    },
  },
  textRoot: {
    display: "flex",
    marginTop: "30px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
  },
  icon: {
    height: "45px",
    width: "33px",
    color: theme.palette.primary.main,
    marginRight: "13px",
    [theme.breakpoints.down("md")]: {
      width: "24px",
      marginRight: "26px",
    },
  },
  header: {
    fontSize: "35px",
    letterSpacing: "3.5px",
    lineHeight: "53px",
    fontWeight: "bolder",
    marginBottom: "60px",
    [theme.breakpoints.down("md")]: {
      fontSize: "25px",
      letterSpacing: "2.5px",
      lineHeight: "38px",
      marginBottom: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      letterSpacing: "2px",
      lineHeight: "30px",
      marginBottom: "15px",
    },
  },
  subText: {
    fontSize: "30px",
    letterSpacing: "3px",
    lineHeight: "46px",
    maxWidth: "510px",
    "&:last-of-type": {
      marginBottom: "60px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      letterSpacing: "2px",
      lineHeight: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      "&:last-of-type": {
        marginBottom: "30px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
      lineHeight: "25px",
      "&:last-of-type": {
        marginBottom: "15px",
      },
    },
  },
  span: {
    color: theme.palette.primary.main,
  },
  appStoreLinks: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    maxWidth: "500px",
    [theme.breakpoints.down(375)]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  appStore: {
    height: "42px",
    "&:not(:last-child)": {
      marginRight: "60px",
      [theme.breakpoints.down("sm")]: {
        marginRight: "0px",
      },
      [theme.breakpoints.down(375)]: {
        marginBottom: "10px",
      },
    },
  },
}));

const DownloadNow = () => {
  const classes = useStyles();
  const isLocalSpot = process.env.REACT_APP_LOCALSPOT_TYPE === "localspot";

  return (
    <Box className={classes.root}>
      <Hidden smDown>
        <img src={mockup} className={classes.imageRoot} alt="" />
        <Box className={classes.textRoot}>
          <BluePinIcon className={classes.icon} />
          <Box maxWidth="675px">
            <Typography className={classes.header}>
              To wszystko i więcej znajdziesz w naszej aplikacji mobilnej
            </Typography>
            <Typography className={classes.subText}>Połącz się ze swoim miastem.</Typography>
            <Typography className={classes.subText}>
              Pobierz{" "}
              <span className={classes.span}>
                {isLocalSpot ? "LocalSpot" : "Komunikator Miejski"} już dziś!
              </span>
            </Typography>
            <Box>
              <a
                href="https://apps.apple.com/pl/app/localspot/id1035268671?l=pl"
                target="_blank"
                rel="noreferrer"
                aria-label="Pobierz aplikację Komunikator Miejski z App Store"
                className={classes.appStore}
              >
                <img src={appStore} className={classes.appStore} alt="Pobierz z AppStore" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=pl.xentivo.komunikator"
                target="_blank"
                rel="noreferrer"
                aria-label="Pobierz aplikację Komunikator Miejski z Google Play"
                className={classes.appStore}
              >
                <img src={googlePlay} className={classes.appStore} alt="Pobierz z Google Play" />
              </a>
            </Box>
          </Box>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box className={classes.textRoot}>
          <BluePinIcon className={classes.icon} />
          <Box>
            <Typography className={classes.header}>
              To wszystko i więcej znajdziesz w naszej aplikacji mobilnej
            </Typography>
            <Typography className={classes.subText}>Połącz się ze swoim miastem.</Typography>
            <Typography className={classes.subText}>
              Pobierz{" "}
              <span className={classes.span}>
                {isLocalSpot ? "LocalSpot" : "Komunikator Miejski"} już dziś!
              </span>
            </Typography>
          </Box>
        </Box>
        <img src={mockup} className={classes.imageRoot} alt="" />

        <Box className={classes.appStoreLinks}>
          <a
            href="https://apps.apple.com/pl/app/localspot/id1035268671?l=pl"
            target="_blank"
            rel="noreferrer"
            aria-label="Pobierz aplikację Komunikator Miejski z App Store"
            className={classes.appStore}
          >
            <img src={appStore} alt="" className={classes.appStore} />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=pl.xentivo.komunikator"
            target="_blank"
            rel="noreferrer"
            aria-label="Pobierz aplikację Komunikator Miejski z Google Play"
            className={classes.appStore}
          >
            <img src={googlePlay} className={classes.appStore} alt="" />
          </a>
        </Box>
      </Hidden>
    </Box>
  );
};

export default withScrollButton(DownloadNow, 80);
