import React from "react";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListIcon from "@material-ui/core/ListItemIcon";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import BluePinIcon from "../../../../static/BluePinIcon";
import documents from "../../../../static/signing-documents.png";
import background from "../../../../static/coop-bg.png";
import { items } from "./items";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "right",
    minHeight: "calc(100vh - 78px)",
    padding: "0px 100px",
    marginBottom: "100px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "0px 50px 0px 150px",
    },
    [theme.breakpoints.down(1280)]: {
      padding: "0px 50px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "37px",
      backgroundPositionY: "bottom",
    },
  },
  leftRoot: {
    display: "flex",
    flexGrow: "1",
    flexShrink: "1",
    width: "0px",
    marginRight: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: "0px",
    },
  },
  textRoot: {
    maxWidth: "740px",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  imageRoot: {
    display: "flex",
    justifyContent: "flex-end",
    minWidth: "40%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  image: {
    width: "530px",
    height: "530px",
    borderRadius: "50%",
    boxShadow: "0px 6px 6px #00000045",
    [theme.breakpoints.down("md")]: {
      width: "390px",
      height: "390px",
      marginTop: "100px",
    },
  },
  icon: {
    height: "45px",
    width: "33px",
    color: theme.palette.primary.main,
    marginRight: "13px",
    [theme.breakpoints.down("md")]: {
      marginRight: "17px",
      height: "54px",
      width: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "24px",
      height: "33px",
      marginRight: "12px",
    },
  },
  header: {
    fontSize: "35px",
    letterSpacing: "3.5px",
    lineHeight: "53px",
    fontWeight: "bolder",
    [theme.breakpoints.down("md")]: {
      fontSize: "25px",
      letterSpacing: "2.5px",
      lineHeight: "38px",
      marginBottom: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      letterSpacing: "2px",
      lineHeight: "30px",
    },
  },
  subtext: {
    fontSize: "25px",
    letterSpacing: "2.5px",
    lineHeight: "38px",
    marginBottom: "28px",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      letterSpacing: "2px",
      lineHeight: "30px",
      marginBottom: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
      lineHeight: "25px",
    },
  },
  list: {
    marginBottom: "50px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "20px",
    },
  },
  listItem: {
    padding: "8px 0px",
    alignItems: "unset",
    [theme.breakpoints.down("xs")]: {
      padding: "20px 0px",
    },
  },
  listIcon: {
    color: theme.palette.primary.main,
    width: "22px",
    height: "30px",
    [theme.breakpoints.down("xs")]: {
      width: "24px",
      height: "33px",
      marginRight: "12px",
    },
  },
  listIconOverride: {
    [theme.breakpoints.down("xs")]: {
      minWidth: "unset",
    },
  },
  listItemText: {
    fontSize: "20px",
    letterSpacing: "2px",
    fontWeight: "500",
    lineHeight: "30px",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
      letterSpacing: "1.8px",
      lineHeight: "27px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
      lineHeight: "25px",
    },
  },
  span: {
    color: theme.palette.primary.main,
  },
}));

const PinListItem = ({ item }) => {
  const classes = useStyles();
  return (
    <ListItem className={classes.listItem}>
      <ListIcon className={classes.listIconOverride}>
        <BluePinIcon className={classes.listIcon} />
      </ListIcon>
      <Typography className={classes.listItemText}>
        {item.split("|").map((text, index) =>
          index % 2 ? (
            <span key={index} className={classes.span}>
              {text}
            </span>
          ) : (
            text
          )
        )}
      </Typography>
    </ListItem>
  );
};

const Cooperation = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.leftRoot}>
        <Hidden xsDown>
          <BluePinIcon className={classes.icon} />
        </Hidden>
        <Box className={classes.textRoot}>
          <Hidden xsDown>
            <Typography component="h2" className={classes.header}>
              Zainteresowany współpracą?
            </Typography>
            <Typography className={classes.subtext}>
              Zostając naszym klientem zyskujesz dostęp do szeregu nowoczesnych usług:
            </Typography>
          </Hidden>
          <Hidden smUp>
            <Box display="flex">
              <BluePinIcon className={classes.icon} />
              <Box>
                <Typography component="h2" className={classes.header}>
                  Zainteresowany współpracą?
                </Typography>
                <Typography className={classes.subtext}>
                  Zostając naszym klientem zyskujesz dostęp do szeregu nowoczesnych usług:
                </Typography>
              </Box>
            </Box>
          </Hidden>
          <List className={classes.list}>
            {items.map((item, index) => (
              <PinListItem item={item} key={index} />
            ))}
          </List>
          <Hidden xsDown>
            <Button
              color="primary"
              size="large"
              variant="contained"
              component={Link}
              to="/dla-urzedow"
            >
              Dowiedz się więcej
            </Button>
          </Hidden>
          <Hidden smUp>
            <Button
              color="primary"
              size="medium"
              variant="contained"
              component={Link}
              to="/dla-urzedow"
              className={classes.button}
            >
              Dowiedz się więcej
            </Button>
          </Hidden>
        </Box>
      </Box>
      <Box className={classes.imageRoot}>
        <img src={documents} alt="" className={classes.image} />
      </Box>
    </Box>
  );
};

export default Cooperation;
