import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Portal from "@material-ui/core/Portal";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { makeStyles } from "@material-ui/core/styles";
import useIsMobile from "../../util/useIsMobile";
import { useDispatch, useSelector } from "react-redux";
import { snackbarAction } from "../../redux/modules/snackbarReducer";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    flexGrow: "1",
    minHeight: "calc(100vh - 78px)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: "0",
    [theme.breakpoints.down("xs")]: {
      minHeight: "calc(100vh - 60px)",
    },
  },
}));

const withLayout = WrappedComponent => {
  const HocComponent = ({ ...props }) => {
    const classes = useStyles();
    const { open, error, message } = useSelector(state => state.snackbarReducer);
    const [snackbarKey, setSnackbarKey] = useState(0);
    const isMobile = useIsMobile();
    const dispatch = useDispatch();

    useEffect(() => {
      setSnackbarKey(Math.round(Math.random() * 1000)); // Prevent preemptive hide after prop change
    }, [message, error]);

    const handleSnackbarClose = (e, reason) => {
      if (reason !== "timeout") return; // Disable hide on clickaway
      dispatch(snackbarAction.closeSnackbar());
    };

    return (
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Header />
        <Box className={classes.root}>
          <Portal container={document.body}>
            <Snackbar
              key={snackbarKey}
              open={open}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: isMobile ? "center" : "left",
              }}
            >
              <Alert severity={error ? "error" : "success"} variant="filled" elevation={5}>
                {error && <AlertTitle>Błąd</AlertTitle>}
                {message}
              </Alert>
            </Snackbar>
          </Portal>
          <WrappedComponent {...props} />
        </Box>
        <Footer />
      </Box>
    );
  };
  return HocComponent;
};

export default withLayout;
