import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";
import LabelWithPin from "../../../components/LabelWithPin/LabelWithPin";
import TextField from "../../../components/TextField/TextField";
import { userAction } from "../../../redux/modules/userReducer";
import deleteAccount from "../../../services/user/deleteAccount";
import Header from "../components/Header";
import Root from "../components/Root";
import { useLoginStyles } from "../useLoginStyles";

const DeleteAccount = () => {
  const [password, setPassword] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const classes = useLoginStyles({ showMessage: error !== "" });
  const history = useHistory();
  const dispatch = useDispatch();
  const { token } = useParams();

  const handleRequest = e => {
    e.preventDefault();
    setIsLoading(true);
    deleteAccount(token, password)
      .then(response => {
        if (response.code !== "000") {
          setError(response.message);
        } else {
          dispatch(userAction.logout());
          localStorage.removeItem("localspotSelectedOffice");
          history.replace(`/usun-konto/sukces`, { date: new Date().getTime() });
        }
      })
      .catch(() => setError("Wystąpił błąd. Proszę spróbować ponownie."))
      .finally(() => setIsLoading(false));
  };

  if (!token || !/(\S+\.){2}(\S+)/.test(token)) {
    return <Redirect to="/" />;
  }

  return (
    <Root>
      <form className={classes.root}>
        <Header header="Usuwanie konta" />
        <Typography className={classes.helperText}>
          Potwierdź usunięcie konta podając swoje hasło.
        </Typography>
        <Box width="100%" maxWidth="510px">
          <LabelWithPin marginBottom="8px" marginRight="8px" label="Hasło" />
          <TextField
            className={classes.input}
            placeholder="Wprowadź hasło"
            variant="filled"
            type="password"
            autoComplete="password"
            onChange={e => setPassword(e.target.value)}
          />
        </Box>
        <Typography className={classes.errorMessage}>{error + ""}</Typography>
        <Button
          color="primary"
          variant="contained"
          className={classes.loginButton}
          type="submit"
          disabled={!password || isLoading}
          onClick={e => handleRequest(e)}
        >
          Usuń konto
        </Button>
      </form>
    </Root>
  );
};

export default DeleteAccount;
