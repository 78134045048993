import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { mapAction } from "../../../redux/modules/mapReducer";

export const useMapCheck = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const { pathname } = location;
    if (!pathname.includes("/map/dodaj-zgloszenie")) {
      dispatch(mapAction.clearNewMarker());
    }

    if (!pathname.includes("/map/details") && !pathname.includes("/map/ogloszenie")) {
      dispatch(mapAction.clearActiveMarker());
    }
  }, [location.pathname]);
};
