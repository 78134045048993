import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const BluePinIcon = props => {
  return (
    <SvgIcon viewBox="0 0 21.935 29.564" {...props}>
      <g transform="translate(-401.806 -1842.5)">
        <path
          d="M184.615,14.7c0,6.016-10.967,18.6-10.967,18.6S162.68,20.72,162.68,14.7a10.967,10.967,0,1,1,21.935,0Z"
          transform="translate(239.126 1838.764)"
          fill="currentColor"
        />
        <circle
          cx="4.927"
          cy="4.927"
          r="4.927"
          transform="translate(407.846 1848.084)"
          fill={props.fill ? props.fill : "#FFFFFF"}
        />
      </g>
    </SvgIcon>
  );
};

export default BluePinIcon;
