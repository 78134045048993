import React from "react";
import { Redirect, useParams } from "react-router-dom";

const ActivateAccountRedirect = () => {
  const { token } = useParams();

  if (token) {
    return <Redirect to={`/aktywuj/${token}`} />;
  } else {
    return <Redirect to="/login" />;
  }
};

export default ActivateAccountRedirect;
