import { useEffect } from "react";
import L from "leaflet";
import mapboxgl from "mapbox-gl";
import "mapbox-gl-leaflet";
import { useMap } from "react-leaflet";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const StyledLayer = () => {
  const map = useMap();

  useEffect(() => {
    const layer = new L.mapboxGL({
      accessToken:
        "pk.eyJ1IjoiaWRhMDIiLCJhIjoiY2syYXUwN2FoMDEyYjNvbWhlcjA2anR6cyJ9.F3vCrPa8vOqKND8Xx_-TYg",
      attribution:
        '&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors',
      style:
        "https://api.maptiler.com/maps/96cd1d87-79fb-4e6f-83a7-7429add478cf/style.json?key=YoauRDISyGLNSXbCBW5P",
    });

    map && layer.addTo(map);

    return () => {
      layer.remove();
    };
  }, [map]);

  return null;
};

export default StyledLayer;
