import L from "leaflet";
import "./marker.css";

const createClusterIcon = (cluster) => {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    iconSize: [30, 30],
    iconAnchor: [15, 15],
    className: "bus-stop-cluster",
  });
};

export default createClusterIcon;
