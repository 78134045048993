const isLocalSpot = process.env.REACT_APP_LOCALSPOT_TYPE === "localspot";

export const rows = [
  {
    title: "Dostęp do powiadomień push",
    content:
      "Dzięki powiadomieniom push powiadomisz mieszkańca o nadchodzącym festynie lub remoncie drogi. Mieszkaniec zawsze wie co, gdzie i kiedy, pozostając w dobrym kontakcie ze swoim urzędem.",
  },
  {
    title: "Narzędzie ankiet i sondaży",
    content:
      "Publikuj ankiety oraz generuj sondaże aby zbadać opinie mieszkańców na temat tego co dla Ciebie najważniejsze. Mieszkańcy otrzymają łatwe narzędzie do wyrażania swojej opinii a urząd możliwość analizy danych.",
  },
  {
    title: "Publikowanie harmonogramu wywozu odpadów oraz rozkładów jazdy",
    content:
      `Urząd publikuje harmonogram wywozu odpadów, a na dzień przed terminem odbioru mieszkaniec otrzymuje powiadomienie z przypomnieniem. ${isLocalSpot ? "LocalSpot" : "Komunikator Miejski"} umożliwia również mieszkańcowi sprawdzenie lokalizacji przystanków na mapie oraz dostęp do rozkładu jazdy.`,
  },
  {
    title: "Dedykowane domeny oraz aplikacja mobilna",
    content:
      `Promuj swój urząd za pomocą dedykowanego adresu internetowego np. wrzesnia.${isLocalSpot ? "localspot" : "komunikatormiejski"}.pl. W aplikacji mobilnej mieszkaniec na ekranie głównym zobaczy herb oraz nazwę miasta wraz ze wszystkimi aktualnymi ogłoszeniami.`,
  },
  {
    title: "Dostęp do zgłoszeń od mieszkańców",
    content:
      "Mieszkańcy zgłaszają problemy zauważone w swojej okolicy takie jak dziura w drodze, czy uszkodzona latarnia. Urząd monitoruje postęp prac pozostając w dobrych stosunkach ze swoim mieszkańcem.",
  },
];
