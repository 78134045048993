import React from "react";
import SuccessPage from "../components/SuccessPage";
import { useHistory, useLocation, Redirect } from "react-router-dom";

const DeleteAccountSuccess = () => {
  const history = useHistory();
  const key = useLocation().state?.date;

  if (!key) {
    return <Redirect to="/login" />
  }

  return (
    <SuccessPage
      header="Konto zostało usunięte"
      texts={["Proces usuwania konta został zakończony pomyślnie."]}
      buttons={["Przejdź na stronę główną"]}
      buttonActions={[() => history.replace("/")]}
    />
  );
};

export default DeleteAccountSuccess;