export default function markerTypes(type) {
  const types = {
    "GOV": 'Urzędy',
    "BUS": 'Przystanki',
    "OSI": 'Ostrzeżenia i informacje',
    "ATR": 'Atrakcje',
    1: 'Zgłoszone',
    2: 'W realizacji',
    3: 'Zamknięte',
    4: 'Odrzucone'
  }
  
  if (typeof types[type] !== undefined) return types[type];
  return '';
}
