import React, { useEffect } from "react";
import { useMap } from "react-leaflet";
import { active } from "../../../static/map/markers";
import MapMarker from "./MapMarker";

const ActiveMapMarker = ({ marker, pos, icon, small, type, openInNewTab, ...props }) => {
  const map = useMap();

  useEffect(() => {
    if (!pos) return;

    if (map.getBounds().contains(pos)) {
      map.panTo(pos);
    } else {
      map.flyTo(pos, 14, { duration: 2 });
    }
  }, [map, pos]);

  return <MapMarker pos={pos} icon={active.gov} type="active" {...props} key={pos} />;
};

export default ActiveMapMarker;
