import axios from "axios";

const getCoordinates = ({ address }) =>
  axios
    .get(process.env.REACT_APP_GOOGLE_MAPS_API_URL, {
      params: {
        key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
        language: "pl",
        region: "pl",
        address,
      },
    })
    .then(response => response.data.results[0]);

export default getCoordinates;
