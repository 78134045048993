import { put, call, takeLatest } from "redux-saga/effects";
import { mapConstants } from "../modules/mapReducer";
import getAnnouncements from "../../services/map/getAnnouncements";
import getIncidents from "../../services/map/getIncidents";
import getSingleIncident from "../../services/map/getSingleIncident";
import getSingleAnnouncement from "../../services/map/getSingleAnnouncement";
import getCategories from "../../services/map/getCategories";

function* searchAnnouncements({ northEast, southWest, activeTypes, showFavourite }) {
  try {
    const payload = yield call(getAnnouncements, { northEast, southWest, activeTypes, showFavourite });
    yield put({ type: mapConstants.GET_ANNOUNCEMENTS.SUCCESS, announcements: payload.results });
  } catch (error) {
    yield put({ type: mapConstants.GET_ANNOUNCEMENTS.FAILED, error });
  }
}

function* searchIncidents({
  northEast,
  southWest,
  activeStatuses,
  showFavourite,
  showArchive,
  showGov,
}) {
  try {
    const payload = yield call(getIncidents, {
      northEast,
      southWest,
      activeStatuses,
      showFavourite,
      showArchive,
      showGov,
    });
    yield put({
      type: mapConstants.GET_INCIDENTS.SUCCESS,
      govs: payload.gov,
      incidents: payload.results,
    });
  } catch (error) {
    yield put({ type: mapConstants.GET_INCIDENTS.FAILED, error });
  }
}

function* searchSingleIncident({ id }) {
  try {
    const payload = yield call(getSingleIncident, { id });
    const result = payload.results[0];
    if (!!result && result.latitude !== undefined && result.longitude !== undefined) {
      yield put({
        type: mapConstants.SEARCH_SINGLE_INCIDENT.SUCCESS,
        marker: result,
      });
    } else {
      yield put({
        type: mapConstants.SEARCH_SINGLE_INCIDENT.FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: mapConstants.SEARCH_SINGLE_INCIDENT.FAILED,
      e,
    });
  }
}

function* searchSingleAnnouncement({ id }) {
  try {
    const payload = yield call(getSingleAnnouncement, { id });
    const result = payload.results[0];
    if (!!result && result.latitude !== undefined && result.longitude !== undefined) {
      yield put({
        type: mapConstants.SEARCH_SINGLE_ANNOUNCEMENT.SUCCESS,
        marker: result,
      });
    } else {
      yield put({
        type: mapConstants.SEARCH_SINGLE_ANNOUNCEMENT.FAILED,
      });
    }
  } catch (e) {
    yield put({
      type: mapConstants.SEARCH_SINGLE_ANNOUNCEMENT.FAILED,
      e,
    });
  }
}

function* searchCategories() {
  try {
    const payload = yield call(getCategories);
    const result = payload.results;
    yield put({
      type: mapConstants.SEARCH_CATEGORIES.SUCCESS,
      categories: result,
    });
  } catch (e) {
    yield put({
      type: mapConstants.SEARCH_CATEGORIES.FAILED,
      e,
    });
  }
}

function* watchMap() {
  yield takeLatest(mapConstants.GET_ANNOUNCEMENTS.ACTION, searchAnnouncements);
  yield takeLatest(mapConstants.GET_INCIDENTS.ACTION, searchIncidents);
  yield takeLatest(mapConstants.SEARCH_SINGLE_INCIDENT.ACTION, searchSingleIncident);
  yield takeLatest(mapConstants.SEARCH_SINGLE_ANNOUNCEMENT.ACTION, searchSingleAnnouncement);
  yield takeLatest(mapConstants.SEARCH_CATEGORIES.ACTION, searchCategories);
}

export default watchMap;
