import { axios } from "../../util/axiosConfig";
import { incidents } from "../urls";
import crypto from "crypto";

const createIncident = (values, image) => {
  const formData = new FormData();
  formData.append(
    "incident",
    JSON.stringify({
      ...values,
      closeToken: crypto.randomBytes(16).toString("base64"),
      source: 3,
    })
  );
  if (image) {
    formData.append("file", image, image.name);
  }

  return axios
    .post(incidents.create, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(response => response.data.results[0]);
};

export default createIncident;
