import React from "react";
import { Switch, Route } from "react-router-dom";
import ActivateAccount from "./ActivateAccount";
import ActivateAccountSuccess from "./ActivateAccountSuccess";

const ActivateAccountWrapper = () => {
  return (
    <Switch>
      <Route path="/aktywuj/:token/sukces">
        <ActivateAccountSuccess />
      </Route>
      <Route path="/aktywuj/:token">
        <ActivateAccount />
      </Route>
    </Switch>
  );
};

export default ActivateAccountWrapper;
