import React, { useEffect, useState, useRef } from "react";
import SuccessPage from "../components/SuccessPage";
import { Redirect, useHistory } from "react-router-dom";
import initAccountDelete from "../../../services/user/initAccountDelete";
import { useSelector } from "react-redux";

const DeleteAccountInitSuccess = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const isLoggedIn = useSelector(state => state.userReducer.isLoggedIn);
  const history = useHistory();
  const timeout = useRef(null);

  useEffect(() => {
    if (sent) {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => setSent(false), 60 * 1000);
    }
    return () => clearTimeout(timeout.current);
  }, [sent]);

  const handleResend = () => {
    setIsLoading(true);
    initAccountDelete()
      .then(() => setSent(true))
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <SuccessPage
      header="E-mail został wysłany"
      texts={[
        "Na adres email przypisany do konta został wysłany link dzięki któremu będziesz mógł usunąć swoje konto.",
        "Nie otrzymałeś maila? Upewnij się że wprowadziłeś poprawny adres e-mail, oraz sprawdź skrzynkę spam.",
      ]}
      buttons={["Wyślij ponownie", "Przejdź na stronę główną"]}
      buttonActions={[() => handleResend(), () => history.replace("/login")]}
      disabledButtons={[isLoading || sent]}
    />
  );
};

export default DeleteAccountInitSuccess;
