import React, { useEffect, useRef, useState } from "react";
import { MapContainer, GeoJSON, Tooltip, useMapEvents } from "react-leaflet";
import { useSelector, useDispatch } from "react-redux";
import { mapAction } from "../../redux/modules/mapReducer";
import { markers, active } from "../../static/map/markers";
import { govMarkers } from "../../static/map/govMarkers";
import createClusterIcon from "./components/createClusterIcon";
import ActiveMapMarker from "./components/ActiveMapMarker";
import MarkerCluster from "./components/MarkerCluster";
import MapMarker from "./components/MapMarker";
import createMarker from "./components/LeafletMapMarker";
import StyledLayer from "./components/StyledLayer";
import AddressSearch from "./components/AddressSearch";
import ZoomControl from "./components/ZoomControl";
import { useHistory } from "react-router";
import getGeoJson from "../../services/townHall/getGeoJson";

const center = [52.03, 19.133];

const BoundsController = ({ geoJsonBounds }) => {
  const dispatch = useDispatch();
  const activeMarker = useSelector(state => state.mapReducer.activeMarker);
  const activeMarkerId = useSelector(state => state.mapReducer.activeMarkerId);
  const newMarkerPos = useSelector(state => state.mapReducer.newMarkerPos);
  const mapZoom = useSelector(state => state.mapReducer.mapZoom);
  const history = useHistory();

  const map = useMapEvents({
    moveend() {
      const bounds = map.getBounds();
      dispatch(
        mapAction.changeBounds({
          northEast: bounds.getNorthEast(),
          southWest: bounds.getSouthWest(),
          center: map.getCenter(),
        })
      );
    },
    click(e) {
      dispatch(mapAction.setNewMarker({ pos: e.latlng }));
      history.push("/map/dodaj-zgloszenie");
    },
  });

  useEffect(() => {
    map.invalidateSize();
    if (activeMarker) {
      const { latitude, longitude } = activeMarker;
      if (mapZoom) {
        // mapZoom is not null -> searching for a single marker
        map.flyTo([latitude, longitude], mapZoom, { animate: false });
      } else {
        // mapZoom is null -> clicked on a marker
        map.panTo([latitude, longitude]);
      }
      return;
    }

    if (geoJsonBounds && !activeMarkerId && !newMarkerPos) {
      map.fitBounds(geoJsonBounds, { animate: false });
    }
  }, [activeMarker, activeMarkerId, newMarkerPos, mapZoom, geoJsonBounds, map]);

  return null;
};

const Map = ({ className }) => {
  const northEast = useSelector(state => state.mapReducer.northEast);
  const southWest = useSelector(state => state.mapReducer.southWest);
  const incidents = useSelector(state => state.mapReducer.incidents);
  const activeStatuses = useSelector(state => state.mapReducer.activeStatuses);
  const activeTypes = useSelector(state => state.mapReducer.activeTypes);
  const activeMarker = useSelector(state => state.mapReducer.activeMarker);
  const newMarkerPos = useSelector(state => state.mapReducer.newMarkerPos);
  const activeMarkerType = useSelector(state => state.mapReducer.activeMarkerType);
  const showFavourite = useSelector(state => state.mapReducer.showFavourite);
  const showArchive = useSelector(state => state.mapReducer.showArchive);
  const showGov = useSelector(state => state.mapReducer.showGov);
  const announcements = useSelector(state => state.mapReducer.announcements);
  const govs = useSelector(state => state.mapReducer.govs);
  const dispatch = useDispatch();
  const history = useHistory();
  const announcementsTimeout = useRef();
  const incidentsTimeout = useRef();
  const layerRef = useRef();
  const [geoJson, setGeoJson] = useState();
  const [geoJsonBounds, setGeoJsonBounds] = useState();

  useEffect(() => {
    if (!process.env.REACT_APP_GEOJSON) return;

    getGeoJson(process.env.REACT_APP_GEOJSON).then(geoJson => {
      setGeoJson(geoJson);
    });
  }, [process.env.REACT_APP_GEOJSON]);

  useEffect(() => {
    clearTimeout(announcementsTimeout.current);
    clearTimeout(incidentsTimeout.current);
    announcementsTimeout.current = setTimeout(handleAnnouncementsDownload, 500);
    incidentsTimeout.current = setTimeout(handleIncidentsDownload, 500);
  }, [northEast, southWest]);

  useEffect(() => {
    clearTimeout(announcementsTimeout.current);
    handleAnnouncementsDownload();
  }, [activeTypes, showFavourite]);

  useEffect(() => {
    clearTimeout(incidentsTimeout.current);
    handleIncidentsDownload();
  }, [activeStatuses, showFavourite, showArchive, showGov, dispatch]);

  const handleAnnouncementsDownload = () => {
    dispatch(mapAction.getAnnouncements({ northEast, southWest, activeTypes, showFavourite }));
  };

  const handleIncidentsDownload = () => {
    dispatch(
      mapAction.getIncidents({
        northEast,
        southWest,
        activeStatuses,
        showFavourite,
        showArchive,
        showGov,
      })
    );
  };

  const handleZoomToJson = () => {
    if (!layerRef.current) {
      return;
    }

    const bounds = layerRef.current.getBounds();
    if (bounds && bounds.isValid()) {
      setGeoJsonBounds(bounds);
    }
  };

  return (
    <MapContainer
      center={center}
      zoom={process.env.REACT_APP_MAP_ZOOM || 7}
      minZoom={7}
      maxZoom={18}
      className={className}
      zoomControl={false}
    >
      <StyledLayer />
      <AddressSearch />
      <ZoomControl disableFitToMap={!!process.env.REACT_APP_GEOJSON} />
      <BoundsController geoJsonBounds={geoJsonBounds} />
      {geoJson && (
        <GeoJSON
          ref={layerRef}
          data={geoJson}
          eventHandlers={{ add: handleZoomToJson }}
          pathOptions={{ fillOpacity: 0.1 }}
        />
      )}
      <MarkerCluster
        markers={incidents
          .filter(i => !activeMarker || activeMarkerType !== "incident" || i.id !== activeMarker.id)
          .map(marker =>
            createMarker({
              pos: [marker.latitude, marker.longitude],
              icon: markers[marker.status],
              marker,
              type: "incident",
              small: true,
              history,
            })
          )}
      />
      <MarkerCluster
        iconCreateFunction={createClusterIcon}
        markers={announcements
          .filter(
            a => !activeMarker || activeMarkerType !== "announcement" || a.id !== activeMarker.id
          )
          .map(marker =>
            createMarker({
              pos: [marker.latitude, marker.longitude],
              icon: govMarkers[marker.category],
              marker,
              type: "announcement",
              small: true,
              history,
            })
          )}
      />
      {activeMarker ? (
        activeMarkerType === "incident" ? (
          <MapMarker
            pos={[activeMarker.latitude, activeMarker.longitude]}
            icon={active[activeMarker.status]}
            type="active"
          />
        ) : (
          <MapMarker
            pos={[activeMarker.latitude, activeMarker.longitude]}
            icon={active.gov}
            type="active"
          />
        )
      ) : null}
      {newMarkerPos && <ActiveMapMarker pos={newMarkerPos} />}
      {govs.map(marker => (
        <MapMarker
          key={marker.identifier}
          pos={[marker.lat, marker.lng]}
          icon={govMarkers.GOV}
          marker={marker}
          type="gov"
        >
          <Tooltip>{marker.name}</Tooltip>
        </MapMarker>
      ))}
    </MapContainer>
  );
};

export default Map;
