import React, { useState, useEffect, useMemo, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import LabelWithPin from "../../../components/LabelWithPin/LabelWithPin";
import TextField from "../../../components/TextField/TextField";
import { makeStyles } from "@material-ui/core/styles";
import nickExists from "../../../services/user/nickExists";
import updateUserData from "../../../services/user/updateUserData";
import useIsMobile from "../../../util/useIsMobile";

const fields = [
  {
    id: "name",
    name: "Imię",
    autocomplete: "given-name",
    isRequired: false,
  },
  {
    id: "surname",
    name: "Nazwisko",
    autocomplete: "family-name",
    isRequired: false,
  },
  {
    id: "email",
    name: "Adres e-mail",
    type: "email",
    autocomplete: "email",
    isRequired: true,
    disabled: true,
  },
  {
    id: "nick",
    name: "Podpis",
  },
  {
    id: "oldPassword",
    name: "Stare hasło",
    type: "password",
    autocomplete: "current-password",
  },
  {
    id: "newPassword",
    name: "Nowe hasło",
    type: "password",
    autocomplete: "new-password",
  },
  {
    id: "repeatNewPassword",
    name: "Powtórz nowe hasło",
    placeholder: "Powtórz hasło",
    type: "password",
    autocomplete: "new-password",
  },
];

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  inputRoot: {
    width: "100%",
    maxWidth: "445px",
    marginBottom: "25px",
  },
  input: {
    width: "100%",
  },
}));

const EditData = ({ userData, dialogOpen, onClose }) => {
  const classes = useStyles();
  const isMobile = useIsMobile("xs");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNick, setIsLoadingNick] = useState(false);
  const [values, setValues] = useState({ ...userData });
  const [errors, setErrors] = useState({});
  const timeoutRef = useRef();
  const isDisabled = useMemo(() => {
    if (!!values.newPassword && !values.oldPassword) return true;

    let disabled = false;

    fields.forEach(f => {
      if (f.isRequired && !values[f.id]) {
        disabled = true;
      }
    });

    if (!disabled) {
      Object.keys(errors).forEach(key => {
        if (!!errors[key]) {
          disabled = true;
        }
      });
    }

    return disabled;
  }, [values, errors]);

  useEffect(() => {
    if (!dialogOpen) {
      return;
    }

    setValues({ ...userData });
    setErrors({});
  }, [dialogOpen]);

  useEffect(() => {
    clearTimeout(timeoutRef.current);
    if (values.nick === userData.nick) {
      setIsLoadingNick(false);
      return;
    }

    setIsLoadingNick(true);
    timeoutRef.current = setTimeout(() => checkNick(values.nick), 250);
  }, [values.nick]);

  const checkNick = nick => {
    nickExists(nick)
      .then(exists => {
        if (exists) {
          setError("nick", "Ktoś używa tego podpisu.");
        } else {
          setError("nick", null);
        }
      })
      .finally(() => {
        setIsLoadingNick(false);
      });
  };

  const setError = (key, value) => {
    const tmpErrors = { ...errors };
    tmpErrors[key] = value;
    setErrors(tmpErrors);
  };

  const setValue = (key, value) => {
    const tmpValues = { ...values };
    tmpValues[key] = value;
    setError(key, null);
    setValues(tmpValues);
  };

  const handleSave = () => {
    if (!!values.newPassword && values.newPassword !== values.repeatNewPassword) {
      const tmpErrors = { ...errors };
      tmpErrors.repeatNewPassword = "Podane hasła różnią się od siebie.";
      setErrors(tmpErrors);
      return;
    }

    updateUserData(values)
      .then(() => {
        onClose(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={onClose}
      maxWidth="sm"
      fullScreen={isMobile}
      fullWidth
      scroll="body"
    >
      <DialogTitle>Edytuj dane</DialogTitle>
      <DialogContent className={classes.root}>
        {fields.map(field => (
          <Box className={classes.inputRoot} key={field.id}>
            <LabelWithPin
              label={field.name}
              transform="uppercase"
              htmlFor={`edit-data-${field.id}`}
            />
            <TextField
              id={`edit-data-${field.id}`}
              className={classes.input}
              variant="filled"
              value={values[field.id] || ""}
              onChange={e => setValue(field.id, e.target.value)}
              autoComplete={field.autocomplete}
              error={!!errors[field.id]}
              helperText={errors[field.id]}
              disabled={field.disabled}
              type={field.type}
              placeholder={
                field.placeholder ? field.placeholder : "Wprowadź " + field.name.toLowerCase()
              }
            />
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="primary" size="small">
          Anuluj
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleSave}
          disabled={isDisabled || isLoading || isLoadingNick}
          startIcon={isLoading && <CircularProgress size={24} />}
        >
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditData;
