import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import BluePinIcon from "../../../../static/BluePinIcon";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import withScrollButton from "../../../../components/withScrollButton/withScrollButton";
import { makeStyles } from "@material-ui/core/styles";
import background from "../../../../static/download-bg.png";
import { rows } from "./rows";

const useStyles = makeStyles(theme => ({
  assetsRoot: {
    height: "fit-content",
    minHeight: "calc(100vh - 78px)",
    width: "100%",
    padding: "30px min(10%, 190px) 85px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundImage: `url(${background})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      padding: "30px min(10%, 190px)",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "23px 36px",
      minHeight: "calc(100vh - 60px)",
    },
  },
  iconRoot: {
    display: "flex",
    marginBottom: "40px",
  },
  icon: {
    height: "45px",
    width: "33px",
    color: theme.palette.primary.main,
    marginRight: "16px",
    [theme.breakpoints.down("xs")]: {
      width: "24px",
      height: "32px",
      marginRight: "30px",
    },
  },
  header: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      letterSpacing: "2px",
      lineHeight: "30px",
      fontWeight: "bold",
    },
  },
  table: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    borderRadius: "16px",
    border: "1px solid #14387B",
    [theme.breakpoints.down("md")]: {
      borderRadius: "unset",
      border: "unset",
      background: "unset",
    },
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    minHeight: "120px",
    height: "fit-content",
    "&:not(:last-of-type)": {
      borderBottom: "1px solid #14387B",
    },
    [theme.breakpoints.down("md")]: {
      "&:not(:last-of-type)": {
        borderBottom: "unset",
      },
    },
    [theme.breakpoints.down("xs")]: {
      "&:not(:last-of-type)": {
        marginBottom: "50px",
      },
    },
  },
  cell: {
    padding: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
    },
  },
  cellLeft: {
    width: "25%",
    borderRight: "1px solid #14387B",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      borderRight: "none",
    },
  },
  cellRight: {
    width: "75%",
    flexGrow: "1",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "42px",
      wordBreak: "break-word",
    },
  },
  smallIcon: {
    width: "22px",
    height: "22px",
    color: theme.palette.primary.main,
    marginRight: "20px",
    marginTop: "3px",
  },
  cellTitle: {
    fontSize: "20px",
    letterSpacing: "2px",
    fontWeight: "600",
    lineHeight: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
      lineHeight: "25px",
      marginBottom: "20px",
    },
  },
  cellContent: {
    fontSize: "20px",
    letterSpacing: "2px",
    lineHeight: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
      lineHeight: "25px",
    },
  },
  text: {
    fontSize: "30px",
    fontWeight: "500",
    lineHeight: "46px",
    maxWidth: "795px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      letterSpacing: "2px",
      lineHeight: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      letterSpacing: "1.6px",
      lineHeight: "25px",
    },
  },
}));

const Assets = () => {
  const classes = useStyles();
  const isLocalSpot = process.env.REACT_APP_LOCALSPOT_TYPE === "localspot";

  return (
    <Box className={classes.assetsRoot}>
      <Box className={classes.iconRoot}>
        <BluePinIcon className={classes.icon} />
        <Typography component="h2" variant="h2" className={classes.header}>
          {isLocalSpot ? "LocalSpot" : "Komunikator Miejski"} oferuje Twojemu urzędowi:
        </Typography>
      </Box>
      <Box className={classes.table}>
        {rows.map((row, index) => (
          <Box className={classes.row} key={index}>
            <Box className={`${classes.cell} ${classes.cellLeft}`}>
              <Box display="flex" width="100%">
                <CheckCircleIcon className={classes.smallIcon} />
                <Typography className={classes.cellTitle}>{row.title}</Typography>
              </Box>
            </Box>
            <Box className={`${classes.cell} ${classes.cellRight}`}>
              <Typography className={classes.cellContent}>{row.content}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default withScrollButton(Assets, 40);
