import CategorySelect from "./CategorySelect";
import PhotoPicker from "./PhotoPicker";

export const fields = {
  address: {
    name: "Adres*",
    placeholder: "Wpisz adres",
  },
  category: {
    name: "Kategoria*",
    select: true,
    placeholder: "Wybierz kategorię",
    component: CategorySelect,
  },
  description: {
    name: "Opis zgłoszenia",
    multiline: true,
    rows: 2,
    placeholder: "Opisz zgłoszenie",
  },
  photo: {
    name: "Zdjęcie",
    component: PhotoPicker,
  },
  nick: {
    name: "Podpis",
    notLoggedIn: true,
    placeholder: "Podpisz się",
  },
  email: {
    name: "E-mail",
    type: "email",
    notLoggedIn: true,
    placeholder: "Wpisz swój e-mail",
  },
};
