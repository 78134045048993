import { axios } from "../../util/axiosConfig";
import { account } from "../urls";

const updateProfilePictrue = avatar => {
  const data = new FormData();
  data.append("avatar", avatar, "avatar.jpg");
  return axios({
    method: "post",
    url: account.edit,
    data,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(response => response.data);
};

export default updateProfilePictrue;
