import { CircularProgress, IconButton, makeStyles, Typography } from "@material-ui/core";
import { ArrowForward, Clear } from "@material-ui/icons";
import clsx from "clsx";
import L from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import { useMap } from "react-leaflet";
import getCoordinates from "../../../services/map/getCoordinates";
import { useQueryParam } from "../../../util/useQueryParam";
import SearchField from "../../SearchField/SearchField";

const useStyles = makeStyles(theme => ({
  inputRoot: {
    borderRadius: "20px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0px 3px 6px #00000040",
    width: "400px",
    [theme.breakpoints.down("sm")]: {
      width: "unset",
      maxWidth: "400px",
      flexGrow: "1",
    },
  },
  input: {
    width: "100%",
  },
  iconButton: {
    marginRight: "5px",
  },
}));

const AddressSearch = () => {
  const map = useMap();
  const classes = useStyles();
  const searchParam = useQueryParam("a");
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState(searchParam || "");
  const inputRef = useRef();

  useEffect(() => {
    L.DomEvent.disableClickPropagation(inputRef.current);
  }, []);

  useEffect(() => {
    if (searchParam) {
      handleSearch();
    }
  }, [searchParam]);

  const handleSearch = e => {
    e?.preventDefault();
    if (loading) return;

    setLoading(true);
    getCoordinates({ address: searchText })
      .then(result => {
        if (!result) {
          return;
        }

        const { geometry, formatted_address } = result;
        map.flyTo({ ...geometry.location }, 14, { duration: 2 });
        setSearchText(formatted_address);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTextChange = e => {
    setSearchText(e.target.value);
  };

  const handleTextClear = () => {
    setSearchText("");
  };

  const renderAdornment = () => {
    if (!searchText.length) return undefined;

    if (loading) return <CircularProgress size={24} />;

    return (
      <React.Fragment>
        <IconButton size="small" className={classes.iconButton} onClick={handleTextClear}>
          <Clear />
        </IconButton>
        <IconButton type="submit" size="small" color="primary">
          <ArrowForward />
        </IconButton>
      </React.Fragment>
    );
  };

  return (
    <div className="leaflet-top leaflet-left leaflet-md-center">
      <form className={clsx("leaflet-control", classes.inputRoot)} onSubmit={handleSearch}>
        <Typography variant="srOnly" component="label" htmlFor="address-search">
          Znajdź na mapie
        </Typography>
        <SearchField
          id="address-search"
          ref={inputRef}
          placeholder="ZNAJDŹ NA MAPIE"
          variant="filled"
          value={searchText}
          onChange={handleTextChange}
          className={classes.input}
          endAdornment={renderAdornment()}
        />
      </form>
    </div>
  );
};

export default AddressSearch;
