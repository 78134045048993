import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  headerSection: {
    padding: "23px 10px",
    borderBottom: "1px solid #DDDDDD",
  },
  header: {
    fontSize: "20px",
    fontWeight: "500",
    textAlign: "center",
    textTransform: "uppercase",
    color: theme.palette.primary.main,
  },
}));

const ListHeader = ({ title }) => {
  const classes = useStyles();

  return (
    <Box className={classes.headerSection}>
      <Typography component="h2" className={classes.header}>
        {title}
      </Typography>
    </Box>
  );
};

export default ListHeader;
