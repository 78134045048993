import React from "react";
import Collapse from "@material-ui/core/Collapse";
import FilterBar from "./components/FilterBar";
import List from "./components/List";
import { useSelector } from "react-redux";
import { markers } from "../../../../../static/map/markers";
import { mapAction } from "../../../../../redux/modules/mapReducer";
import useIsMobile from "../../../../../util/useIsMobile";

const IncidentsList = ({ showCollapse }) => {
  const isMobile = useIsMobile();
  const incidents = useSelector(state => state.mapReducer.incidents);
  const activeStatuses = useSelector(state => state.mapReducer.activeStatuses);

  const Wrapper = isMobile ? Collapse : React.Fragment;
  const wrapperProps = isMobile ? { in: showCollapse } : undefined;

  return (
    <React.Fragment>
      <Wrapper {...wrapperProps}>
        <FilterBar
          types={[1, 2, 3, 4]}
          activeIcons={markers}
          active={activeStatuses}
          changeActive={mapAction.changeActiveStatuses}
          searchAction={mapAction.searchIncidents}
          placeholder="SZUKAJ ZGŁOSZEŃ"
        />
      </Wrapper>
      <List markers={incidents} isIncident />
    </React.Fragment>
  );
};

export default IncidentsList;
