import React from "react";
import { Switch, Route } from "react-router-dom";
import DeleteAccount from "./DeleteAccount";
import DeleteAccountInitSuccess from "./DeleteAccountInitSuccess";
import DeleteAccountSuccess from "./DeleteAccountSuccess";

const DeleteAccountWrapper = () => {
  return (
    <Switch>
      <Route path="/usun-konto/rozpoczecie" exact>
        <DeleteAccountInitSuccess />
      </Route>
      <Route path="/usun-konto/sukces" exact>
        <DeleteAccountSuccess />
      </Route>
      <Route path="/usun-konto/:token">
        <DeleteAccount />
      </Route>
    </Switch>
  );
};

export default DeleteAccountWrapper;
