import React from "react";
import TopBanner from "../../../../components/TopBanner/TopBanner";
import banner1 from "../../../../static/topBanner/banner1.jpg";

const LandingPageBanner = () => {
  const isLocalSpot = process.env.REACT_APP_LOCALSPOT_TYPE === "localspot";
  return (
    <TopBanner
      src={banner1}
      topText={`Rozwiązania Smart City\nw Twoim mieście`}
      middleText={`${
        isLocalSpot ? "LocalSpot" : "Komunikator Miejski"
      } to niezwykła usługa łącząca mieszkańca ze swoim urzędem za pomocą nowoczesnych rozwiązań.`}
    />
  );
};

export default LandingPageBanner;
