import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import ListHeader from "./ListHeader";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    "&:not(:last-child)": {
      borderRight: "1px solid #DDDDDD",
    },
  },
  list: {
    overflow: "scroll",
  },
}));

const List = ({ title, renderList }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <ListHeader title={title} />
      <Box className={classes.list}>{renderList}</Box>
    </Box>
  );
};

export default List;
