import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import PinWithLabel from "../../components/LabelWithPin/LabelWithPin";
import Avatar from "./components/Avatar";
import { useStyles } from "./components/styles";
import Office from "./components/Office";
import EditData from "./components/EditData";
import MailNotifications from "./components/MailNotifications";
import initAccountDelete from "../../services/user/initAccountDelete";
import { useHistory } from "react-router-dom";
import sizeMe from "react-sizeme";

const fields = [
  {
    id: "nick",
    name: "Podpis",
  },
  {
    id: "email",
    name: "Adres e-mail",
  },
];

const getIncidentCountString = count => {
  if (count === 1) return "zgłoszenie";

  const unit = count % 10;
  if (unit >= 2 && unit <= 4 && (count < 10 || count > 20)) return "zgłoszenia";

  return "zgłoszeń";
};

const Row = ({ field, userData }) => {
  const classes = useStyles();

  return (
    <Box className={classes.row}>
      <Typography className={classes.fieldName}>{field.name}:</Typography>
      <Typography className={classes.value}>{userData[field.id]}</Typography>
    </Box>
  );
};

const UserDetails = ({ userData, handleRefresh, incidentCount, size, setHeight }) => {
  const classes = useStyles();
  const history = useHistory();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [initingDelete, setInitingDelete] = useState(false);

  useEffect(() => {
    setHeight(size.height);
  }, [size, setHeight]);

  const handleClose = refresh => {
    setDialogOpen(false);
    if (refresh === true) {
      handleRefresh();
    }
  };

  const handleDeleteInit = () => {
    setInitingDelete(true);
    initAccountDelete()
      .then(() => {
        history.push("/usun-konto/rozpoczecie");
      })
      .finally(() => {
        setInitingDelete(false);
      });
  };

  return (
    <Box className={classes.root}>
      <Avatar avatarId={userData.meduimAvatarId} handleRefresh={handleRefresh} />
      <Typography component="h2" className={classes.name}>
        {userData.name && userData.surname ? `${userData.name} ${userData.surname}` : userData.nick}
      </Typography>
      <PinWithLabel
        size={20}
        textTransform="uppercase"
        label={`${incidentCount} ${getIncidentCountString(incidentCount)}`}
        marginBottom="50px"
        color="primary"
      />
      <Box className={classes.dataRoot}>
        <PinWithLabel
          size={20}
          fontWeight="400"
          textTransform="uppercase"
          label="Moje dane"
          marginBottom="20px"
          color="primary"
        />
        {fields.map(field => (
          <Row field={field} userData={userData} key={field.id} />
        ))}
      </Box>
      <Button
        variant="outlined"
        size="small"
        color="primary"
        className={classes.button}
        onClick={() => setDialogOpen(true)}
      >
        Edytuj dane
      </Button>
      <EditData dialogOpen={dialogOpen} onClose={handleClose} userData={userData} />
      <Office />
      <MailNotifications userData={userData} handleSave={handleClose} />
      <Button
        variant="contained"
        size="small"
        color="primary"
        className={classes.button}
        startIcon={initingDelete && <CircularProgress size={24} />}
        disabled={initingDelete}
        onClick={handleDeleteInit}
      >
        Usuń konto
      </Button>
    </Box>
  );
};

export default sizeMe({ monitorHeight: true })(UserDetails);
