import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { defaultTermsOfUse, termsOfUse, termsOfService } from "./termsOfUse";
import Logo from "../../static/km-logo-no-text.png";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "60px 100px",
    maxWidth: "1350px",
    [theme.breakpoints.down("xs")]: {
      padding: "50px 20px",
    },
  },
  headerRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "48px",
  },
  subtitle: {
    padding: "15px 0px 25px",
    letterSpacing: 0,
  },
  logo: {
    marginRight: "26px",
  },
  text: {
    fontSize: "16px",
    letterSpacing: "1.6px",
    lineHeight: "23px",
    wordBreak: "break-word",
    marginBottom: "10px",
  },
  title: {
    fontSize: "40px",
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  list: {
    margin: 0,
    [theme.breakpoints.down("xs")]: {
      paddingInlineStart: "30px",
    },
  },
  nestedList: {
    listStyle: "disc",
    marginBottom: "10px",
    [theme.breakpoints.down("xs")]: {
      paddingInlineStart: "30px",
    },
  },
}));

const TermsOfUsePage = () => {
  const classes = useStyles();
  const terms = window.location.pathname == "/terms" ? termsOfService : (termsOfUse[process.env.REACT_APP_TERMS_OF_USE] || defaultTermsOfUse);

  return (
    <Box className={classes.root}>
      <Box className={classes.headerRoot}>
        <img className={classes.logo} src={Logo} alt="logo" />
        <Typography className={classes.title} variant="h1" component="h1">
          {window.location.pathname == "/terms" ? "POLITYKA PRYWATNOŚCI" : "REGULAMIN"}
        </Typography>
      </Box>
      {terms.split(/\n\n/).map((item, i) => {
        return /^[IVX]+\./.test(item) ? (
          <Typography
            key={i}
            className={classes.subtitle}
            variant="h3"
            component="h2"
            color="primary"
          >
            {item}
          </Typography>
        ) : (
          <ol className={classes.list} key={i}>
            {item.split(/\n/).map((line, inx) =>
              line.includes("|") ? (
                <ol key={i + "-" + inx} className={classes.nestedList}>
                  {line.split("|").map((subline, index) => (
                    <li className={classes.text} key={i + " " + inx + "-" + index}>
                      {subline}
                    </li>
                  ))}
                </ol>
              ) : (
                <li className={classes.text} key={i + "-" + inx}>
                  {line}
                </li>
              )
            )}
          </ol>
        );
      })}
    </Box>
  );
};

export default TermsOfUsePage;
