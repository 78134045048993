import { makeStyles } from "@material-ui/core/styles";

export const useLoginStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  helperText: {
    fontSize: "20px",
    letterSpacing: "2px",
    lineHeight: "24px",
    marginBottom: "55px",
  },
  input: {
    width: "100%",
    marginBottom: "25px",
  },
  loginButton: {
    height: "51px",
    minWidth: "305px",
    width: "fit-content",
    fontSize: "25px",
    fontWeight: "500",
    borderRadius: "25.5px",
    [theme.breakpoints.down("sm")]: {
      height: "37px",
      minWidth: "170px",
      fontSize: "16px",
      borderRadius: "18.5px",
    },
  },
  errorMessage: {
    visibility: props => (props.showMessage ? "visible" : "hidden"),
    textAlign: "center",
    marginBottom: "25px",
    fontSize: "20px",
    letterSpacing: "2px",
    minHeight: "24px",
    lineHeight: "24px",
    color: theme.palette.error.main,
  },
}));
