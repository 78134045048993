import React from "react";
import LandingPageBanner from "./components/LandingPageBanner/LandingPageBanner";
import MobileApp from "./components/MobileApp/MobileApp";
import DownloadNow from "./components/DownloadNow/DownloadNow";
import Cooperation from "./components/Cooperation/Cooperation";

const LandingPage = () => {
  return (
    <React.Fragment>
      <LandingPageBanner />
      <MobileApp />
      <DownloadNow />
      <Cooperation />
    </React.Fragment>
  );
};

export default LandingPage;
