import React, { useState, useEffect } from "react";
import Root from "../components/Root";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import LabelWithPin from "../../../components/LabelWithPin/LabelWithPin";
import TextField from "../../../components/TextField/TextField";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Header from "../components/Header";
import SocialLoginButton from "../components/SocialLoginButton";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "../../../redux/modules/userReducer";
import { fields } from "./fields";
import { makeStyles } from "@material-ui/core/styles";
import facebook from "../../../static/login/facebook-sm.png";
import google from "../../../static/login/google-sm.png";
import clsx from "clsx";
import { useLoginStyles } from "../useLoginStyles";

const useStyles = makeStyles(theme => ({
  root: {
    display: "contents",
  },
  flexColumn: {
    flexGrow: "1",
    flexShrink: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      maxHeight: "300px",
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: "200px",
      width: "100%",
    },
  },
  input: {
    width: "510px",
    "&:not(:last-child)": {
      marginBottom: "10px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  errorMessage: {
    visibility: props => (props.showMessage ? "visible" : "hidden"),
    fontWeight: "500",
    marginBottom: "10px",
    fontSize: "20px",
    letterSpacing: "2px",
    height: "30px",
  },
  buttonRoot: {
    maxHeight: "187px",
    flexShrink: "2",
    justifyContent: "center",
  },
  bottomRoot: {
    maxHeight: "221px",
  },
  dividerRoot: {
    display: "flex",
    alignItems: "center",
    width: "500px",
    marginBottom: "12.5px",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  divider: {
    flexGrow: "1",
    flexBasis: "10px",
    backgroundColor: theme.palette.text.secondary,
  },
  dividerText: {
    fontSize: "20px",
    letterSpacing: "2px",
    color: theme.palette.text.secondary,
    margin: "0 20px",
  },
  linkRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    marginBottom: "5px",
    width: "360px",
    [theme.breakpoints.down(400)]: {
      width: "100%",
    },
  },
  loginText: {
    fontSize: "15px",
    letterSpacing: "1.5px",
    lineHeight: "19px",
    color: theme.palette.text.secondary,
  },
  passwordRemind: {
    marginLeft: "20px",
  },
}));

const showSocialButtons = false;

const isDisabled = values => {
  let isDisabled = false;
  values.forEach(value => {
    if (!isDisabled && (value === "" || !value)) {
      isDisabled = true;
    }
  });
  return isDisabled;
};

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const isLoggingIn = useSelector(state => state.userReducer.isLoggingIn);
  const isLoggedIn = useSelector(state => state.userReducer.isLoggedIn);
  const loginErrorMessage = useSelector(state => state.userReducer.loginErrorMessage);
  const classes = useStyles({ showMessage: loginErrorMessage });
  const loginClasses = useLoginStyles();

  const handleLogin = e => {
    e.preventDefault();
    dispatch(userAction.login({ email, password }));
  };

  useEffect(() => {
    dispatch(userAction.clearMessages());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(userAction.clearMessages());
      history.push("/");
    }
  }, [isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Root>
      <form className={classes.root}>
        <Header header="Logowanie" />
        <Box className={classes.flexColumn}>
          {fields.map(field => (
            <Box key={field.id} width="100%" maxWidth="510px">
              <LabelWithPin label={field.placeholder} marginBottom="8px" marginRight="8px" />
              <TextField
                className={classes.input}
                placeholder={field.placeholder}
                variant="filled"
                type={field.type}
                onChange={e =>
                  field.id === "email" ? setEmail(e.target.value) : setPassword(e.target.value)
                }
              />
              {field.showRemind && (
                <Link
                  className={clsx(classes.loginText, classes.passwordRemind)}
                  to="/login/resetuj-haslo"
                >
                  Zapomniałeś hasła?
                </Link>
              )}
            </Box>
          ))}
        </Box>
        <Box className={`${classes.flexColumn} ${classes.buttonRoot}`}>
          <Typography className={classes.errorMessage}>{loginErrorMessage + ""}</Typography>
          <Button
            color="primary"
            variant="contained"
            className={loginClasses.loginButton}
            type="submit"
            disabled={isDisabled([email, password]) || isLoggingIn}
            onClick={handleLogin}
          >
            Zaloguj się
          </Button>
          <Box className={classes.errorMessage} />
        </Box>
        <Box className={`${classes.flexColumn} ${classes.bottomRoot}`}>
          {showSocialButtons ? (
            <React.Fragment>
              <Box className={classes.dividerRoot}>
                <Divider className={classes.divider} />
                <Typography className={classes.dividerText}>lub</Typography>
                <Divider className={classes.divider} />
              </Box>
              <SocialLoginButton
                color="#4267B2"
                className={classes.button}
                startIcon={<img src={facebook} alt="" />}
              >
                Zaloguj się z Facebookiem
              </SocialLoginButton>
              <SocialLoginButton
                color="#F75B47"
                className={classes.button}
                startIcon={<img src={google} alt="" />}
              >
                Zaloguj się z Google
              </SocialLoginButton>
            </React.Fragment>
          ) : null}
          <Box className={classes.linkRoot}>
            <Typography className={classes.loginText}>Nie masz jeszcze konta?</Typography>
            <Link className={classes.loginText} to="/rejestracja">
              Zarejestruj się
            </Link>
          </Box>
        </Box>
      </form>
    </Root>
  );
};

export default LoginPage;
