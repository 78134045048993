import React, { useState, useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useMapEvents } from "react-leaflet";
import L from "leaflet";

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: "40px !important",
    marginBottom: "40px !important",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      marginRight: "15px !important",
      marginBottom: "25px !important",
    },
  },
  button: {
    height: "44px",
    width: "44px",
    fontSize: "32px",
    minWidth: "unset",
    padding: "unset",
    borderRadius: "16px",
    boxShadow: "0px 3px 6px #00000040 !important",
    backgroundColor: theme.palette.background.paper,
    "&:not(:last-of-type)": {
      marginBottom: "8px",
    },
  },
}));

const ZoomControl = ({ disableFitToMap }) => {
  const classes = useStyles();
  const [zoom, setZoom] = useState(0);
  const zoomIn = useRef(null);
  const zoomOut = useRef(null);

  const map = useMapEvents({
    zoom() {
      setZoom(map.getZoom());
    },
  });

  useEffect(() => {
    L.DomEvent.disableClickPropagation(zoomIn.current);
    L.DomEvent.disableClickPropagation(zoomOut.current);
  }, []);

  useEffect(() => {
    setZoom(map.getZoom());
    if (disableFitToMap) {
      return;
    }

    map.invalidateSize();
    map.fitBounds([
      [49, 14.12],
      [55.03, 24.15],
    ]);
  }, [map, disableFitToMap]);

  const handleZoomIn = e => {
    e.stopPropagation();
    map.zoomIn();
  };

  const handleZoomOut = e => {
    e.stopPropagation();
    map.zoomOut();
  };

  return (
    <div className="leaflet-bottom leaflet-right">
      <Box className={`${classes.root} leaflet-control`}>
        <Button
          aria-label="Przybliż mapę"
          variant="contained"
          onClick={handleZoomIn}
          disabled={zoom === map.getMaxZoom()}
          className={classes.button}
          ref={zoomIn}
        >
          +
        </Button>
        <Button
          aria-label="Oddal mapę"
          variant="contained"
          onClick={handleZoomOut}
          disabled={zoom === map.getMinZoom()}
          className={classes.button}
          ref={zoomOut}
        >
          -
        </Button>
      </Box>
    </div>
  );
};

export default ZoomControl;
